<template>
    <div class="debug-plugins__router">
        <code
            class="debug-plugins__router__status full-width"
        >
            <span>Status:</span>

            <span v-if="loading">Loading</span>
            <span v-else>Ready</span>
        </code>

        <template v-if="router">
            <code class="debug-plugins__router__routes full-width">
                <span class="row"><span>Current route:</span><span>{{ currentRoute }}</span></span>
            </code>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import type { PluginsManager } from '../../../../plugins';
import { injectOrFail } from '../../../../services/util';

const plugins = injectOrFail<PluginsManager>('plugins');
const router = useRouter();
const loading = ref<boolean>(true);

onMounted(() => {
    void initializePluginDebug();
});

async function initializePluginDebug() {
    try {
        await plugins.router.loading;
    } finally {
        loading.value = false;
    }
}

const currentRoute = computed<string>(() => {
    if (!router) {
        return '';
    }

    return `${router.currentRoute.name || '-'} <${router.currentRoute.path}>`;
});
</script>

<style lang="scss" scoped>
.debug-plugins__router {
    & > code {
        display: flex;
        justify-content: space-between;
        margin-top: 1px;

        & > span:not(:first-child) {
            padding-left: .5rem;
        }

        &.debug-plugins__router__routes {
            flex-direction: column;
            justify-content: flex-start;

            & > .row {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
</style>
