import type {
    default as VueRouter, Location, Route,
} from 'vue-router';
import type { Context } from './context.service';

export type OnContextFilterSelect = (id: string | symbol | undefined, context?: Context | undefined) => Location | null;

function extractOnContextSelect(from: Route): OnContextFilterSelect | null {
    for (const routeRecord of from.matched.slice().reverse()) {
        const { onContextSelect } = routeRecord.meta;

        if (onContextSelect) {
            return onContextSelect;
        }
    }
    return null;
}

/**
 * Update the route when another context is selected.
 */
export function handleContextFilterUpdate(router: VueRouter, id: string | symbol, context: Context | undefined) {
    const toRoute = extractOnContextSelect(router.currentRoute);

    if (!toRoute) {
        return;
    }

    const route = toRoute(id, context);

    if (!route) {
        return;
    }

    void router.push({
        query: {
            ...router.currentRoute.query,

            // Needed to perform context update as the url doesn't change when switching between companies
            // TODO: We might need to return the company id in the url
            nonce: Date.now().toString(),
        },
        ...route,
    });
}

/**
 * Updating the company context based on the given id
 */
export function onCompanyContextSelect(id: string | symbol | undefined, context: Context | undefined): Location | null {
    if (!context) {
        return null;
    }

    if (!id || typeof id === 'symbol') {
        return { params: { company: '' } };
    }

    return { params: { company: id } };
}
