<template>
    <ActionTile
        :description="$t(`dashboard.company.edit.actions.${translationSlug}.description`)"
        :title="$t(`dashboard.company.edit.actions.${translationSlug}.title`)"
        :data-testid="`actions-${slug}`"
    >
        <InputField
            v-if="events"
            :label="$t('dashboard.events.model_name')"
        >
            <OtPaginationSelect
                ref="paginationSelect"
                v-model="selectedEventId"
                :name-resolver="nameResolver"
                :not-shown-label="$t('dashboard.events.list.options_not_shown')"
                :pagination="events"
                data-primary-key="name"
                :data-testid="`actions-${slug}-event-select`"
            />
        </InputField>

        <button
            v-if="modalRef"
            :disabled="!selectedEventId"
            class="is-small ot-button"
            :data-testid="`actions-${slug}-submit`"
            @click="countAndOpenModal"
        >
            {{ $t(`dashboard.company.edit.actions.${translationSlug}.action_label`) }}
        </button>

        <ConfirmWithYesModal
            ref="modalRef"
            :title="$t(`dashboard.company.edit.actions.${translationSlug}.title`)"
            :subtitle="eventName"
            :confirm-label="$t(`dashboard.company.edit.actions.${translationSlug}.action_label`)"
            @confirm="confirm"
        >
            <template #description>
                <i18n
                    tag="span"
                    :path="`dashboard.company.edit.actions.${translationSlug}.stats_description`"
                >
                    <template #count>
                        <span
                            v-if="count !== undefined"
                            class="confirm-with-yes-modal__highlighted"
                            :data-testid="`confirm-with-yes-modal-count-${slug}`"
                        >
                            {{ $l.number(count) }}
                        </span>
                    </template>

                    <template #event>
                        <span class="confirm-with-yes-modal__highlighted">
                            {{ eventName }}
                        </span>
                    </template>
                </i18n>
            </template>
        </ConfirmWithYesModal>
    </ActionTile>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import type { Event, ManagementClient } from '@openticket/lib-management';
import { OtPaginationSelect } from '@openticket/vue-dashboard-components';
import type { Pagination } from '@openticket/lib-crud';
import type VueNotifications from '@openticket/vue-notifications';
import type { VueLocalization } from '@openticket/vue-localization';
import ActionTile from './ActionTile.vue';
import ConfirmWithYesModal from './modals/ConfirmWithYesModal.vue';
import InputField from '../../../../components/form/InputField.vue';
import { injectOrFail } from '../../../../services/util';
import { useGenericErrorHandling } from '../../../../composables';
import type { Context } from '../../../../services/context';

type Props = {
    doAction: (eventId: string) => Promise<void>,
    getCount: (eventId: string) => Promise<number>,
    slug: string,
}

const props = defineProps<Props>();

const localization = injectOrFail<VueLocalization>('localization');
const management = injectOrFail<ManagementClient>('management');
const notifications = injectOrFail<VueNotifications>('notifications');
const context = injectOrFail<Context>('context');

const { handleError } = useGenericErrorHandling();

const eventName = ref<string>('');
const events = ref<Pagination<Event<ManagementClient>> | null>(null);
const modalRef = ref<InstanceType<typeof ConfirmWithYesModal>>();
const selectedEventId = ref<Event<ManagementClient>['id'] | null>(null);
const count = ref<number | undefined>();

const translationSlug = computed<string>(() => props.slug.replace('-', '_'));

watch(() => selectedEventId.value, async () => {
    if (context.isEventContext()) {
        eventName.value = context.event.name;
        return;
    }

    eventName.value = selectedEventId.value ? await nameResolver(selectedEventId.value) : '';
});

void (async () => {
    // When in the event context the user does not select an event, the event context is used as the 'selected event'.
    if (context.isEventContext()) {
        selectedEventId.value = context.event.id;
        return;
    }

    try {
        events.value = management.events.list(10);
        await events.value.initialization;
    } catch (e) {
        handleError(e);
    }
})();

async function nameResolver(guid: string): Promise<string> {
    try {
        const _eventModel = await management.events.find(guid);
        return _eventModel.$data.name;
    } catch (e) {
        void handleError(e);
    }

    return guid;
}

async function countAndOpenModal() {
    if (!selectedEventId.value) {
        return;
    }

    count.value = undefined;

    try {
        count.value = await props.getCount(selectedEventId.value);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        modalRef.value?.open();
    } catch (e: unknown) {
        handleError(e);
    }
}

async function confirm() {
    if (!selectedEventId.value) {
        return;
    }

    try {
        await props.doAction(selectedEventId.value);

        notifications.success(localization.getI18n().t(`dashboard.company.edit.actions.${translationSlug.value}.success`));
    } catch (e: unknown) {
        handleError(e);
    }
}
</script>
