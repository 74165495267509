import type { VueConstructor } from 'vue';
import type { PluginsManager } from './manager';
import type { Rejector, Resolver } from './types';

export abstract class Plugin<T = unknown> {

    public readonly resolve: Resolver<T>;
    public readonly reject: Rejector;
    public readonly loading: Promise<T>;

    public readonly resolveRegistration: Resolver<void>;
    public readonly rejectRegistration: Rejector;
    public readonly registration: Promise<void>;
    public readonly errors: string[] = [];

    constructor() {
        let resolveRegistration: Resolver<void>;
        let rejectRegistration: Rejector;

        const registration: Promise<void> = new Promise<void>((_resolve: Resolver<void>, _reject: Rejector) => {
            resolveRegistration = _resolve;
            rejectRegistration = _reject;
        });

        this.resolveRegistration = () => {
            resolveRegistration();

            resolveRegistration = () => { /* No-op */ };
        };
        this.rejectRegistration = (reason?: unknown) => {
            rejectRegistration(reason);

            rejectRegistration = () => { /* No-op */ };
        };
        this.registration = registration;

        let resolve: Resolver<T>;
        let reject: Rejector;

        const loading: Promise<T> = new Promise<T>((_resolve: Resolver<T>, _reject: Rejector) => {
            resolve = _resolve;
            reject = _reject;
        });

        this.resolve = (value: T | PromiseLike<T>) => {
            resolveRegistration();
            resolve(value);
        };
        this.reject = (reason?: unknown) => {
            rejectRegistration(reason);
            reject(reason);
        };
        this.loading = loading;
    }

    abstract install(plugins: PluginsManager, Vue: VueConstructor): void;

}
