<template>
    <OtSimpleTile
        :title="record.$data.name"
        :record="record"
        class="event-dates-list__record"
        clickable
        @click="goToDateEditPage(record.$data.event_id, record.$data.guid)"
    >
        <template #asset>
            <div class="event-dates-list__record-asset ot-text-body">
                <span>{{ month }}</span>
                <span class="event-dates-list__record-asset__day">{{ day }}</span>
            </div>
        </template>

        <template #title>
            <div class="event-dates-list__record-title">
                <h3>{{ record.$data.name }}</h3>
            </div>
        </template>

        <template #column__-data__start="{ value }">
            {{ $l.dateTime(value) }}
        </template>

        <template #column__-data__end="{ value }">
            {{ $l.dateTime(value) }}
        </template>

        <template #column__-data__capacity="{ record, value }">
            <FractionColumn :numerator="record.$data.sold_count || 0" :denominator="value" />
        </template>

        <template #actions>
            <button
                class="ot-button is-outline is-small"
                :title="$t('dashboard.events.dates.list.edit.title', { date: record.$data.name })"
                @click.stop="goToDateEditPage(record.$data.event_id, record.$data.guid)"
                @keydown.space.stop="goToDateEditPage(record.$data.event_id, record.$data.guid)"
                @keydown.enter.stop="goToDateEditPage(record.$data.event_id, record.$data.guid)"
            >
                {{ $t('dashboard.events.dates.list.edit.text') }}
                <OtIcon type="carrot-right" size="small" class="ot-button-icon--right" />
            </button>
        </template>
    </OtSimpleTile>
</template>

<script setup lang="ts">
import type { EventDate, ManagementClient } from '@openticket/lib-management';
import type { VueLocalization } from '@openticket/vue-localization';
import { computed } from 'vue';
import type VueRouter from 'vue-router';
import { useRouter } from 'vue-router/composables';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import FractionColumn from '../../../components/columns/FractionColumn.vue';

type Props = {
    record: EventDate<ManagementClient>;
}

const context = injectOrFail<Context>('context');
const localization = injectOrFail<VueLocalization>('localization');

const props = defineProps<Props>();
const router: VueRouter = useRouter();

const month = computed(() => localization?.formatters.parts.dateShort(props.record.$data.start).find((x) => x.type === 'month')?.value.toUpperCase());
const day = computed(() => localization?.formatters.parts.dateShort(props.record.$data.start).find((x) => x.type === 'day')?.value);

function goToDateEditPage(event: string, date: string): void {
    if (!context.isCompanyContext()) {
        // TODO Take this from the date data instead!
        throw new Error('Should be in company context to edit a date!');
    }

    void router.push({
        name: 'dates.edit',
        params: { event, date },
    });
}
</script>

<style scoped lang="scss">
.event-dates-list__record {
    &-asset {
        border-radius: .5rem;
        width: 4rem;
        height: 4rem;
        background-color: var(--ot-color-core-light-foreground-primary);
        color: var(--ot-color-core-white);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__day {
            font-weight: 700;
            font-size: 1.125rem;
        }
    }

    &-title {
        display: flex;

        h3 {
            flex: none;
        }
    }
}
</style>
