<template>
    <div class="invitation">
        <OtAvatar :name="invitation.name" size="64" />

        <div class="invitation__info">
            <b>{{ invitation.name }}</b>
            <div v-if="tfaNeeded && !twoFactorEnabled" class="ot-label">
                <i18n path="dashboard.header.profile.invitations.two_factor_needed.text">
                    <template #setup>
                        <span
                            class="ot-link ot-clickable"
                            @click="redirect"
                            role="button"
                            tabindex="0"
                            @keydown.space="redirect"
                            @keydown.enter="redirect"
                        >
                            {{ $t('dashboard.header.profile.invitations.two_factor_needed.setup') }}
                        </span>
                    </template>
                </i18n>
            </div>
        </div>

        <div class="invitation__action">
            <OtSpinner
                v-if="status === 'loading'"
                class="ot-p[xs]"
            />

            <button
                v-if="status === 'accepted'"
                class="ot-button is-small is-success disabled"
                type="button"
            >
                <OtIcon type="check" class="ot-button-icon--left" />
                {{ $t('dashboard.header.profile.invitations.accepted') }}
            </button>

            <button
                v-if="status === 'declined'"
                class="ot-button is-small is-danger disabled"
                type="button"
            >
                <OtIcon type="close" class="ot-button-icon--left" />
                {{ $t('dashboard.header.profile.invitations.declined') }}
            </button>

            <button
                v-if="!status"
                class="ot-button is-small"
                type="button"
                :disabled="tfaNeeded && !twoFactorEnabled"
                :title="$t('dashboard.header.profile.invitations.action.accept.title')"
                @click="acceptInvitation()"
            >
                <OtIcon type="check" class="ot-button-icon--left" />
                {{ $t('dashboard.header.profile.invitations.action.accept.text') }}
            </button>

            <button
                v-if="!status"
                class="ot-button is-danger is-small"
                type="button"
                :title="$t('dashboard.header.profile.invitations.action.decline.title')"
                @click="declineInvitation()"
            >
                <OtIcon type="close" class="ot-button-icon--left" />
                {{ $t('dashboard.header.profile.invitations.action.decline.text') }}
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { AuthClient } from '@openticket/lib-auth';
import type { DialogController, ModalController } from '@openticket/vue-dashboard-components';
import { isAxiosError } from 'axios';
import urlJoin from 'url-join';
import {
    computed, inject, reactive, ref, type UnwrapNestedRefs,
} from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import type VueNotifications from '@openticket/vue-notifications';
import type { VueLocalization } from '@openticket/vue-localization';
import { injectOrFail } from '../../services/util';
import type { SidebarUpdateTriggers } from '../../layouts/types';

interface MinimalCompany {
    name: string;
    guid: string;
    force_tfa: boolean;
}

type Props = {
    invitation: MinimalCompany,
    showRedirectToFixPage: boolean,
    twoFactorEnabled: boolean,
}

type Emits = {
    (e: 'accepted'): void
}

const props = withDefaults(
    defineProps<Props>(),
    {
        showRedirectToFixPage: true,
    },
);

const emit = defineEmits<Emits>();

const auth = injectOrFail<AuthClient>('auth');
const dialog = injectOrFail<DialogController>('dialog');
const localization = injectOrFail<VueLocalization>('localization');
const modal = inject<ModalController | string>('modal', 'no modal needed (for fix wrapper)');
const notifications = injectOrFail<VueNotifications>('notifications');
// Normal inject, as on fix routes the sidebar is not provided.
const sidebarUpdate = inject<UnwrapNestedRefs<SidebarUpdateTriggers> | null>('sidebarUpdate', null);

const status = ref<'accepted' | 'declined' | 'loading' | null>(null);
const tfaNeededByResponse = ref<boolean>(false);
let clientConfig = reactive<{
        client_id?: string;
        redirect_uri?: string;
        state?: string;
}>({});

const tfaNeeded = computed<boolean>(() => props.invitation.force_tfa || tfaNeededByResponse.value);

const route = useRoute();
const router = useRouter();

async function redirect(): Promise<void> {
    if (props.showRedirectToFixPage) {
        clientConfig = route.query;

        await router.push({ name: 'fix.tfa', query: { ...clientConfig, 'forced-warning': 'hide' } });
    } else {
        if (typeof modal !== 'string') {
            await modal.close();
        }
        await router.push({ name: 'profile.settings.security' });
    }
}

async function acceptInvitation(): Promise<void> {
    status.value = 'loading';

    // TODO - @openticket/lib-auth
    try {
        await auth.$http.post(urlJoin(auth.$path, 'invitations', 'companies', props.invitation.guid));
    } catch (error: unknown) {
        status.value = null;

        if (!isAxiosError(error)) {
            notifications.warning($t('dashboard.header.profile.invitations.accept.warning'));

            throw error;
        }

        if (error.response?.status === 400) {
            tfaNeededByResponse.value = true;
            return;
        }

        notifications.warning($t('dashboard.header.profile.invitations.accept.warning'));
        return;
    }

    status.value = 'accepted';
    notifications.success($t('dashboard.header.profile.invitations.accept.success'));
    emit('accepted');

    if (sidebarUpdate) {
        await sidebarUpdate.updateUserandCompanies();
    }
}

async function declineInvitation(): Promise<void> {
    const confirm = await dialog.confirm({
        title: $t('dashboard.common.confirm.permanent.title') as string,
        description: $t('dashboard.common.confirm.permanent.description') as string,
        type: 'is-danger',
    });

    if (confirm) {
        status.value = 'loading';

        // TODO - @openticket/lib-auth
        try {
            await auth.$http.delete(urlJoin(auth.$path, 'invitations', 'companies', props.invitation.guid));
        } catch (e: unknown) {
            status.value = null;

            notifications.warning($t('dashboard.header.profile.invitations.decline.warning'));

            throw e;
        }

        status.value = 'declined';
        notifications.success($t('dashboard.header.profile.invitations.decline.success'));
    }
}

// TODO: Remove below when upgrading to Vue 3
const $t = (value: string) => localization.getI18n().t(value);
</script>

<style lang="scss" scoped>
.invitation {
    display: flex;
    width: 100%;
    align-items: center;

    &__info {
        flex-grow: 1;
        margin: 0 var(--ot-spacing-2xl);
        flex: min-content;
    }

    &__action {
        display: flex;

        .ot-button {
            margin-left: var(--ot-spacing-xs);
        }

        .disabled {
            cursor: default;
        }
    }
}
</style>
