import type { Pagination } from '@openticket/lib-crud';
import type {
    ManagementClient, OrderExtended, OrderPaymentExtended,
} from '@openticket/lib-management';
import { ref, watchEffect, type Ref } from 'vue';
import type { TranslateResult, VueLocalization } from '@openticket/vue-localization';
import { injectOrFail } from '../../../services/util';

export interface PaymentDataComposableResult {
    currency: Ref<string>,
    currencyFormatter: (cents: number | undefined) => string,
    hasPayment: Ref<boolean>,
    isFree: Ref<boolean>,
    paymentMethod: Ref<string>,
    transactionId: Ref<string | TranslateResult>,
}

const UNKNOWN_CHARACTER = '�';

export function usePaymentData(
    payments: Ref<Pagination<OrderPaymentExtended<OrderExtended<ManagementClient>>> | null>,
): PaymentDataComposableResult {
    const localization = injectOrFail<VueLocalization>('localization');

    const currency = ref<string>(UNKNOWN_CHARACTER);
    const hasPayment = ref<boolean>(false);
    const isFree = ref<boolean>(false);
    const paymentMethod = ref<string>(UNKNOWN_CHARACTER);
    const transactionId = ref<string | TranslateResult>(UNKNOWN_CHARACTER);

    watchEffect(() => {
        if (!payments.value) {
            hasPayment.value = false;
            return;
        }

        // TODO
        // Currently the first payment record is used to calculate the following properties.
        // This should be updated when a decision has been made how to handle multiple payments.
        // See also CU-86bwaacqb
        const payment = payments.value.records[0];

        if (!payment) {
            hasPayment.value = false;
            return;
        }

        currency.value = payment.$data.currency;
        hasPayment.value = true;
        isFree.value = !payment.$data.finn_price;
        paymentMethod.value = payment.$data.payment_method.name;
        transactionId.value = payment.$data.payment_id;
    });

    function currencyFormatter(cents: number | undefined): string {
        if (typeof cents !== 'number') {
            return '';
        }

        try {
            return localization.formatters.currency(cents, currency.value);
        } catch (e) {
            return `${currency.value}${(cents / 100).toFixed(2)}`;
        }
    }

    return {
        currency,
        currencyFormatter,
        hasPayment,
        isFree,
        paymentMethod,
        transactionId,
    };
}
