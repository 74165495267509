<template>
    <div id="app">
        <DebugWrapper :is-loading="isLoading" :components-registered="componentsRegistered" />

        <OtSplash v-if="isLoading && componentsRegistered" class="app__loading" :spinner="spinner" />

        <OtDialog v-if="!isLoading">
            <div class="app__content">
                <router-view></router-view>
            </div>
        </OtDialog>
    </div>
</template>

<script lang="ts" setup>
import { send, Log } from '@openticket/lib-log';
import { computed, onMounted, ref } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { useRouter } from 'vue-router/composables';
import DebugWrapper from './modules/debug/DebugWrapper.vue';
import type { PluginsManager } from './plugins';
import { injectOrFail } from './services/util';

const plugins = injectOrFail<PluginsManager>('plugins');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const router = useRouter();

const isLoading = ref<boolean>(true);
const componentsRegistered = ref<boolean>(false);

const spinner = computed<string>(() => whitelabel.resources.spinner);

onMounted(() => {
    void load();
});

const load = async () => {
    try {
        send({
            href: window.location.href,
            message: 'Dashboard: App initialized',
            slug: 'dashboard.log.messages.init.app',
            ts: Date.now(),
        }, Log.Info);
    } catch (e) {
        console.debug('Failed to send app init log message', { e });
        // Fail silently
    }
    try {
        // Components are needed for the splash screen. Await those first.
        await plugins.components.loading;
        componentsRegistered.value = true;

        // TODO Should localization be awaited on loading or registration???
        await plugins.all;
    } catch (e) {
        // TODO Send log
        // TODO Error data & Check what happens if already @ /error
        //  -> Duplicate navigation is aborted -> should be fixed/workaround (ignore??? and append reason???)
        // TODO also find a way to send more error context to the error page...
        console.error('App created error: ', { e });

        if (router.currentRoute.name !== 'error' && router.currentRoute.name !== 'auth.login') {
            void router.push({ name: 'error', query: { reason: e instanceof Error ? e.message : 'unknown init error' } });
        }
    } finally {
        await plugins.router.initialRouteLoading;

        isLoading.value = false; // TODO Check all loading vars, and make them consistent
    }
};
</script>

<style lang="scss">
@import "./assets/scss/mixins.scss";

body {
    overflow-y: scroll;
}

.ot-document {
    --ot-card-padding: var(--ot-spacing-3xl) var(--ot-spacing-4xl);
    --ot-gradient-light-top: linear-gradient(180deg, rgba(245, 247, 249, 0) 0%, var(--ot-color-core-background-secondary) 100%);
}

.ot-input-label {
    color: var(--ot-color-core-foreground-secondary) !important;
}

a {
    text-decoration: none;
    font-weight: 700;
    color: inherit;

    &:visited {
        color: inherit;
    }
}

.ot-vars,
.ot-document {
    --ot-layout-width: 80rem;
    --ot-layout-sidebar-width: 16rem;
    --ot-layout-header-height: 5.5rem;
    --ot-layout-header-padding-y: 1.5rem;
    --ot-heading-font-weight: 500;
}

@include breakpoint(tab) {
    .ot-vars,
    .ot-document {
        --ot-spacing-unit: 0.5rem;
        --ot-spacing-3xl: calc(2*var(--ot-spacing-unit));
        --ot-spacing-4xl: calc(2*var(--ot-spacing-unit));
        --ot-layout-header-height: 4rem;
        --ot-layout-header-padding-y: 0.75rem;
    }
}
</style>
