<template>
    <FormWrapper
        class="profile-update"
        :form="profileForm"
        :hide-controls="hideControls"
    >
        <template #header-title>
            <UserHeader
                :name="headerName || $t('dashboard.company.profile.new')"
                :show_tags="true"
                :manager="user.$data.manager"
                :two_factor_enabled="user.$data.two_factor_enabled"
                hide_role
            />
        </template>
        <VerticalTabs>
            <template v-slot="{ selected }">
                <VerticalTabsItem
                    :label="$t('dashboard.profile.settings.general.route_title')"
                    :route="{
                        name: 'profile.settings.general',
                    }"
                    :selected="selected"
                >
                    <ProfileGeneralForm :form="profileForm" />
                </VerticalTabsItem>

                <VerticalTabsItem
                    :label="$t('dashboard.profile.settings.security.route_title')"
                    :route="{
                        name: 'profile.settings.security',
                    }"
                    :selected="selected"
                >
                    <ProfileSecurityWrapper />
                </VerticalTabsItem>
            </template>
        </VerticalTabs>
    </FormWrapper>
</template>

<script setup lang="ts">
import type { AuthClient, User } from '@openticket/lib-auth';
import {
    computed, reactive, ref, watch,
} from 'vue';
import { useRoute } from 'vue-router/composables';
import VerticalTabs from '../../../components/verticaltabs/VerticalTabs.vue';
import VerticalTabsItem from '../../../components/verticaltabs/VerticalTabsItem.vue';
import ProfileGeneralForm from '../components/forms/ProfileGeneralForm.vue';
import FormWrapper from '../../../components/form/FormWrapperNew.vue';
import { useUpdateForm } from '../../../composables/forms';
import { injectOrFail } from '../../../services/util';
import UserHeader from '../../../components/user/UserHeader.vue';
import ProfileSecurityWrapper from '../layouts/ProfileSecurityWrapper.vue';

type Props = {
    user: User<AuthClient>,
}

const props = defineProps<Props>();

const auth = injectOrFail<AuthClient>('auth');
const route = useRoute();

const profileForm = reactive(
    useUpdateForm<User<AuthClient>, User<AuthClient>>(
        props.user,
        auth.users,
        {
            onSubmit() {
                headerName.value = profileForm.getModelName();
            },
        },
    ),
);

const headerName = ref<string>(profileForm.getModelName());

const hideControls = computed<boolean>(() => (route.name === 'profile.settings.security'));

watch(() => profileForm.rules, () => {
    // The password and whitelabel_id properties are marked as required in the rules but don't exist on the User model.
    // Until CU-86byezdqr is implemented, the below is needed for the validation to succeed
    // TODO: Remove below after the above task is implemented
    delete profileForm.rules.password;
    delete profileForm.rules.whitelabel_id;
});
</script>

<style lang="scss" scoped>
.profile-update {
    &__header {
        border-bottom: 1px solid var(--ot-color-core-accent-secondary);
    }

    &__content {
        padding: var(--ot-card-padding);

        &__form {
            margin-bottom: var(--ot-spacing-3xl);

            &__save {
                display: flex;
                justify-content: flex-end;
            }
        }

        & > :not(:first-child) {
            margin-top: var(--ot-spacing-xl);
        }
    }
}

::v-deep .avatar-header.user-header {
    padding: 0;
}
</style>
