<template>
    <OtDropdown class="profile-dropdown" ref="profileDropdown" contentAlignment="right">
        <template #trigger="{ opened }">
            {{ /* TODO add keylisteners and a11y */ }}
            <div
                class="profile-dropdown__trigger"
                :class="{ opened, 'show-marker': showMarker }"
            >
                <OtAvatar
                    class="profile-dropdown__trigger__avatar"
                    :class="{ opened }"
                    :name="userName"
                    size="40"
                />
            </div>
        </template>

        <template #content>
            <div class="profile-dropdown__content">
                <ProfileSummary @navigate="profileDropdown?.close()" />

                <ProfileLocaleSwitch />

                <div class="ot-separator" />

                <ProfileInvitations
                    :class="{ 'show-marker': showMarker }"
                    @has-invitations="hasInvitations"
                />
            </div>
        </template>
    </OtDropdown>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import type { AuthClient } from '@openticket/lib-auth';
import { OtDropdown } from '@openticket/vue-dashboard-components';
import { injectOrFail } from '../../../../services/util';
import ProfileInvitations from './ProfileInvitations.vue';
import ProfileLocaleSwitch from './ProfileLocaleSwitch.vue';
import ProfileSummary from './ProfileSummary.vue';

const auth = injectOrFail<AuthClient>('auth');

const showMarker = ref<boolean>(false);
const profileDropdown = ref<InstanceType<typeof OtDropdown>>();
const userName = ref<string | undefined>();

void (async () => {
    const tokenInfo = await auth.$token.$info;
    userName.value = tokenInfo ? tokenInfo.user.name : '?';
})();

function hasInvitations(value: boolean): void {
    showMarker.value = !!value;
}

</script>

<style lang="scss" scoped>
.profile-dropdown {
    &__trigger {
        position: relative;

        &__avatar {
            margin: 0.5rem 0;
            cursor: pointer;
            padding: 2px;
            border-radius: 50%;

            &:hover, &.opened, &:focus, &:focus-within {
                outline: var(--ot-color-core-brand);
                outline-width: 2px;
                outline-style: solid;
            }

            &.opened {
                outline-offset: 2px;

                &.show-marker::after {
                    margin-left: 2px;
                    margin-bottom: 2px;
                }
            }
        }

        &.opened.show-marker::after {
            margin-right: -2px;
            margin-top: -2px;
        }
    }

    &__content {
        padding: var(--ot-spacing-unit);

        .ot-separator {
            margin: var(--ot-spacing-unit) 0;
        }

        & > * {
            position: relative;
        }
    }

    // Align the dropdown to the right side of the screen
    &::v-deep .dropdown__dialog {
        left: unset;

        width: 24rem;

        @media (40rem < width) {
            right: 0;
        }

        border-radius: var(--ot-input-radius);
        border: 1px solid var(--ot-color-core-accent-secondary);
    }

    .show-marker::after {
        content: '';
        position: absolute;
        display: block;
        border-radius: .75rem;
        width: .75rem;
        height: .75rem;
        background-color: var(--ot-color-accent-pink-dark);
        top: calc(0.146 * 2.5rem - .375rem); /* i.e. h * (1-(sqrt(2)/2))/2 - r/2 */
        right: calc(0.146 * 2.5rem - .375rem); /* i.e. w * (1-(sqrt(2)/2))/2 - r/2 */
    }
}
</style>
