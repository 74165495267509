import type { AxiosInstance, AxiosResponse } from 'axios';
import { isAxiosError } from 'axios';
import urlJoin from 'url-join';
import { AdminActionsApiError, AdminActionsInvalidResponse, AdminActionsUnsetBaseUrlError } from './errors';

export class AdminActions {

    private http: AxiosInstance;
    protected baseUrl: string | null;

    constructor(config: { http: AxiosInstance, baseUrl: string | null }) {
        this.baseUrl = config.baseUrl;
        this.http = config.http;

        // DD-DASHBOARD-2729 - CORS error appears when credential mode is 'include'.
        // TODO: remove after CORS from legacy api gets updated
        this.http.interceptors.request.use((axiosConfig) => {
            delete axiosConfig.headers['X-Authorization-By-Openticket'];
            return axiosConfig;
        });
    }

    private checkIfBaseUrlSet(): asserts this is { baseUrl: string } {
        if (!this.baseUrl) {
            throw new AdminActionsUnsetBaseUrlError();
        }
    }

    private checkIfResponseHasCount(
        response: AxiosResponse,
        countKey = 'order_count',
    ): asserts response is AxiosResponse<{ [key in typeof countKey]: number }> {
        if (!(countKey in response.data) || typeof response.data[countKey] !== 'number') {
            throw new AdminActionsInvalidResponse();
        }
    }

    async doRerenderEventOrders(eventId: string, getCount: boolean = true): Promise<AxiosResponse<{}>> {
        this.checkIfBaseUrlSet();

        const url = urlJoin(this.baseUrl, 'admin', 'maintenance', 'rerender-event');

        const data = {
            event_id: eventId,
        };

        return getCount ? this.getOrThrow(url, data) : this.postOrThrow(url, data);
    }

    /**
     * Re-render the orders of the specified Event.
     *
     * @param eventId guid of the Event of which the orders need to be re-rendered.
     */
    async rerenderEventOrders(eventId: string): Promise<void> {
        await this.doRerenderEventOrders(eventId, false);
    }

    /**
     * Get the number of orders that are going to be (re-)rendered.
     *
     * @param eventId guid of the event of which the receipts need to be re-rendered.
     */
    async countRerenderEventOrders(eventId: string): Promise<number> {
        const response = await this.doRerenderEventOrders(eventId, true);

        this.checkIfResponseHasCount(response);

        return response.data.order_count;
    }

    async doRerenderReceipts(companyId: string, getCount: boolean = true): Promise<AxiosResponse<{}>> {
        this.checkIfBaseUrlSet();

        const url = urlJoin(this.baseUrl, 'admin', 'maintenance', 'rerender-receipts');

        const data = {
            company_id: companyId,
        };

        return getCount ? this.getOrThrow(url, data) : this.postOrThrow(url, data);
    }

    /**
     * Re-render receipts for the company.
     *
     * @param companyId guid of the company of which the receipts need to be re-rendered.
     */
    async rerenderReceipts(companyId: string): Promise<void> {
        await this.doRerenderReceipts(companyId, false);
    }

    /**
     * Get the number of receipts that are going to be (re-)rendered.
     *
     * @param companyId guid of the company of which the receipts need to be re-rendered.
     */
    async countRerenderReceipts(companyId: string): Promise<number> {
        const response = await this.doRerenderReceipts(companyId, true);

        this.checkIfResponseHasCount(response);

        return response.data.order_count;
    }

    async doSendEmails(eventId: string, getCount: boolean = true): Promise<AxiosResponse<{}>> {
        this.checkIfBaseUrlSet();

        const url = urlJoin(this.baseUrl, 'event', eventId, 'resend-emails');

        return getCount ? this.getOrThrow(url) : this.postOrThrow(url);
    }

    /**
     * Re-send emails for the event.
     *
     * @param eventId guid of the event of which the emails need to be re-send.
     */
    async resendEmails(eventId: string): Promise<void> {
        await this.doSendEmails(eventId, false);
    }

    /**
     * Get the number of orders for which the emails are going to be resend.
     *
     * @param eventId guid of the event of which the emails need to be re-send.
     */
    async countResendEmails(eventId: string): Promise<number> {
        const response = await this.doSendEmails(eventId, true);

        this.checkIfResponseHasCount(response, 'order_count');

        return response.data.order_count;
    }

    /**
     * Re-render the Tickets of the specified Order.
     *
     * @param orderId guid of the Order of which the Tickets need to be re-rendered.
     */
    async rerenderOrderTickets(orderId: string): Promise<void> {
        this.checkIfBaseUrlSet();

        const url = urlJoin(this.baseUrl, 'order', orderId, 'rebuild');

        await this.getOrThrow(url);
    }

    /**
     * Resend the confirmation email of the specified Order.
     *
     * @param orderId guid of the Order of which the confirmation email need to be resend.
     */
    async resendOrderConfirmationEmail(orderId: string): Promise<void> {
        this.checkIfBaseUrlSet();

        const url = urlJoin(this.baseUrl, 'order', orderId, 'resend');

        await this.getOrThrow(url);
    }

    async getOrThrow(url: string, data?: { [key: string]: string }): Promise<AxiosResponse<{}>> {
        try {
            let queryUrl = url;

            if (data) {
                const query = Object.entries(data)
                    .map(([ key, value ]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                    .join('&');
                queryUrl = `${queryUrl}?${query}`;
            }

            // DD-DASHBOARD-2729 - CORS error appears when credential mode is 'include'.
            // TODO: remove withCredentials when CORS settings have been updated in legacy api.
            return await this.http.get(queryUrl, { withCredentials: false });
        } catch (e) {
            if (isAxiosError(e)) {
                throw new AdminActionsApiError(url, e);
            }

            throw e;
        }
    }

    async postOrThrow(url: string, data?: { [key: string]: string }): Promise<AxiosResponse<{}>> {
        try {
            // DD-DASHBOARD-2729 - CORS error appears when credential mode is 'include'.
            // TODO: remove withCredentials when CORS settings have been updated in legacy api.
            return await this.http.post(url, data, { withCredentials: false });
        } catch (e) {
            if (isAxiosError(e)) {
                throw new AdminActionsApiError(url, e);
            }

            throw e;
        }
    }

}
