<template>
    <div class="color-conformation-levels">
        <div
            class="color-conformation-levels__summary"
            role="switch"
            tabindex="0"
            :aria-checked="showDetails"
            :aria-expanded="showDetails"
            @click="showDetails = !showDetails"
            @keydown.space="showDetails = !showDetails"
            @keydown.enter="showDetails = !showDetails"
        >
            <div class="color-conformation-levels__summary__line">
                <div
                    class="color-conformation-levels__summary__line__contrast"
                    v-if="comparisonSummary"
                    :style="{
                        width: `${100*comparisonSummary.minContrast/21}%`,
                        background: summaryRGB,
                    }"
                ></div>
            </div>

            <div
                class="color-conformation-levels__summary__info ot-text-small-strong"
            >{{ $t(comparisonSummaryInfo) }}</div>

            <OtIcon :type="comparisonSummaryIcon" size="small" />
        </div>

        <transition
            name="accordion"
            @enter="startTransition"
            @after-enter="endTransition"
            @before-leave="startTransition"
            @after-leave="endTransition"
        >
            <div class="color-conformation-levels__details" v-show="showDetails">
                <div class="color-conformation-levels__details__header">
                    <div class="color-conformation-levels__details__header__description ot-text-small">
                        <div>{{ $t('dashboard.components.color_conformation_levels.details.description') }}</div>
                        <br />
                        <div
                            class="color-conformation-levels__details__header__description__explained"
                            v-for="(description, level) in {
                                'aaa': 'dashboard.components.color_conformation_levels.details.levels.aaa',
                                'aa': 'dashboard.components.color_conformation_levels.details.levels.aa',
                                'a': 'dashboard.components.color_conformation_levels.details.levels.a',
                                '-': 'dashboard.components.color_conformation_levels.details.levels.-',
                            }"
                            :key="level"
                        >
                            <div
                                class="color-conformation-levels__details__header__description__explained__level"
                            >{{ level }}</div>

                            <div>{{ $t(description) }}</div>
                        </div>
                        <br />
                        <div v-html="$t('dashboard.components.color_conformation_levels.details.more')"></div>
                    </div>

                    <button
                        :style="{all: 'unset'}"
                        @click="showDetails = false"
                    >
                        <OtIcon type="close" />
                    </button>
                </div>

                <slot />
            </div>
        </transition>

        <OtAside
            v-if="showContrastWarning"
            type="is-warning"
            icon="warning"
            :title="$t('dashboard.components.color_conformation_levels.warning.contrast')"
        />
    </div>
</template>

<script setup lang="ts">
import { Color, type WCAGConformanceLevel } from '@peterdekok/color-compare';
import Vue, {
    computed, provide, ref, watch,
} from 'vue';
import type { Comparison } from './types';

type Props = {
    target: string | null,
}

const props = withDefaults(defineProps<Props>(), { target: null });

const comparisons: { [key: string]: Comparison } = {};

const comparisonSummary = ref<{ level: WCAGConformanceLevel; minContrast: number } | null>(null);
const showDetails = ref<boolean>(false);

const comparisonSummaryInfo = computed<string>(() => {
    if (!comparisonSummary.value) {
        return 'dashboard.components.color_conformation_levels.summary.info.undetermined';
    }

    switch (comparisonSummary.value.level) {
        case 'aaa':
            return 'dashboard.components.color_conformation_levels.summary.info.strong';
        case 'aa':
            return 'dashboard.components.color_conformation_levels.summary.info.good';
        case 'a':
            return 'dashboard.components.color_conformation_levels.summary.info.poor';
        default:
            return 'dashboard.components.color_conformation_levels.summary.info.weak';
    }
});

const comparisonSummaryIcon = computed<string>(() => {
    if (comparisonSummary.value) {
        return showDetails.value ? 'info-active' : 'info';
    }

    return showDetails.value ? 'warning-active' : 'warning';
});

const showContrastWarning = computed<boolean>(() => !!comparisonSummary.value && !comparisonSummary.value.level);

const targetColor = computed<Color | null>(() => {
    if (!props.target) {
        return null;
    }

    try {
        return new Color(props.target);
    } catch (e) {
        return null;
    }
});

const summaryRGB = computed<string>(() => {
    if (!comparisonSummary.value) {
        return 'var(--ot-color-core-accent-primary)';
    }

    return contrastRatioToRGBRating(comparisonSummary.value.minContrast, 2.5, 4, 7);
});

watch(() => props.target, () => {
    for (const key in comparisons) {
        comparisons[key].target = targetColor.value;

        const { related } = comparisons[key];

        if (targetColor.value && related) {
            comparisons[key].result = targetColor.value.compare(related);
        } else {
            comparisons[key].result = null;
        }
    }

    updateComparisonSummary();
});

provide('color-comparison-registration', comparisonRegistration);

function contrastRatioToRGBRating(ratio: number, lower: number, middle: number, upper: number) {
    const red = (1 - ((ratio - middle) / (upper - middle))) * 255;
    const green = 255 * ((ratio - lower) / (middle - lower));

    return `rgb(${red}, ${green}, 0)`;
}

function updateComparisonSummary(): void {
    if (!Object.keys(comparisons).length) {
        comparisonSummary.value = null;

        return;
    }

    comparisonSummary.value = Object.values(comparisons)
        .reduce((carry: { level: WCAGConformanceLevel, minContrast: number } | null, comparison: Comparison) => {
            if (!carry || !comparison.result) {
                return null;
            }

            if (comparison.result.rawPerceivableValues.contrast < carry.minContrast) {
                return {
                    level: comparison.result.wcagConformance,
                    minContrast: comparison.result.rawPerceivableValues.contrast,
                };
            }

            return carry;
        }, {
            level: 'aaa',
            minContrast: 21,
        });
}

function comparisonRegistration(key: string, related: Color | null): Comparison {
    if (!comparisons[key]) {
        comparisons[key] = Vue.observable({
            key,
            target: targetColor.value,
            related: null,
            result: null,
            deregister: () => deregister(key),
        });
    }

    comparisons[key].related = related;

    if (targetColor.value && related) {
        comparisons[key].result = targetColor.value.compare(related);
    } else {
        comparisons[key].result = null;
    }

    updateComparisonSummary();

    return comparisons[key];
}

function deregister(key: string): void {
    delete comparisons[key];
    updateComparisonSummary();
}

function startTransition(el: HTMLElement): void {
    el.style.height = `${el.scrollHeight}px`;
}

function endTransition(el: HTMLElement): void {
    el.style.height = '';
}
</script>

<style lang="scss" scoped>
.color-conformation-levels {
    &__summary {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:not(:last-child) {
            margin-bottom: var(--ot-spacing-default);
        }

        &__line {
            flex-grow: 1;

            height: .375rem;

            border-radius: .375rem;
            overflow: hidden;
            background: var(--ot-color-core-background-secondary);

            &__contrast {
                width: 0;
                height: 100%;
                border-radius: .375rem;
            }
        }

        &__info {
            padding: 0 .375rem;
            flex-grow: 0;
            color: var(--ot-color-core-foreground-secondary);
        }

        .oti{
            flex-grow: 0;

            &::v-deep:before {
                color: var(--ot-color-core-foreground-secondary);
            }
        }
    }

    &__details {
        padding: var(--ot-spacing-sm);
        background: var(--ot-color-core-accent-tertiary);
        color: var(--ot-color-core-foreground-secondary);

        &:not(:last-child) {
            margin-bottom: var(--ot-spacing-default);
        }

        &__header {
            display: flex;
            align-items: flex-start;

            &:not(:last-child) {
                margin-bottom: var(--ot-spacing-lg);
            }

            &__description {
                flex-grow: 1;

                &__explained {
                    display: flex;

                    & > * {
                        display: inline-block;
                    }

                    &__level {
                        flex-shrink: 0;
                        width: 3em;
                        text-transform: uppercase;
                    }
                }
            }

            .oti {
                flex-grow: 0;
                margin-left: var(--ot-spacing-sm);
                cursor: pointer;
            }
        }

        &.accordion-enter-active,
        &.accordion-leave-active {
            will-change: height, opacity;
            transition:
                height var(--ot-transition-default-duration) var(--ot-transition-default-timing-function),
                opacity var(--ot-transition-default-duration) var(--ot-transition-default-timing-function);
            overflow: hidden;
        }

        &.accordion-enter,
        &.accordion-leave-to {
            height: 0 !important;
            opacity: 0;
        }
    }
}
</style>
