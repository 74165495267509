import type { Route, RouteConfig } from 'vue-router';
import ProductPortalView from "./views/ProductPortalView.vue";

export function productPortalRoutes(): RouteConfig[] {
    return [
        {
            path: '/product-portal',
            component: ProductPortalView,
            meta: {
                context: 'global',
                title: 'dashboard.document_title.product_portal.title',
                titleFallback: 'Product Portal',
            }
        },
    ];
}
