<template>
    <div class="events-details-form">
        <OtFormRow>
            <InputField
                :label="$t('dashboard.events.edit.details.form.event_name.label')"
                :description="$t('dashboard.events.edit.details.form.event_name.description')"
                :required="form.rules.name && form.rules.name.includes('required')"
                :optional="form.rules.name && form.rules.name.includes('optional')"
                :error="form.errors.name"
            >
                <OtInput
                    class="input"
                    type="text"
                    v-model="form.model.$data.name"
                    @input="form.errors.name = []"
                />
            </InputField>
        </OtFormRow>

        <OtFormRow>
            <InputField
                data-testid="events-detailsform-location"
                :label="$t('dashboard.events.edit.details.form.location.label')"
                :description="$t('dashboard.events.edit.details.form.location.description')"
                :required="true"
                :error="form.errors.location"
            >
                <LocationsInput
                    v-model="form.model.$data.location_id"
                    @input="form.errors.location = []"
                />
            </InputField>

            <InputField
                :label="$t('dashboard.events.edit.details.form.currency.label')"
                :description="$t('dashboard.events.edit.details.form.currency.description')"
                :required="form.rules.currency && form.rules.currency.includes('required')"
                :optional="form.rules.currency && form.rules.currency.includes('optional')"
                :error="form.errors.currency"
                >
                <OtInputSelect
                    class="input"
                    sort="alphabetically"
                    :options="currencies"
                    :searchable="true"
                    :internal-search="true"
                    v-model="form.model.$data.currency"
                    @input="form.errors.currency = []"
                />
            </InputField>
        </OtFormRow>

        <!-- TODO: Restore this when sealed status is included in event model -->
        <!-- <OtFormRow>
            <InputField
                :label="$t('dashboard.events.edit.details.form.sealed.label')"
                :description="$t('dashboard.events.edit.details.form.sealed.description')"
            >
                <OtInputToggle
                    class="input"
                    :label="$t('dashboard.events.edit.details.form.sealed.toggle_text')"
                    v-model="dummy.sealed"
                />
            </InputField>
        </OtFormRow> -->
    </div>
</template>

<script setup lang="ts">
import type { UnwrapNestedRefs } from 'vue';
import { computed } from 'vue';
import type { VueLocalization } from '@openticket/vue-localization';
import type { ManagementClient, Event } from '@openticket/lib-management';
import InputField from '../../../../components/form/InputField.vue';
import LocationsInput from '../../../../components/form/LocationsInput.vue';
import { injectOrFail } from '../../../../services/util';
import type { ModelFormComposableResult } from '../../../../composables/forms';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Event<ManagementClient>, Event<ManagementClient>>>
}

defineProps<Props>();

const localization = injectOrFail<VueLocalization>('localization');

const currencies = computed<Record<string, string>>(() => {
    const map: Record<string, string> = {};
    const { supportedCurrencies } = localization;

    if (supportedCurrencies) {
        for (const key of Object.keys(supportedCurrencies)) {
            map[key] = `${supportedCurrencies[key].currencySymbol} - ${supportedCurrencies[key].currencyDisplayName}`;
        }
    }

    return map;
});
</script>
