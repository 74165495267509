<template>
    <ErrorView
        v-if="error"
        :label="error.message"
    />

    <div
        v-else-if="users"
        class="modules__companies__members__list"
    >
        <OtDataGrid
            class="module__companies__members__list__grid"
            type="tiles"
            :pagination="users"
            :columns="columns"
        >
            <template #list-header>
                <OtListHeader
                    :pagination="users"
                    :title="$tc('dashboard.company.members.list.header.text', users.total ?? 0)"
                >
                    <template
                        v-if="roles.isManager || roles.isWhitelabelAdmin"
                        #actions-top
                    >
                        <div class="modules__companies__members__list__actions">
                            <button
                                class="ot-button is-outline is-small"
                                @click="$router.push({ name: 'companies.edit.members.invites' })"
                            >
                                {{ $t('dashboard.company.members.list.header.invites.text') }}
                            </button>

                            <button
                                class="ot-button"
                                @click="openModal"
                            >
                                <OtIcon
                                    type="plus"
                                    class="ot-button-icon--left"
                                />
                                {{ $t('dashboard.company.members.list.header.new_member.text') }}
                            </button>
                        </div>
                    </template>
                </OtListHeader>
            </template>

            <template #tile="{ record }">
                <CompaniesMembersListRecord :record="record" />
            </template>
        </OtDataGrid>

        <CompaniesMembersInviteModal ref="modalRef" />
    </div>

    <OtSpinner v-else />
</template>

<script setup lang="ts">
import type { AuthClient, User } from '@openticket/lib-auth';
import type { Pagination } from '@openticket/lib-crud';
import { type Column } from '@openticket/vue-dashboard-components';
import { ref, type UnwrapNestedRefs } from 'vue';
import { onBeforeRouteUpdate, useRouter } from 'vue-router/composables';
import CompaniesMembersListRecord from './CompaniesMembersListRecord.vue';
import ErrorView from '../../../../components/ErrorView.vue';
import { injectOrFail } from '../../../../services/util';
import CompaniesMembersInviteModal from './CompaniesMembersInviteModal.vue';
import { useGenericErrorHandling } from '../../../../composables';
import type { SidebarUpdateTriggers } from '../../../../layouts/types';
import type { Roles } from '../../../../plugins/types';

const { error } = useGenericErrorHandling();

const auth = injectOrFail<AuthClient>('auth');
const sidebarUpdate = injectOrFail<UnwrapNestedRefs<SidebarUpdateTriggers>>('sidebarUpdate');

const router = useRouter();
const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');

const modalRef = ref<InstanceType<typeof CompaniesMembersInviteModal>>();
const users = ref<Pagination<User<AuthClient>>>();

const columns: Column[] = [
    {
        key: [ '$data', 'email' ],
        type: 'email',
        label: 'E-mail',
        simpleTileColumnSize: 'large',
    },
    {
        key: [ '$data', 'manager' ],
        type: 'custom',
        label: 'Role',
        simpleTileColumnSize: 'default',
    },
];

void (async () => {
    // TODO isManager/isAdmin
    // TODO user.$data.manager / user.$data.two_factor_enabled
    // TODO Use pagination component (and thus allow switching pages...)
    users.value = auth.users.list();

    try {
        await users.value?.initialization;
    } catch (e) {
        // If the user ends up here, it is probably due to a permission reason.
        // This happens when a user removes themselves from a company.
        // TODO: now this call doesn't work as the company scope is not set properly.
        // TODO: After the company scope implementation, make sure that this call works.
        void sidebarUpdate.updateUserandCompanies();

        void router.push({
            name: 'companies.list',
        });
    }
})();

function openModal() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    modalRef.value?.open();
}

onBeforeRouteUpdate(async (_to, from, next) => {
    if (users.value && from.name === 'companies.edit.members.edit') {
        // Refresh the first page.
        await users.value.loadPage();
    }

    next();
});
</script>

<style lang="scss" scoped>
.modules__companies__members__list {
    &__actions {
        display: flex;
        align-items: center;
        gap: var(--ot-spacing-unit);
    }
}
</style>
