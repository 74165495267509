import { VueDashboardComponents } from '@openticket/vue-dashboard-components';
import '@openticket/vue-dashboard-components/lib/style.css';
import { VueIcons } from '@openticket/vue-icons';
import { VueInput } from '@openticket/vue-input';
import '@openticket/vue-input/style.css';
import Notifications from '@openticket/vue-notifications';
import { VueTFAConfirmationPlugin } from '@openticket/vue-tfa-confirmation';
import '@openticket/vue-tfa-confirmation/lib/style.css';
import type { VueConstructor } from 'vue';
import { reactive } from 'vue';
import { registerComponents } from '../components';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import type { ThemeModes } from './types';

export class ComponentsPlugin extends Plugin<void> {

    async install(plugins: PluginsManager, Vue: VueConstructor): Promise<void> {
        try {
            // Localization needs to be registered for @openticket/vue-input.
            // After the dependency for localization has been removed from vue-input,
            // it should be reworked here.
            await plugins.localization.registration;

            Vue.use(VueDashboardComponents);

            Vue.use(VueIcons);

            Vue.use(VueInput);

            const notifications = new Notifications({ singleNotification: true, duration: 5000 });

            Vue.use(VueTFAConfirmationPlugin);

            Vue.use(registerComponents);

            let mode: ThemeModes = 'light';

            Vue.mixin({
                provide: {
                    notifications,
                    theme: reactive({
                        get mode() {
                            return mode;
                        },
                        set mode(value: ThemeModes) {
                            mode = value;

                            const { classList } = document.body;

                            if (value === 'dark') {
                                classList.remove('is-light');
                                classList.add('is-dark');
                            } else {
                                classList.remove('is-dark');
                                classList.add('is-light');
                            }
                        },
                    }),
                },
            });

            this.resolve();
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

}
