import {
    OtLocaleFlag,
    OtLocaleSwitch,
    type PreferredLocales,
    VueLocalization,
    type PartialConfig,
} from '@openticket/vue-localization';
import '@openticket/vue-localization/lib/style.css';
import type { VueConstructor } from 'vue';
import { getGlobal, type OpenTicketGlobal } from '../openticket';
import type { PluginsManager } from './manager';
import type { LocalizationOptions } from './types';
import { Plugin } from './plugin';

export class LocalizationPlugin extends Plugin<VueLocalization> {

    async install(plugins: PluginsManager, Vue: VueConstructor): Promise<void> {
        try {
            // Registers the translation methods on the vue instance before initializing its
            // instance in the OpenTicketLocalizationVue package.
            // --- Normally VueI18n is installed manually here, but VueI18n also supports auto install.
            // --- Due to VueLocalization not exporting the augmented VueI18n constructor,
            // --- it is less bad to let it auto-install here.
            // --- window.Vue (VueConstructor) is needed for this.
            // Vue.use(VueI18n);
            window.Vue = window.Vue || Vue;

            const options: LocalizationOptions = plugins.options.localization || {};

            const localization: VueLocalization = Vue.observable(new VueLocalization(undefined, options.defaultMessages));

            // Mix the i18n instance into the Vue constructor options,
            // so it is available BEFORE running any beforeCreate hooks.
            // When installing (Vue.use) the VueI18n plugin, it mixes in a beforeCreate hook,
            // which triggers for ALL components.
            // Which requires (fails terribly otherwise) the i18n option to be set.
            Vue.mixin({
                i18n: localization.getI18n(),
                provide: {
                    localization,
                },
            });

            const OT: OpenTicketGlobal = getGlobal();

            OT.Localization = localization;

            Object.defineProperty(Vue.prototype, '$localization', {
                get: () => localization,
                set: () => {
                    console.warn('Re-setting [$localization] is not allowed, ignoring.');
                },
            });
            Object.defineProperty(Vue.prototype, '$l', {
                get: () => localization.formatters,
                set: () => {
                    console.warn('Re-setting [$l] is not allowed, ignoring.');
                },
            });
            Object.defineProperty(Vue.prototype, '$p', {
                get: () => localization.parsers,
                set: () => {
                    console.warn('Re-setting [$p] is not allowed, ignoring.');
                },
            });

            Vue.component('OtLocaleFlag', OtLocaleFlag);
            Vue.component('OtLocaleSwitch', OtLocaleSwitch);

            this.resolveRegistration();

            const localeOverwrite: string | null = new URLSearchParams(window.location.search).get('locale') || null;
            const preferredLocales: PreferredLocales = localization.preferredLocales();

            const config: PartialConfig = {
                locale: {
                    defaults: [
                        preferredLocales.userOverwrite,
                        localeOverwrite,
                        preferredLocales.browser,
                    ],
                    supported: [ 'en_GB', 'en_US', 'nl_NL', 'de_DE', 'fr_FR', 'es_ES' ],
                },
            };

            if (import.meta.env.VITE_DEFAULT_TRANSLATIONS_URL) {
                config.urls = {
                    translations: import.meta.env.VITE_DEFAULT_TRANSLATIONS_URL,
                };
            }

            await localization.init(config);

            this.resolve(localization);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

}
