import type { VueConstructor } from 'vue';
import { Plugin } from './plugin';
import type { PluginsManager } from './manager';
import { AdminActions } from '../services/admin-actions/adminActions.service';
import type { AdminActionsOptions } from './types';

export class AdminActionsPlugin extends Plugin<AdminActions> {

    async install(plugins: PluginsManager, Vue: VueConstructor): Promise<void> {
        try {
            const options: AdminActionsOptions = plugins.options.adminActions || {};

            const auth = await plugins.auth.loading;
            const whitelabel = await plugins.whitelabel.loading;

            const baseUrl: string | null = options.url
                || whitelabel.dashboard.legacy_api_url
                || null;

            const adminActions = new AdminActions({ baseUrl, http: auth.$http });

            Vue.mixin({
                provide: {
                    adminActions,
                },
            });

            this.resolve(adminActions);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

}
