import {
    START_LOCATION,
    type NavigationGuardNext,
    type Route,
    type RouteConfig,
} from 'vue-router';
import AuthCallbackView from './views/AuthCallbackView.vue';
import AuthLoginView from './views/AuthLoginView.vue';
import AuthLogoutView from './views/AuthLogoutView.vue';
import { RouterView } from '../../services/util';

export function authRoutes(): RouteConfig[] {
    return [
        {
            path: '/auth',
            component: RouterView,
            children: [
                {
                    path: 'callback',
                    name: 'auth.callback',
                    component: AuthCallbackView,
                    // This only allows navigating to the auth callback route when it is the starting route.
                    // Users can't navigate there manually by performing a navigation back action.
                    // Programmatic navigation can also not reach this route.
                    beforeEnter: (_to: Route, from: Route, next: NavigationGuardNext) => {
                        if (from === START_LOCATION) {
                            next();
                            return;
                        }

                        next(false);
                    },
                    meta: {
                        disableAuth: true,
                        title: 'dashboard.document_title.auth.callback',
                        titleFallback: 'Welcome back',
                    },
                },
                {
                    path: 'login',
                    name: 'auth.login',
                    component: AuthLoginView,
                    meta: {
                        disableAuth: true,
                        title: 'dashboard.document_title.auth.login',
                        titleFallback: 'Login',
                    },
                },
                {
                    path: 'logout',
                    alias: '/logout',
                    name: 'auth.logout',
                    component: AuthLogoutView,
                    meta: {
                        disableAuth: true,
                        title: 'dashboard.document_title.auth.logout',
                        titleFallback: 'Logout',
                    },
                },
            ],
        },
    ];
}
