<template>
    <div class="single-mutation">
        <i18n
            :path="`dashboard.revisions.item.${mutation.type}`"
            tag="p"
        >
            <template #type>
                <strong>{{ `${mutation.model.type} ${mutation.model.name}` }}</strong>
            </template>
        </i18n>

        <p class="single-mutation-meta ot-text-tiny">
            {{ localization.formatters.dateTimeLong(mutation.time) }}
            <span v-if="mutatedBy">
                {{ ' - ' }}
                {{ $t('dashboard.revisions.by') }}
                {{ mutatedBy }}
            </span>
        </p>
    </div>
</template>

<script setup lang="ts">
import type { VueLocalization } from '@openticket/vue-localization';
import type { MutationTypes } from '../../../services/revisor';
import { injectOrFail } from '../../../services/util';

type Props = {
    mutation: MutationTypes,
    mutatedBy?: string
}

const localization = injectOrFail<VueLocalization>('localization');

defineProps<Props>();
</script>

<style lang="scss" scoped>

.single-mutation {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    max-height: 5rem;
    transition: max-height 250ms ease-in-out;

    strong {
        text-transform: capitalize;
    }

    &.open {
        -webkit-line-clamp: 300;
        max-height: 50rem;
    }

    &-meta {
        color: var(--ot-color-core-light-foreground-secondary);
    }

    strong {
        font-weight: 500;
    }
}

</style>
