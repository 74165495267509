<template>
    <FormWrapper
        class="company-update"
        :form="companyForm"
        :hide-controls="hideControls"
    >
        <VerticalTabs>
            <template #default="{ selected }">
                <VerticalTabsItem
                    :label="$t('dashboard.company.edit.general.route_title')"
                    :route="{
                        name: 'companies.edit.general',
                        params: {
                            company: company.$data.guid
                        }
                    }"
                    :selected="selected"
                    :disabled="!hasEditRole"
                >
                    <CompanyGeneralForm :form="companyForm" />
                </VerticalTabsItem>
                <VerticalTabsItem
                    :label="$t('dashboard.company.edit.registration.route_title')"
                    :route="{
                        name: 'companies.edit.registration',
                        params: {
                            company: company.$data.guid
                        }
                    }"
                    :selected="selected"
                    :disabled="!hasEditRole"
                >
                    <CompanyRegistrationForm :form="companyForm" />
                </VerticalTabsItem>
                <VerticalTabsItem
                    :label="$t('dashboard.company.edit.bank_info.route_title')"
                    :route="{
                        name: 'companies.edit.bank-info',
                        params: {
                            company: company.$data.guid
                        }
                    }"
                    :selected="selected"
                    :disabled="!hasEditRole"
                >
                    <CompanyBankInfoForm :form="companyForm" />
                </VerticalTabsItem>
                <!-- TODO: Update backend to support this data -->
                <!-- <VerticalTabsItem
                    :label="$t('dashboard.company.edit.contact.route_title')"
                    :route="{
                        name: 'companies.edit.contact',
                        params: {
                            company: company.$data.guid
                        }
                    }"
                    :selected="selected"
                    :disabled="!hasEditRole"
                >
                    <CompaniesEditContactForm />
                </VerticalTabsItem> -->
                <VerticalTabsItem
                    :label="$t('dashboard.company.edit.security.route_title')"
                    :route="{
                        name: 'companies.edit.security',
                        params: {
                            company: company.$data.guid
                        }
                    }"
                    :selected="selected"
                    :disabled="!hasEditRole"
                >
                    <CompanySecurityForm :form="companyForm" />
                </VerticalTabsItem>
                <VerticalTabsItem
                    :label="$t('dashboard.company.members.route_title')"
                    :route="{
                        name: 'companies.edit.members.home',
                        params: {
                            company: company.$data.guid
                        }
                    }"
                    :selected="route.name?.startsWith('companies.edit.members') ?? false"
                >
                    <router-view />
                </VerticalTabsItem>
                <VerticalTabsItem
                    is-admin
                    :label="$t('dashboard.company.edit.actions.route_title')"
                    :route="{
                        name: 'companies.edit.actions',
                        params: {
                            company: company.$data.guid
                        }
                    }"
                    :selected="selected"
                >
                    <CompanyAdminActions
                        v-if="companySettings"
                        :company-form="companyForm"
                        :company-settings="companySettings"
                    />
                </VerticalTabsItem>
            </template>
        </VerticalTabs>
    </FormWrapper>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router/composables';
import type { AuthClient, Company, CompanyConfig } from '@openticket/lib-auth';
import type { UnwrapNestedRefs } from 'vue';
import {
    computed, reactive, ref, watch,
} from 'vue';
import type { CompanySettings, ManagementClient } from '@openticket/lib-management';
import { injectOrFail } from '../../../services/util';
import VerticalTabs from '../../../components/verticaltabs/VerticalTabs.vue';
import VerticalTabsItem from '../../../components/verticaltabs/VerticalTabsItem.vue';
import CompanyGeneralForm from '../components/forms/CompanyGeneralForm.vue';
import CompanyRegistrationForm from '../components/forms/CompanyRegistrationForm.vue';
import CompanyBankInfoForm from '../components/forms/CompanyBankInfoForm.vue';
import CompanySecurityForm from '../components/forms/CompanySecurityForm.vue';
import type { Roles } from '../../../plugins/types';
import { useUpdateForm } from '../../../composables/forms';
import FormWrapper from '../../../components/form/FormWrapperNew.vue';
import type { SidebarUpdateTriggers } from '../../../layouts/types';
import type { Context } from '../../../services/context';
import type { AttributesToUpdatePerRoute } from '../../../composables/forms/types';
import CompanyAdminActions from '../components/actions/CompanyAdminActions.vue';

type Props = {
    company: Company<AuthClient>,
}

const props = defineProps<Props>();

const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');
const auth = injectOrFail<AuthClient>('auth');
const management = injectOrFail<ManagementClient>('management');
const sidebarUpdate = injectOrFail<UnwrapNestedRefs<SidebarUpdateTriggers>>('sidebarUpdate');
const context = injectOrFail<Context>('context');
const companySettings = ref<CompanySettings<ManagementClient>>();

const route = useRoute();

const attributesToUpdatePerRoute: AttributesToUpdatePerRoute<CompanyConfig> = {
    'companies.edit.general': [ 'name', 'website', 'currency', 'locale', 'country' ],
    'companies.edit.registration': [ 'coc_name', 'coc_number', 'vat_number' ],
    'companies.edit.bank-info': [ 'bank_account_name', 'bank_account_number', 'bank_account_bic', 'payout_frequency', 'payout_reference' ],
    'companies.edit.security': [ 'force_tfa' ],
};

const companyForm = reactive(
    useUpdateForm<Company<AuthClient>, Company<AuthClient>>(
        props.company,
        auth.companies,
        {
            async onSubmit(model) {
                // Update sidebar and context
                await sidebarUpdate.updateUserandCompanies();
                const managementCompany = management.companyPath.$factory(model.$data);
                await context.updateContext('company', managementCompany);
            },
            attributesToUpdatePerRoute,
        },
    ),
);

void (async () => {
    if (context.isCompanyContext()) {
        companySettings.value = (await management.companies.find(context.company.id));
    }
})();

watch(() => props.company, () => {
    companyForm.init(props.company, auth.companies);
});

const hideControls = computed<boolean>(() => (
    typeof route.name === 'string' && (route.name.startsWith('companies.edit.members')
        || route.name.startsWith('companies.edit.actions'))
));
const hasEditRole = computed<boolean>(() => (roles.isSuperAdmin || roles.isWhitelabelAdmin || roles.isManager));
</script>
