<template>
    <div class="access-moments-update__linked-tickets">
        <ErrorView
            v-if="error"
            :label="error.message"
        />

        <OtDataGrid
            class="access-moments-update__linked-tickets__grid"
            type="table"
            :columns="columns"
            :pagination="linkedTickets"
            hide-header
            :empty-title="$t('dashboard.access_moments.linked_tickets.list.empty.title')"
        >
            <template #list-header>
                <OtListHeader
                    :pagination="linkedTickets"
                    searchable
                    search-key="name"
                    :search-placeholder="$t('common.placeholder.search')"
                    :title="$t('dashboard.access_moments.linked_tickets.list.header.title')"
                >
                    <template #actions-top>
                        <button
                            class="ot-button"
                            :title="$t('dashboard.access_moments.linked_tickets.list.header.link.title')"
                            data-testid="access-moment-link-ticket-btn"
                            @click="linkModalRef?.show(context.accessMoment.model)"
                        >
                            <OtIcon
                                type="plus"
                                class="ot-button-icon--left"
                            />
                            {{ $t('dashboard.access_moments.linked_tickets.list.header.link.text') }}
                        </button>
                    </template>
                </OtListHeader>
            </template>

            <template #empty-action-no-filter>
                <button
                    class="ot-button is-outline"
                    :title="$t('dashboard.access_moments.linked_tickets.list.header.link.title')"
                    data-testid="access-moment-empty-link-tickets-btn"
                    @click="linkModalRef?.show(context.accessMoment.model)"
                >
                    <OtIcon
                        type="plus"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.access_moments.linked_tickets.list.header.link.text') }}
                </button>
            </template>

            <template #actions="{ record }">
                <button
                    class="ot-button is-tiny is-text ot-px[sm] access-moments-update__linked-tickets__grid__item__delete"
                    data-testid="access-moment-linked-tickets-unlink"
                    @click="unlinkTicket(record.id)"
                >
                    <OtIcon
                        type="close"
                        size="small"
                    />
                </button>
            </template>
        </OtDataGrid>

        <AccessMomentTicketsLinkModal
            ref="linkModalRef"
            @submit="loadTickets"
        />
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type {
    Ticket,
    EventDate,
    ManagementClient,
} from '@openticket/lib-management';
import type { VueLocalization } from '@openticket/vue-localization';
import type { Pagination } from '@openticket/lib-crud';
import {
    ColumnTypes, OtDataGrid, type Column, type DialogController,
} from '@openticket/vue-dashboard-components';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import { useGenericErrorHandling } from '../../../composables';
import ErrorView from '../../../components/ErrorView.vue';
import AccessMomentTicketsLinkModal from '../components/AccessMomentTicketsLinkModal.vue';

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');
const localization = injectOrFail<VueLocalization>('localization');
const dialog = injectOrFail<DialogController>('dialog');

const { error, handleError } = useGenericErrorHandling();

if (!context.isAccessMomentContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const columns: Column[] = [
    {
        key: [ '$data', 'name' ],
        type: ColumnTypes.string,
        label: $t('Name'),
        simpleTileColumnSize: 'default',
    },
];

// Type adjusted copy of the contexts accessmoment to have tickets relation
const accessMoment = management.eventdates.$factory(context.accessMoment.model.$raw);
const linkedTickets = ref<Pagination<Ticket<EventDate<ManagementClient>>>>();
const linkModalRef = ref<InstanceType<typeof AccessMomentTicketsLinkModal> | null>(null);

void loadTickets();

async function loadTickets() {
    linkedTickets.value = accessMoment.tickets.list();
    await linkedTickets.value?.initialization;
}

async function unlinkTicket(id: string) {
    if (!context.isAccessMomentContext()) {
        // TODO Properly log error & localise reason.
        throw Error('Invalid context');
    }

    const confirm = await dialog.confirm({
        title: $t('dashboard.common.confirm.permanent.title') as string || '',
        description: $t('dashboard.common.confirm.permanent.description') as string || '',
        type: 'is-danger',
    });

    if (!confirm) {
        return;
    }

    try {
        // Unlink ticket
        const unlinkTicketModel = accessMoment.tickets.$factory({
            ...context.event.model.tickets.new().$data,
            guid: id,
        });
        await accessMoment.tickets.unlink(unlinkTicketModel);

        // Refresh list
        void loadTickets();
    } catch (e) {
        handleError(e);
    }
}

// TODO: Remove when upgraded to Vue 3
function $t(slug: string, values?: Record<string, unknown>) {
    return localization.getI18n().t(slug, values);
}
</script>

<style scoped lang="scss">
.access-moments-update__linked-tickets {
    &__grid {
        &__item {
            &__delete:hover {
                color: var(--ot-color-accent-orange-dark);
            }
        }
    }
}
</style>
