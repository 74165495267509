<template>
    <div
        v-if="!!tickets.total && !!tickets.records.length"
        class="module__access-moments__unlinked-tickets__list"
    >
        <OtDataGrid
            class="module__access-moments__unlinked-tickets__list__grid"
            type="table"
            :pagination="tickets"
            :columns="columns"
            hide-header
            :empty-message-active-filter="$t('dashboard.common.filters.not_found')"
            :loading-message="$t('common.loading')"
        >
            <template #list-header>
                <OtListHeader
                    :pagination="tickets"
                    :title="$t('dashboard.access_moments.unlinked_tickets.list.header.title')"
                />
            </template>

            <template #column__-data__name="{ value }">
                <span class="module__access-moments__unlinked-tickets__list__record">
                    <OtIcon type="ticket-alt" />
                    <h4>{{ value }}</h4>
                </span>
            </template>
        </OtDataGrid>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type { Event, ManagementClient, Ticket } from '@openticket/lib-management';
import type { Pagination } from '@openticket/lib-crud';
import type { Column } from '@openticket/vue-dashboard-components';
import type { Context } from '../../../services/context';
import { injectOrFail } from '../../../services/util';

const context = injectOrFail<Context>('context');

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const columns: Column[] = [
    {
        key: [ '$data', 'name' ],
        label: 'Name',
        type: 'string',
    },
];

const tickets = ref<Pagination<Ticket<Event<ManagementClient>>>>(context.event.model.tickets.list({
    defaultFilters: { unlinked_event_dates: { scope: '' } },
    perPage: 10,
}));

async function reload(): Promise<void> {
    await tickets.value.loadPage();
}

defineExpose({
    reload,
});
</script>

<style scoped lang="scss">
.module__access-moments__unlinked-tickets__list__record {
    display: flex;
    align-items: center;
    gap: var(--ot-spacing-unit);

    svg {
        color: var(--ot-color-core-light-accent-primary);
    }
}
</style>
