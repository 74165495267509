import type { RouteConfig } from 'vue-router';
import OrderDetailsView from './views/OrderDetailsView.vue';
import OrdersListView from './views/OrdersListView.vue';
import { RouterView } from '../../services/util';
import { onCompanyContextSelect } from '../../services/context/contextFilter';

export function ordersRoutes(): RouteConfig[] {
    return [
        {
            path: 'orders',
            component: RouterView,
            meta: {
                context: 'global',
                contextIfSet: 'company',
                title: 'dashboard.document_title.orders.list',
                titleFallback: 'Orders',
                onContextSelect: onCompanyContextSelect,
            },
            children: [
                {
                    path: '',
                    name: 'orders.list',
                    component: OrdersListView,
                },
                {
                    path: ':order',
                    name: 'orders.details',
                    component: OrderDetailsView,
                    meta: {
                        context: 'order',
                        title: 'dashboard.document_title.orders.order',
                        titleFallback: 'Order',
                        onContextSelect(id) {
                            if (!id || typeof id === 'symbol') {
                                return {
                                    name: 'orders.list',
                                    // This circumvents line 117 in the router plugin where the company context is
                                    // hacked-in to preserve the company context when using the browser's native back button.
                                    params: { company: '' },
                                };
                            }
                            return {
                                name: 'orders.list',
                                params: { company: id },
                            };
                        },
                    },
                },
            ],
        },
    ];
}
