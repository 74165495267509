<template>
    <ErrorView
        v-if="error"
        :label="error.message"
    />

    <div
        v-else-if="order"
        class="order-details"
        data-testid="order-details"
    >
        <div class="order-details__header">
            <h1>{{ order.$data.firstName }} {{ order.$data.lastName }}</h1>

            <div class="order-details__header__actions">
                <!-- <button class="ot-button is-outline is-small">
                    <OtIcon
                        type="edit"
                        size="small"
                        class="ot-button-icon--left"
                    />
                    Edit Order
                </button> -->

                <OrderAdminActions :order="order" />
            </div>
        </div>

        <div class="order-details__content">
            <OtDataDisplay>
                <OtDataDisplayItem
                    :label="$t('dashboard.orders.details.email')"
                    :value="order.$data.email"
                />
                <OtDataDisplayItem
                    :label="$t('dashboard.orders.details.order_id')"
                    :value="order.id"
                />
            </OtDataDisplay>

            <OtDataDisplay class="order-details__content__payment-data">
                <OtFlair
                    :icon="orderStatusFlair.icon"
                    :type="orderStatusFlair.type"
                    :style="{ alignSelf: 'center' }"
                >
                    {{ orderStatusFlair.slug ? $t(orderStatusFlair.slug) : order.$data.status }}
                </OtFlair>
                <OtDataDisplayItem
                    :label="$t('dashboard.orders.details.total_transaction_fee')"
                    :value="paymentData.hasPayment
                        ? (paymentData.isFree
                            ? paymentData.currencyFormatter(0)
                            : `${paymentData.currencyFormatter(order.$data.finn_price)}
                                (${paymentData.currencyFormatter(order.$data.finn_service_fee)})`
                        )
                        : $t('dashboard.orders.details.no_payment')"
                />
                <OtDataDisplayItem
                    :label="$t('dashboard.orders.details.payment_method')"
                    :value="paymentData.hasPayment ? paymentData.paymentMethod : $t('dashboard.orders.details.no_payment')"
                />
                <OtDataDisplayItem
                    :label="$t('dashboard.orders.details.transaction_id')"
                    :value="!paymentData.hasPayment
                        ? $t('dashboard.orders.details.no_payment')
                        : (paymentData.isFree
                            ? $t('dashboard.orders.details.is_free')
                            : paymentData.transactionId
                        )"
                />
                <OtDataDisplayItem
                    class="order-details__content__payment-data__shop-label"
                    :label="$t('dashboard.orders.details.shop.label')"
                    v-bind="shopDataProps"
                />
                <OtDataDisplayItem
                    :label="$t('dashboard.orders.details.placed_at')"
                    :value="$l.dateTimeLong(order.$data.created_at || '')"
                />
            </OtDataDisplay>

            <OtDataDisplay
                v-if="!!order.$data.metadata.length"
                class="order-details__content__metadata"
            >
                <OtDataDisplayItem
                    v-for="(item, index) in order.$data.metadata"
                    :key="index"
                    :label="item.metadata.name"
                    :value="item.value"
                />
            </OtDataDisplay>

            <div
                v-if="tickets"
                class="order-details__content__tickets"
            >
                <OtDataGrid
                    class="order-details__content__tickets__list"
                    type="tiles"
                    :pagination="tickets"
                >
                    <template #list-header>
                        <OtListHeader
                            :pagination="tickets"
                            :title="$t('dashboard.document_title.tickets.list')"
                        />
                    </template>

                    <template #tile="{ record }">
                        <OrderDetailsTicketsRecord
                            :payment-data="paymentData"
                            :record="record"
                        />
                    </template>
                </OtDataGrid>
            </div>
        </div>
    </div>

    <OtSpinner v-else />
</template>

<script setup lang="ts">
import type { Pagination } from '@openticket/lib-crud';
import type {
    ManagementClient, OrderExtended, OrderPaymentExtended, OrderTicketExtended, Shop,
} from '@openticket/lib-management';
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import type { VueLocalization } from '@openticket/vue-localization';
import OrderDetailsTicketsRecord from '../components/OrderDetailsTicketsRecord.vue';
import { injectOrFail } from '../../../services/util';
import ErrorView from '../../../components/ErrorView.vue';
import type { Context } from '../../../services/context';
import { usePaymentData } from '../composables/usePaymentData';
import { useGenericErrorHandling } from '../../../composables';
import OrderAdminActions from '../components/OrderAdminActions.vue';

const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');
const localization = injectOrFail<VueLocalization>('localization');

const order = ref<OrderExtended<ManagementClient> | null>(null);
const payments = ref<Pagination<OrderPaymentExtended<OrderExtended<ManagementClient>>> | null>(null);
const shop = ref<Shop<ManagementClient> | null>(null);
const tickets = ref<Pagination<OrderTicketExtended<OrderExtended<ManagementClient>>> | null>(null);

const router = useRouter();
const { error, handleError } = useGenericErrorHandling();
const { error: shopError, handleErrorSilently: handleShopErrorSilently } = useGenericErrorHandling();
const paymentData = reactive(usePaymentData(payments));

const shopDataProps = computed(() => {
    if (shopError.value) {
        return {
            class: 'error',
            value: localization.getI18n().t('dashboard.orders.details.shop.error'),
        };
    }

    if (shop.value) {
        return {
            value: shop.value.$data.name,
        };
    }

    return {
        value: '',
    };
});

const orderStatusFlair = computed<{ icon: string; slug: string; type: string }>(() => {
    switch (order.value?.$data.status) {
        case 'cancelled':
            return {
                icon: 'close',
                slug: 'dashboard.orders.status.cancelled',
                type: 'is-danger',
            };
        case 'expired':
            return {
                icon: 'close',
                slug: 'dashboard.orders.status.expired',
                type: 'is-danger',
            };
        case 'failed':
            return {
                icon: 'close',
                slug: 'dashboard.orders.status.failed',
                type: 'is-danger',
            };
        case 'paid':
            return {
                icon: 'check',
                slug: 'dashboard.orders.status.paid',
                type: 'is-success',
            };
        case 'pending':
            return {
                icon: 'spinner',
                slug: 'dashboard.orders.status.pending',
                type: 'is-neutral',
            };
        case 'refunded':
            return {
                icon: 'close',
                slug: 'dashboard.orders.status.refunded',
                type: 'is-danger',
            };
        case 'refunding':
            return {
                icon: 'spinner',
                slug: 'dashboard.orders.status.refunding',
                type: 'is-warning',
            };
        case 'swapped':
            return {
                icon: 'promote',
                slug: 'dashboard.orders.status.swapped',
                type: 'is-info',
            };
        case 'verify':
            return {
                icon: 'spinner',
                slug: 'dashboard.orders.status.verify',
                type: 'is-warning',
            };
        case 'unknown':
        default:
            return {
                icon: 'close',
                slug: 'dashboard.orders.status.unknown',
                type: 'is-warning',
            };
    }
});

async function setOrder() {
    if (!context.isOrderContext()) {
        void handleError(new Error('Invalid order')); // TODO Proper error
        void router.push({ name: 'orders.list' });
        return;
    }

    order.value = context.order.model;

    try {
        // TODO: Fix pagination typing after lib-crud is updated
        tickets.value = order.value.tickets.list();
        await tickets.value.initialization;

        payments.value = order.value.payments.list();
        await payments.value.initialization;
    } catch (e) {
        void handleError(e);
    }

    try {
        if (!order.value.$data.shop_id) {
            throw Error();
        }

        shop.value = await management.shops.find(order.value.$data.shop_id);
    } catch (e) {
        void handleShopErrorSilently(e);
    }
}

void setOrder();
</script>

<style scoped lang="scss">
.order-details {
    display: flex;
    flex-direction: column;
    gap: var(--ot-spacing-lg);

    &__header {
        display: flex;
        justify-content: space-between;

        &__actions {
            display: flex;
            gap: var(--ot-spacing-unit);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: var(--ot-spacing-xl);

        &__payment-data {
            :deep(.ot-flair__label) {
                margin: 0;
            }

            &__shop-label {
                &.error {
                    :deep(.data-display__item__value) {
                        color: var(--ot-color-core-light-accent-primary);
                        font-weight: 400;
                    }
                }
            }
        }

        &__metadata {
            background: var(--ot-color-core-light-background-secondary);
            padding: 1.25rem;
            border-radius: var(--ot-input-radius);
        }
    }
}
</style>
