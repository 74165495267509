import {
    AuthClient, type Config, InfoAPIAuthError, UnauthenticatedAPIAuthError,
} from '@openticket/lib-auth';
import { send, Log } from '@openticket/lib-log';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import Axios from 'axios';
import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import urlJoin from 'url-join';
import type { VueConstructor } from 'vue';
import type VueRouter from 'vue-router';
import type { NavigationGuardNext, Route, RouteRecord } from 'vue-router';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import type { AuthOptions } from './types';

function urlStartsWith(url: string, target: string): boolean {
    try {
        const a = new URL(url);
        const b = new URL(target);

        if (a.host !== b.host) {
            return false;
        }

        return a.pathname.startsWith(b.pathname);
    } catch (e) {
        return false;
    }
}

export class AuthPlugin extends Plugin<AuthClient> {

    private auth: AuthClient;

    async install(plugins: PluginsManager, Vue: VueConstructor): Promise<void> {
        async function afterLogout(): Promise<void> {
            const router: VueRouter = await plugins.router.loading;

            if (router.currentRoute.name === 'fix.profile' || router.currentRoute.name === 'fix.forgot_password') {
                // TODO @Peter - lib-auth add a 'clear tokens' (e.g. logout without hooks) method.
                return;
            }

            const intercom = await plugins.intercom.loading;
            intercom.shutdown();

            const rudderstack = await plugins.rudderstack.loading;
            rudderstack.track('vue-dashboard auth logout success');

            console.warn('Logout hook triggered, redirecting to home');

            // This will trigger a re-login, which is auto-accepted, due to session being still valid
            // TODO Fix session after logout
            void router.push({ name: 'home', query: { nonce: new Date().getTime().toFixed(0) } });
        }

        try {
            const withXSRFToken = ({ url }: InternalAxiosRequestConfig): boolean | undefined => !!url
                && urlStartsWith(url, baseUrl);

            const options: AuthOptions = plugins.options.auth || {};
            const http: AxiosInstance = Axios.create({ withCredentials: true, withXSRFToken });
            const whitelabel: Whitelabel = await plugins.whitelabel.loading;

            const { errors } = this;

            const loginUrl: string = options.loginUrl
                || whitelabel.dashboard.login_url
                || 'https://login.openticket.tech/';
            const managementUrl: string = options.managementUrl
                || whitelabel.dashboard.management_api_url
                || 'https://management.openticket.tech/';
            const customShopSettingsUrl: string = options.customShopSettingsUrl
                || whitelabel.shop.custom_shop_settings_url
                || 'https://custom.shop.openticket.tech/';
            const revisorUrl: string = options.revisorUrl
                || whitelabel.dashboard.revisor_url
                || 'https://revisor.openticket.tech/';
            const adminActionsUrl: string | null = options.adminActionsUrl
                || whitelabel.dashboard.legacy_api_url
                || null;
            const albatoUrl: string = options.albatoUrl
                || whitelabel.dashboard.albato_url
                || 'https://albato.openticket.local/';
            const baseUrl: string = options.baseUrl
                || whitelabel.dashboard.auth_code_api_url
                || whitelabel.dashboard.auth_api_url;

            const tokenGuard = (url: string): boolean => !!url && (urlStartsWith(url, baseUrl)
                || urlStartsWith(url, managementUrl)
                || urlStartsWith(url, customShopSettingsUrl)
                || urlStartsWith(url, albatoUrl)
                || urlStartsWith(url, revisorUrl)
                || (!!adminActionsUrl && urlStartsWith(url, adminActionsUrl)));

            const authConfig: Config = {
                authorizePath: loginUrl,
                blockingUpgrade(from: number, to: number | null): void {
                    console.warn('This context is blocking the upgrade of the Auth database from v%s to v%s the auth database has been closed, a refresh is needed soon.', from, to);
                    // TODO Should notify user as well.
                },
                clientId: options.clientId
                    || whitelabel.dashboard.auth_code_client_id
                    || whitelabel.dashboard.auth_client_id,
                clientSecret: options.clientSecret
                    || whitelabel.dashboard.auth_code_client_secret
                    || whitelabel.dashboard.auth_client_secret,
                http,
                afterLogout,
                path: urlJoin([ baseUrl ]),
                tokenGuard,
                upgradeBlocked(from: number, to: number | null): void {
                    console.warn('Another context is blocking the upgrade of the Auth database from v%s to v%s', from, to);

                    errors.push(`Another context is blocking the upgrade of the Auth database from v${from} to v${to || '?'}`);

                    // TODO Should notify user as well.
                },
            };

            this.auth = new AuthClient(authConfig);

            Vue.mixin({
                provide: {
                    auth: this.auth,
                },
            });

            await this.auth.$token.retrieveLastToken();

            Object.defineProperty(Vue.prototype, '$auth', {
                get: () => this.auth,
            });

            this.resolveRegistration();

            if (this.auth.$token.$info) {
                await this.auth.$token.$info;
            }

            try {
                const logContext: { [p: string]: string } = { authenticated: '' };
                try {
                    const user = (await this.auth.$token.$info)?.user;

                    logContext.authenticated = user?.guid || '';

                    if (user?.guid) {
                        const rudderstack = await plugins.rudderstack.loading;

                        rudderstack.identify(user.guid, user);
                        rudderstack.track('vue-dashboard auth existing token info retrieved');
                    }
                } catch (e) {
                    logContext.fail = e.message || '';
                }

                send({
                    href: window.location.href,
                    message: 'Dashboard: Auth initialized',
                    slug: 'dashboard.log.messages.init.auth',
                    ts: Date.now(),
                    getLogContext: (): { [p: string]: string } => logContext,
                }, Log.Debug);
            } catch (e) {
                console.debug('Failed to send auth init log message', { e });
                // Fail silently
            }

            this.resolve(this.auth);
        } catch (e: unknown) {
            // An (existing) expired token will fail dramatically,
            // while the user needs to be redirected to the login page.
            if (e instanceof UnauthenticatedAPIAuthError || e instanceof InfoAPIAuthError) {
                // Before logout can proceed, the auth plugin should resolve
                // to proceed the initialization of the other plugins.
                this.resolve(this.auth);
                await this.logout();
                return;
            }

            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

    async logout(): Promise<void> {
        try {
            await this.loading;
        } catch (e) {
            if (!this.auth) {
                throw e;
            }
        }

        try {
            // TODO @openticket/lib-auth
            await this.auth.$http.get(urlJoin(this.auth.$path, 'sessions', 'logout'));
        } catch (e) {
            // Silent fail
            // TODO Maybe log errors here??
        }

        // The onLogout hook will determine what to do next.
        await this.auth.logout();
    }

    async routeGuard(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
        try {
            if (to.matched.some((record: RouteRecord) => record.meta.disableAuth)) {
                next();
                return;
            }

            await this.registration;

            if (this.auth && this.auth.$token.$activeToken && this.auth.$token.$activeToken.shouldBeValid) {
                // Assumed to be logged in.
                // While the token should be valid (read: not expired yet),
                // this does not mean the token is valid,
                // but it is not the router's responsibility to check this.
                next();
                return;
            }

            next({
                name: 'auth.login',
                query: {
                    client_id: to.query.client_id,
                    location: to.path,
                    redirect: to.fullPath,
                },
            });
        } catch (e) {
            // TODO log error
            console.error('Failed to guard authenticated route to: %s (%s). Redirecting to the error page.', to.name, to.path);

            next({ name: 'error', query: { location: to.path, reason: e instanceof Error ? e.message : 'unknown' } }); // TODO proper reason
        }
    }

}
