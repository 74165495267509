<template>
    <OtDialogModal
        ref="modalRef"
        class="add-tickets-to-event-modal"
    >
        <OtCard>
            <OtCardHeader
                :title="props.title"
                :subtitle="props.subtitle"
            />

            <OtCardContent class="ot-mb[md]">
                <OtFormRow v-if="eventTickets && !loading">
                    <InputField
                        :label="$t('dashboard.shop_tickets.details.inputs.tickets.label')"
                        :description="$t('dashboard.shop_tickets.details.inputs.tickets.description')"
                    >
                        <OtInputSelect
                            v-model="selectedTickets"
                            class="input"
                            sort="alphabetically"
                            :options="eventTicketsFiltered"
                            searchable
                            multiple
                            @search-change="handleSearch"
                        >
                            <template #singleLabel="{ option }">
                                {{ eventTickets[option].name }}
                            </template>

                            <template #option="{ option }">
                                <div class="locations-input__list__item">
                                    <div class="locations-input__list__item__text">
                                        <span class="locations-input__list__item__text__name">
                                            {{ eventTickets[option].name }}
                                        </span>
                                    </div>
                                </div>
                            </template>
                        </OtInputSelect>
                    </InputField>
                </OtFormRow>

                <OtError v-else-if="error" />

                <OtSpinner v-else />
            </OtCardContent>

            <OtCardFooter>
                <template #left>
                    <button
                        class="ot-button is-dark"
                        :title="$t('dashboard.common.action.cancel.title')"
                        @click="close"
                    >
                        <OtIcon
                            type="close"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.common.action.cancel.text') }}
                    </button>
                </template>

                <template #right>
                    <button
                        class="ot-button"
                        :title="$t('dashboard.common.action.save.title')"
                        :disabled="Boolean(loading) || selectedTickets.length === 0"
                        @click="save"
                    >
                        <OtIcon
                            type="check"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.common.action.save.text') }}
                    </button>
                </template>
            </OtCardFooter>
        </OtCard>
    </OtDialogModal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { OtDialogModal } from '@openticket/vue-dashboard-components';
import type {
    ManagementClient, TicketData,
} from '@openticket/lib-management';
import { OtError } from '@openticket/lib-log';
import InputField from '../../../../components/form/InputField.vue';
import { injectOrFail } from '../../../../services/util';
import { useGenericErrorHandling } from '../../../../composables';

type Props = {
    title: string,
    subtitle?: string,
}

type Emits = {
    (e: 'saved', ticketGuids: string[]): void
}

const management = injectOrFail<ManagementClient>('management');

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { handleError, error } = useGenericErrorHandling();

const modalRef = ref<InstanceType<typeof OtDialogModal> | null>(null);

const eventTickets = ref<Record<string, TicketData>>({});
const eventTicketsFiltered = ref<Record<string, TicketData>>({});
const selectedTickets = ref([]);

const loading = ref(0);

function save() {
    emit('saved', selectedTickets.value);
    close();
}

function open(eventId: string) {
    if (modalRef.value) {
        modalRef.value.show();
        void loadTickets(eventId);
    }
}

function close() {
    if (modalRef.value) {
        modalRef.value.close();
        eventTickets.value = {};
        selectedTickets.value = [];
    }
}

async function loadTickets(eventId: string) {
    try {
        loading.value++;
        const event = await management.events.find(eventId);

        const tickets = event.tickets.list(100);
        await tickets.initialization;

        // TODO: Update this when Pagination is updated
        eventTickets.value = tickets.records.reduce((result: Record<string, TicketData>, obj) => {
            if (obj.$data.guid) {
                result[obj.$data.guid] = obj.$data;
            }
            return result;
        }, {});

        eventTicketsFiltered.value = eventTickets.value;
    } catch (e) {
        void handleError(e);
    } finally {
        loading.value--;
    }
}

function handleSearch(query: string) {
    eventTicketsFiltered.value = Object.keys(eventTickets.value)
        .filter((key) => {
            const event = eventTickets.value[key];
            return event.name.toUpperCase().includes(query.toUpperCase());
        })
        .reduce((res: Record<string, TicketData>, key) => {
            res[key] = eventTickets.value[key];
            return res;
        }, {});
}

defineExpose({
    open,
});
</script>

<style lang="scss" scoped>
.add-tickets-to-event-modal {
    min-width: 600px;
    overflow: visible;

    @media (max-width: 40rem) {
        min-width: unset;
        width: 100% !important;
    }

    &::v-deep {
        .card {
            overflow: visible !important;
        }

        .card-footer {
            border-radius: 0 0 var(--ot-input-radius) var(--ot-input-radius);
        }
    }
}
</style>
