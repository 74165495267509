import type { VueConstructor } from 'vue';
import { isOtError, send } from '@openticket/lib-log';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import { RudderStack } from '../services/rudderstack';

export class RudderStackPlugin extends Plugin<RudderStack> {

    async install(plugins: PluginsManager, Vue: VueConstructor): Promise<void> {
        const rudderstack = new RudderStack();

        try {
            const whitelabel = await plugins.whitelabel.loading;

            if (whitelabel.dashboard.rudderstack_write_key && whitelabel.dashboard.rudderstack_data_plane_url) {
                rudderstack.init(
                    whitelabel.dashboard.rudderstack_write_key,
                    whitelabel.dashboard.rudderstack_data_plane_url,
                );
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());

                if (isOtError(e)) {
                    send(e);
                }
            }
        } finally {
            Vue.mixin({ provide: { rudderstack } });

            this.resolve(rudderstack);
        }
    }

}
