import type { RouteConfig } from 'vue-router';
import EventWizardView from './views/EventWizardView.vue';

export function eventWizardRoutes(): RouteConfig[] {
    return [
        {
            path: 'new',
            name: 'events.new',
            component: EventWizardView,
            meta: {
                context: 'global',
                contextIfSet: 'company',
                title: 'dashboard.document_title.events.new',
                titleFallback: 'New',
            },
        },
    ];
}
