<template>
    <div class="debug-plugins__components">
        <code
            class="debug-plugins__components__status full-width"
        >
            <span>Status:</span>

            <span v-if="loading">Loading</span>
            <span v-else>Registered</span>
        </code>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import type { PluginsManager } from '../../../../plugins';
import { injectOrFail } from '../../../../services/util';

const plugins = injectOrFail<PluginsManager>('plugins');
const loading = ref<boolean>(true);

onMounted(() => {
    void initializePluginDebug();
});

async function initializePluginDebug() {
    try {
        await plugins.components.loading;
    } finally {
        loading.value = false;
    }
}
</script>

<style lang="scss" scoped>
.debug-plugins__components {
    & > code {
        display: flex;
        justify-content: space-between;
        margin-top: 1px;
    }
}
</style>
