import type { Route, RouteConfig } from 'vue-router';
import { RouterView } from '../../services/util';
import AccessMomentsListView from './views/AccessMomentsListView.vue';
import AccessMomentsUpdateView from './views/AccessMomentsUpdateView.vue';

export function accessMomentsRoutes(): RouteConfig[] {
    return [
        {
            path: 'access-moments',
            component: RouterView,
            meta: {
                title: 'dashboard.document_title.access_moments.list',
                titleFallback: 'Access Moments',
                onContextSelect: (id: string | symbol | undefined) => {
                    if (typeof id === 'string') {
                        return {
                            name: 'access-moments.list',
                            params: { event: id },
                        };
                    }
                    return null;
                },
            },
            children: [
                {
                    path: '',
                    name: 'access-moments.list',
                    component: AccessMomentsListView,
                },
                {
                    path: ':accessMoment',
                    name: 'access-moments.home',
                    component: RouterView,
                    meta: {
                        context: 'accessMoment',
                        title: 'dashboard.document_title.access_moments.home.access_moment',
                        titleFallback: 'Access moment',
                    },
                    redirect: (to: Route) => ({
                        name: 'access-moments.home.edit',
                        params: { ...to.params },
                    }),
                    children: [
                        {
                            path: 'edit',
                            name: 'access-moments.home.edit',
                            component: AccessMomentsUpdateView,
                            redirect: (to: Route) => ({
                                name: 'access-moments.edit.details',
                                params: { ...to.params },
                            }),
                            children: [
                                {
                                    path: 'details',
                                    name: 'access-moments.edit.details',
                                },
                                {
                                    path: 'linked-tickets',
                                    name: 'access-moments.edit.linked-tickets',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
}
