<template>
    <div class="revision_list_item">
        <OtAvatar
            :name="mutation.meta?.user_name"
            :size="'40'"
            class="revision_list_item--avatar"
        />
        <div class="revision_list_item--content">
            <component
                :is="mutationComponent"
                :mutation="mutation"
                :mutated-by="mutationBy"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { OtAvatar } from '@openticket/vue-dashboard-components';
import { computed } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { MutationTypes } from '../../services/revisor';
import SingleMutation from './mutations/SingleMutation.vue';
import RelationMutation from './mutations/RelationMutation.vue';
import UpdateMutation from './mutations/UpdateMutation.vue';
import { injectOrFail } from '../../services/util';

type Props = {
    mutation: MutationTypes
}

const props = defineProps<Props>();

const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const mutationBy = computed(() => {
    if (!props.mutation.meta) {
        return undefined;
    }

    return props.mutation.meta.anonymous ? whitelabel.name : props.mutation.meta.user_name;
});

const mutationComponent = computed(() => {
    if (props.mutation.type === 'relation') {
        return RelationMutation;
    }

    if (props.mutation.type === 'update') {
        return UpdateMutation;
    }

    return SingleMutation;
});
</script>

<style lang="scss" scoped>

.revision_list_item {
    display: flex;
    gap: 1.5rem;
    padding: 1rem 0;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid var(--ot-color-core-light-accent-tertiary);

    &:last-child {
        border-bottom: none;
    }

    &--avatar {
        min-width: 40px;
    }

}

</style>
