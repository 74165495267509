<template>
    <div class="fix-view">
        <OtSplash
            v-if="isLoading"
            class="app__loading"
            :spinner="$whitelabel.resources.spinner"
        />

        <div
            v-else
            class="fix-view__container"
            :class="isSmall ? 'is-small' : 'is-big'"
        >
            <router-view />

            <footer class="footer">
                <div class="footer__row">
                    <a
                        class="footer__row__logo"
                        :href="$whitelabel.website_url"
                        target="_blank"
                    >
                        <img
                            :src="$whitelabel.resources.logo"
                            alt="logo"
                        >
                    </a>

                    <div
                        class="footer__row__locale"
                        role="button"
                        tabindex="0"
                        @click="otLocaleSwitch?.toggle()"
                        @keydown.space="otLocaleSwitch?.toggle()"
                        @keydown.enter="otLocaleSwitch?.toggle()"
                    >
                        <OtLocaleFlag
                            :locale="$localization.locale"
                            size="short"
                        />

                        <i
                            class="oti is-small"
                            :class="localeSwitchOpen ? 'oti-drop-up' : 'oti-drop-down'"
                        />
                    </div>
                </div>

                <OtLocaleSwitch
                    ref="otLocaleSwitch"
                    class="footer__locale-switch"
                    @opened="onOpened"
                />

                <div>
                    {{ $t('common.copyright', { year }) }}
                </div>
            </footer>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { AuthClient } from '@openticket/lib-auth';
import { OtLocaleSwitch } from '@openticket/vue-localization';
import Axios, { isAxiosError } from 'axios';
import type { AxiosInstance } from 'axios';
import urlJoin from 'url-join';
import {
    computed, onBeforeUnmount, provide, ref,
} from 'vue';
import type { RouteRecord } from 'vue-router';
import { useRoute, useRouter } from 'vue-router/composables';
import { getXLocation } from '../services/util/xlocation';
import type { Intercom } from '../services/intercom';
import {
    getHostnameFromUrl,
    getPathFromUrl,
    injectOrFail, mapRelevantParams, urlIncludesString,
} from '../services/util';
import type { MinimalUser } from '../modules/fix/types';

const auth = injectOrFail<AuthClient>('auth');
const intercom = injectOrFail<Intercom>('intercom');

const otLocaleSwitch = ref<InstanceType<typeof OtLocaleSwitch>>();
const isLoading = ref<boolean>(false);
const localeSwitchOpen = ref<boolean>(false);
const minimalUser = ref<MinimalUser>();

provide<MinimalUser | {}>('minimal_user', minimalUser);

const isSmall = computed<boolean>(() => route.matched.some((record: RouteRecord) => record.meta.isSmall));

const route = useRoute();
const router = useRouter();

void (async (): Promise<void> => {
    try {
        const validationNeeded = !route.matched.some((record: RouteRecord) => record.meta.disableSessionValidation);

        if (validationNeeded) {
            if (!await isSessionValid()) {
                await router.push({
                    name: 'error',
                    query: { reason: 'session validation needed, but session is not valid.' },
                });
            }

            await getMinimalUser();
            loadIntercomInfo();
        }
    } finally {
        isLoading.value = false;
    }
})();

onBeforeUnmount(() => {
    intercom.shutdown();
});

async function isSessionValid(): Promise<boolean> {
    const http: AxiosInstance = Axios.create({ withCredentials: true });
    const params = route.query;

    // TODO - @openticket/lib-auth
    try {
        await http.get(urlJoin(auth.$path, 'tokens/valid'), { params });
    } catch (error: unknown) {
        if (!isAxiosError(error) || !error.response) {
            return false;
        }

        if (error.response.status === (Number(import.meta.env.VITE_LOGOUT_REDIRECT_STATUS) || 355)) {
            const xLocation = getXLocation(error);
            if (xLocation) {
                if (compareLocations(window.location.href, xLocation)) {
                    return true;
                }

                window.location.href = xLocation;
                return false;
            }
        }

        return false;
    }

    return true;
}

function compareLocations(first_url: string, second_url: string): boolean {
    if (first_url === second_url) {
        return true;
    }

    // Has equal domain
    if (window.location.host !== getHostnameFromUrl(first_url) || window.location.host !== getHostnameFromUrl(second_url)) {
        return false;
    }

    // Has Fix route
    if (!urlIncludesString(getPathFromUrl(first_url), '/fix/') || !urlIncludesString(getPathFromUrl(second_url), '/fix/')) {
        return false;
    }

    // Has appropriate queries
    const relevantQueries = [ 'client_id', 'redirect_uri', 'state' ] as const;

    const firstUrlMappedParams = mapRelevantParams(first_url, relevantQueries);
    const secondUrlMappedParams = mapRelevantParams(second_url, relevantQueries);

    for (const key of relevantQueries) {
        if (firstUrlMappedParams.has(key) !== secondUrlMappedParams.has(key)) {
            return false;
        }

        if (firstUrlMappedParams.has(key) && (firstUrlMappedParams.get(key) !== secondUrlMappedParams.get(key))) {
            return false;
        }
    }

    return true;
}

async function getMinimalUser(): Promise<void> {
    try {
        // TODO - @openticket/lib-auth
        minimalUser.value = (await auth.$http.get<MinimalUser>(urlJoin(auth.$path, 'sessions', 'me'))).data;
    } catch (e) {
        await router.push({ name: 'error' });
    }
}

function loadIntercomInfo(): void {
    if (!minimalUser.value) {
        return;
    }

    intercom.updateSettings({
        company: {
            name: null,
            company_id: null,
            dashboard_link: window.location.href,
            whitelabel_id: null,
            whitelabel_name: null,
            remote_created_at: null,
        },
        current_event_id: null,
        has_a_company: minimalUser.value.has_a_company,
        two_factor_enabled: minimalUser.value.two_factor_enabled,
        user_id: minimalUser.value.guid,
        name: minimalUser.value.name,
        email: minimalUser.value.email,
    });
}

const onOpened = (opened: boolean): void => {
    localeSwitchOpen.value = opened;
};
const year = new Date().getFullYear();
</script>

<style lang="scss" scoped>
.fix-view {
    display: flex;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    --ot-card-border-radius: var(--ot-spacing-default);

    &__container {
        display: block;
        width: 100%;
        padding: var(--ot-spacing-4xl);

        &.is-small {
            max-width: 30rem;
        }

        &.is-big {
            max-width: 50rem;
        }
    }

    footer {
        padding: var(--ot-spacing-4xl) 0;
        text-align: center;

        .footer {
            &__row {
                display: flex;
                position: relative;
                margin: 0.75rem 0;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                text-align: left;

                & > * {
                    margin: 0.75rem 0;
                }

                &__logo {
                    position: relative;
                    z-index: 5;
                    color: inherit;
                    text-decoration: none;

                    span,
                    img {
                        vertical-align: middle;
                    }

                    img {
                        height: 1.25rem;
                    }

                    span {
                        margin-right: 0.5rem;
                        color: var(--color-canvas-invert);
                        font-size: 0.75rem;
                    }
                }

                &__locale {
                    display: flex;
                    cursor: pointer;

                    .oti {
                        margin-left: 0.5rem;
                    }
                }
            }

            &__locale-switch {
                margin: var(--ot-spacing-default, 1rem) 0;
            }
        }
    }
}

@media (max-width: 25rem) {
    .fix-view footer.footer__row {
        flex-direction: column;
    }
}
</style>
