<template>
    <div class="event-wizard-tickets__upsert">
        <div class="event-wizard-tickets__upsert__docs">
            <OtDocsSection
                :label="$t('dashboard.common.read_docs')"
                :type="'tickets'"
                :url="$whitelabel.dashboard.docs_urls?.tickets?.new"
            >
                <h1>{{ $t(`dashboard.events.wizard.steps.tickets.upsert.title_${
                    wizardProvide.ticketToEditGuid.value ? 'update' : 'insert'
                }`) }}</h1>
                <span>{{ $t('dashboard.events.wizard.steps.tickets.upsert.description') }}</span>
            </OtDocsSection>
        </div>

        <OtSpinner v-if="!!loading" class="event-wizard-tickets__upsert-spinner" />

        <FormWrapper
            class="event-wizard-tickets__upsert__form"
            v-else-if="!error"
            :form="ticketForm"
        >
            <OtFormRow>
                <InputField
                    data-testid="event-wizard-ticket-form-name"
                    :label="$t('dashboard.events.wizard.steps.tickets.upsert.form.ticket_name.label')"
                    :description="$t('dashboard.events.wizard.steps.tickets.upsert.form.ticket_name.description')"
                    :required="ticketForm.rules.name && ticketForm.rules.name.includes('required')"
                    :error="ticketForm.errors.name"
                >
                    <OtInput
                        class="input"
                        type="text"
                        v-model="ticketForm.model.$data.name"
                        @input="ticketForm.errors.name = []"
                    />
                </InputField>
            </OtFormRow>

            <OtFormRow>
                <InputField
                    data-testid="event-wizard-ticket-form-available-stock"
                    :label="$t('dashboard.events.wizard.steps.tickets.upsert.form.capacity.label')"
                    :description="$t('dashboard.events.wizard.steps.tickets.upsert.form.capacity.description')"
                    :error="ticketForm.errors.available_stock"
                >
                    <InputFloatUnlimited
                        v-model="ticketForm.model.$data.available_stock"
                        @input="ticketForm.errors.available_stock = []"
                    />
                </InputField>

                <div></div>
            </OtFormRow>

            <OtFormRow>
                <InputTicketPrice
                    data-testid="event-wizard-ticket-form-min-price"
                    :currency="wizardProvide.eventForm.model.$raw.currency"
                    :form="ticketForm"
                />
            </OtFormRow>
        </FormWrapper>

        <ErrorView
            v-else-if="error"
            :label="error.message"
        />
    </div>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue';
import type {
    ManagementClient,
} from '@openticket/lib-management';
import { Create, Update } from '@openticket/lib-crud';
import InputField from '../../../components/form/InputField.vue';
import FormWrapper from '../../../components/form/FormWrapper.vue';
import type { WizardProvide } from '../types';
import ErrorView from '../../../components/ErrorView.vue';
import { injectOrFail } from '../../../services/util';
import InputFloatUnlimited from '../../../components/InputFloatUnlimited.vue';
import { useGenericErrorHandling } from '../../../composables';
import { useModelForm } from '../../../composables/forms/useModelForm';
import InputTicketPrice from '../../../components/form/InputTicketPrice.vue';

const { error, handleError } = useGenericErrorHandling();

const management = injectOrFail<ManagementClient>('management');
const wizardProvide = injectOrFail<WizardProvide>('wizardProvide');

const loading = ref(0);

// Use a copy of the parent to fix the relation url.
const event = management.events.new();
event.$raw = wizardProvide.eventForm.model.$raw;

// When the ticket guid is set, the form should be an update form.
const ticketForm = reactive(
    useModelForm(
        event.tickets.new(),
        event.tickets,
        wizardProvide.ticketToEditGuid.value ? Update : Create,
        {
            disableLocalChangesDialog: true,
        },
    ),
);

void (async () => {
    if (!wizardProvide.ticketToEditGuid.value) {
        return;
    }

    try {
        loading.value++;
        ticketForm.model.$raw = (await event.tickets.find(wizardProvide.ticketToEditGuid.value)).$raw;
    } catch (e) {
        handleError(e);
    } finally {
        loading.value--;
    }
})();

async function submit(): Promise<boolean> {
    try {
        const { success } = await ticketForm.submit();

        if (!success) {
            return false;
        }

        // Link date to ticket
        const ticketWithoutEvent = management.tickets.$factory(ticketForm.model.$raw);
        const ticketDate = ticketWithoutEvent.eventdates.$factory(wizardProvide.eventDateForm.model.$raw);
        await ticketWithoutEvent.eventdates.link(ticketDate, {});
    } catch (e) {
        void handleError(e);
        return false;
    }

    return true;
}

function resetForm() {
    // Reset name but keep the previously set values
    const keepValues = {
        vat_percentage: ticketForm.model.$data.vat_percentage,
        available_stock: ticketForm.model.$data.available_stock,
    };

    ticketForm.init(
        event.tickets.new(),
        event.tickets,
    );

    ticketForm.model.$data.vat_percentage = keepValues.vat_percentage;
    ticketForm.model.$data.available_stock = keepValues.available_stock;
}

defineExpose({
    submit,
    resetForm,
});
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/mixins.scss";

.event-wizard-tickets__upsert {
    &__docs {
        padding: var(--ot-spacing-xl) 0;
        border-bottom: 1px solid var(--ot-color-core-light-accent-tertiary);
        margin-bottom: var(--ot-spacing-xl);

        h1 {
            color: var(--ot-color-core-light-foreground-primary);
            margin-bottom: var(--ot-spacing-xs);
        }

        span {
            color: var(--ot-color-core-light-foreground-secondary);
        }
    }

    &-spinner {
        height: 20rem
    }

    &__form {
        &__availability {
            border-top: 1px solid var(--ot-color-core-light-accent-tertiary);
            margin-top: var(--ot-spacing-xl);
            padding-top: var(--ot-spacing-xl);

            span {
                color: var(--ot-color-core-light-foreground-secondary);
            }

            .fancy-radio {
                margin-top: var(--ot-spacing-lg);
            }
        }

        &::v-deep .input-field__content  {

            & > .ot-select-bar {
                flex: none;
            }

            @include breakpoint(mob) {
                flex-wrap: wrap;
                gap: var(--ot-spacing-sm);

                & > .ot-select-bar {
                    flex-basis: 100%;
                }
            }

        }
    }
}
</style>
