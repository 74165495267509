<template>
    <div
        class="shop-home-wrapper"
        data-testid="shop-home-wrapper"
    >
        <div class="shop-home-wrapper__header ot-pb[lg] ot-mb[lg]">
            <h1>{{ context.shop.name }}</h1>
            <a v-if="shopUrl" class="ot-button is-outline is-small" :href="shopUrl || '#'" target="_blank">
                <OtIcon type="show-password" class="ot-mr[xs]" size="small"/>
                {{ $t('dashboard.shop_tickets.preview_shop.text') }}
            </a>
        </div>

        <router-view></router-view>
    </div>
</template>

<script setup lang="ts">

import { computed } from 'vue';
import urlJoin from 'url-join';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { useRoute } from 'vue-router/composables';
import type { Context } from '../../../services/context';
import { injectOrFail } from '../../../services/util';

const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const context = injectOrFail<Context>('context');

const route = useRoute();

if (!context.isShopContext()) {
    throw Error('Invalid context');
}

const shopUrl = computed(() => {
    if (!whitelabel || route.name !== 'shops.tickets') {
        return null;
    }

    return urlJoin(whitelabel.shop.url, context.shop.id, '?nocache');
});

</script>

<style lang="scss">
.shop-home-wrapper {
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--ot-color-core-light-accent-tertiary);

        h1 {
            min-height: 2.5rem;
        }
    }
}
</style>
