<template>
    <div class="relation-mutation">
        <i18n
            :path="`dashboard.revisions.item.${mutation.change.create ? 'relation' : 'unlink'}`"
            tag="p"
            class="relation-mutation-single"
        >
            <template #name>
                <strong>{{ mutation.model.name }}</strong>
            </template>
            <template #parent>
                <strong>{{ `${mutation.change.parent.type} ${mutation.change.parent.name}` }}</strong>
            </template>
        </i18n>
        <p class="relation-mutation-meta ot-text-tiny">
            {{ localization.formatters.dateTimeLong(mutation.time) }}
            <span v-if="mutatedBy">
                {{ ' - ' }}
                {{ $t('dashboard.revisions.by') }}
                {{ mutatedBy }}
            </span>
        </p>
    </div>
</template>

<script setup lang="ts">
import type { VueLocalization } from '@openticket/vue-localization';
import type { Mutation } from '../../../services/revisor';
import { injectOrFail } from '../../../services/util';

type Props = {
    mutation: Mutation<'relation'>,
    mutatedBy?: string
}

const localization = injectOrFail<VueLocalization>('localization');

defineProps<Props>();
</script>

<style lang="scss" scoped>
.relation-mutation {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    max-height: 5rem;
    transition: max-height 250ms ease-in-out;

    strong {
        text-transform: capitalize;
        font-weight: 500;
    }

    &-meta {
        color: var(--ot-color-core-light-foreground-secondary);
    }
}
</style>
