import type { ComponentOptions, VueConstructor } from 'vue';
import VueRouter from 'vue-router';
import type { NavigationGuardNext, RawLocation, Route } from 'vue-router';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import ErrorView from '../modules/error/views/ErrorView.vue';
import type { Resolver, RouterOptions } from './types';
import { roleGuard } from '../services/util';

type Position = { x: number; y: number };

const persistClassicQueryParam = (to: Route, from: Route, next: NavigationGuardNext) => {
    const fromHasClassicOrLegacyQuery = !!from.query.classic || !!from.query.legacy;
    const toHasClassicOrLegacyQuery = !!to.query.classic || !!to.query.legacy;

    if (fromHasClassicOrLegacyQuery && !toHasClassicOrLegacyQuery) {
        const newRoute = {
            ...to,
            name: to.name ?? undefined,
            query: {
                ...to.query,
                classic: from.query.classic ?? undefined,
                legacy: from.query.legacy ?? undefined,
            },
        } satisfies RawLocation;

        next(newRoute);
        return;
    }

    next();
};

export class RouterPlugin extends Plugin<VueRouter> {

    resolveInitialRouteLoading: Resolver<boolean> = () => true;

    initialRouteLoading: Promise<boolean> = new Promise<boolean>((_resolve: Resolver<boolean>) => {
        this.resolveInitialRouteLoading = _resolve;
    });

    install(plugins: PluginsManager, Vue: VueConstructor): void {
        try {
            const options: RouterOptions = plugins.options.router || {};

            Vue.use(VueRouter);

            const router = new VueRouter({
                mode: 'history',
                routes: [
                    {
                        path: '/error/:log_id?',
                        name: 'error',
                        meta: {
                            disableAuth: true,
                            title: 'dashboard.document_title.error',
                            titleFallback: 'Error',
                        },
                        component: ErrorView,
                        props: (route: Route) => ({ reason: route.query.reason }),
                    },

                    ...(options.routes || []),

                    {
                        path: '*',
                        redirect: { name: 'home' },
                    },
                ],
                scrollBehavior(to: Route, from: Route, savedPosition: Position | void): Position | void {
                    if ((to.meta && to.meta.modal) || (from.meta && from.meta.modal)) {
                        return savedPosition;
                    }

                    return { x: 0, y: 0 };
                },
            });

            router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
                void plugins.auth.routeGuard(to, from, next);
            });
            router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
                persistClassicQueryParam(to, from, next);
            });

            // Role based routing
            router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
                void roleGuard(to, from, next, plugins);
            });

            // vue-router initializes the router, when it sees its router options present during component creation.
            // (i.e. in a global beforeCreate hook. As it's global, it's triggered for every component!)
            // It does not care if a router was already initialized. When used in combination with nested (dymamic?) routes.
            // With the below injection of the router options as a global mixin, it will therefore re-init the vue router.
            // When it renders a component with a vue-router component inside. This results in infinite recursion,
            // as it will then try to fill that router-view child with itself (or technically a clone of it)...
            const strats = Vue.config.optionMergeStrategies as { [key: string]: CallableFunction };
            strats.router = function routerMergeStrategy(
                parent: VueRouter | undefined,
                child: VueRouter | undefined,
                vm: ComponentOptions<Vue> | undefined,
            ): VueRouter | undefined {
                return vm ? router : undefined;
            };

            // The key needs to be present for the above strategy to work, but the value should not be there.
            Vue.mixin({ router: undefined });

            router.beforeResolve(async (to: Route, from: Route, next: NavigationGuardNext) => {
                try {
                    const context = await plugins.context.loading;

                    const { params } = to;

                    // To prevent the native browser's back button from forgetting the company context.
                    if ('company' in from.params && !('company' in to.params) && (!!context.context)) {
                        params.company = from.params.company;
                    }

                    await context.beforeRouteChange({ ...to, params });
                } catch (e: unknown) {
                    // Don't redirect to error page if we're already there
                    if (to.name !== 'error') {
                        this.resolveInitialRouteLoading(false);

                        next({
                            name: 'error',
                            query: {
                                reason: e instanceof Error ? e.message : 'Failed to update context.',
                            },
                        });

                        return;
                    }
                }

                this.resolveInitialRouteLoading(false);

                next();
            });

            router.afterEach(async (to, from) => {
                const intercom = await plugins.intercom.loading;
                intercom.updateSettings();

                const rudderstack = await plugins.rudderstack.loading;
                rudderstack.page('vue-dashboard', to.name, {
                    from: from.fullPath,
                    to: to.fullPath,
                });
            });

            this.resolve(router);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

}
