<template>
    <div class="event-wizard-tickets__list">
        <div class="event-wizard-tickets__list__docs">
            <OtDocsSection
                :label="$t('dashboard.common.read_docs')"
                :type="'tickets'"
                :url="$whitelabel.dashboard.docs_urls?.tickets?.list"
            >
                <h1>{{ $t('dashboard.events.wizard.steps.tickets.list.title') }}</h1>
                <span>{{ $t('dashboard.events.wizard.steps.tickets.list.description') }}</span>
            </OtDocsSection>
        </div>

        <OtDataGrid
            v-if="wizardProvide.eventTickets.pagination && !error"
            class="event-wizard-tickets__list__grid"
            type="table"
            :pagination="wizardProvide.eventTickets.pagination"
            :columns="columns"
            :emptyTitle="$t('dashboard.tickets.list.empty.title')"
            :emptyMessage="$t('dashboard.tickets.list.empty.message')"
        >
            <template #column__-data__available_stock="{ value }">
                {{ $l.number(value === 0 ? Infinity : value) }}
            </template>

            <template #column__-data__min_price="{ value }">
                {{ $l.currency(value, wizardProvide.eventForm.model.$data.currency) }}
            </template>

            <template #column__-data__vat_percentage="{ value }">
                {{ value }}%
            </template>

            <template #actions="{ record }">
                <button
                    class="ot-button is-tiny is-outline"
                    :title="$t('dashboard.events.wizard.steps.tickets.list.edit.title')"
                    @click="handleTicketEdit(record)"
                >
                    {{ $t('dashboard.events.wizard.steps.tickets.list.edit.text') }}
                    <OtIcon type="carrot-right" class="ot-button-icon--right" size="small" />
                </button>
            </template>
        </OtDataGrid>

        <ErrorView
            v-else-if="error"
            :label="error.message"
        />

        <OtSpinner v-else />
    </div>
</template>

<script lang="ts" setup>
import type { Event, ManagementClient, Ticket } from '@openticket/lib-management';
import type { Column } from '@openticket/vue-dashboard-components';
import type { WizardProvide } from '../types';
import ErrorView from '../../../components/ErrorView.vue';
import { injectOrFail } from '../../../services/util';
import { useGenericErrorHandling } from '../../../composables';

const { error, handleError } = useGenericErrorHandling();

const management = injectOrFail<ManagementClient>('management');
const wizardProvide = injectOrFail<WizardProvide>('wizardProvide');

const columns: Column[] = [
    {
        key: [ '$data', 'name' ],
        type: 'string',
        label: 'Name',
        simpleTileColumnSize: 'default',
    },
    {
        key: [ '$data', 'available_stock' ],
        type: 'custom',
        label: 'Capacity',
        simpleTileColumnSize: 'default',
    },
    {
        key: [ '$data', 'min_price' ],
        type: 'number',
        label: 'Price',
        simpleTileColumnSize: 'default',
    },
    {
        key: [ '$data', 'vat_percentage' ],
        type: 'number',
        label: 'VAT',
        simpleTileColumnSize: 'default',
    },
];

void (async () => {
    try {
        if (wizardProvide.eventForm.model.id) {
            const event = management.events.new();
            event.$raw = wizardProvide.eventForm.model.$raw;

            const list = event.tickets.list();
            await list.initialization;

            wizardProvide.eventTickets.update(list);
        }
    } catch (e) {
        void handleError(e);
    }
})();

function handleTicketEdit(data: Ticket<Event<ManagementClient>>) {
    wizardProvide.ticketToEditGuid.value = data.id;
    wizardProvide.showUpsertTicketView.value = true;
}
</script>

<style lang="scss" scoped>

.event-wizard-tickets__list {
    &__docs {
        padding: var(--ot-spacing-xl) 0;
        border-bottom: 1px solid var(--ot-color-core-light-accent-tertiary);
        margin-bottom: var(--ot-spacing-xl);

        h1 {
            color: var(--ot-color-core-light-foreground-primary);
            margin-bottom: var(--ot-spacing-xs);
        }

        span {
            color: var(--ot-color-core-light-foreground-secondary);
        }
    }
}
</style>
