<template>
    <div class="timeline">
        <button
            class="timeline-toggle is-link"
            @click="toggleTimeline"
            @keydown.space="toggleTimeline"
            @keydown.enter="toggleTimeline"
        >
            <template v-if="!showTimeline">
                {{ $t('dashboard.revisions.toggle.open') }}
            </template>
            <template v-if="showTimeline">
                {{ $t('dashboard.revisions.toggle.close') }}
            </template>
            <OtIcon
                type="drop-down"
                size="small"
                :class="{open: showTimeline}"
            />
        </button>
        <div
            class="timeline-content"
            :class="{open: showTimeline}"
        >
            <div
                v-for="[key, value] in Object.entries(items)"
                :key="key"
                class="timeline-content__item"
            >
                <i18n
                    path="dashboard.revisions.item.update.single"
                    tag="p"
                >
                    <template #name>
                        <strong>{{ getKeyTranslation(key) }}</strong>
                    </template>
                    <template #oldValue>
                        <s>{{ parseChange(key, value.old, model) }}</s>
                    </template>
                    <template #newValue>
                        <strong>{{ parseChange(key, value.val, model) }}</strong>
                    </template>
                </i18n>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type { TranslateResult, VueLocalization } from '@openticket/vue-localization';
import type { RevisionModelType, UpdateMutation } from '../../services/revisor';
import { humanize, parseChange } from '../revisions/changeFormatter';
import { injectOrFail } from '../../services/util';

type Props = {
  items: UpdateMutation['changes'],
  model: RevisionModelType
}

const localization = injectOrFail<VueLocalization>('localization');

const props = defineProps<Props>();

const showTimeline = ref<boolean>(false);

function toggleTimeline(): void {
    showTimeline.value = !showTimeline.value;
}

function getKeyTranslation(key: string): string | TranslateResult {
    const tKey = `models.${props.model}.attributes.${key}.label`;
    if (localization.getI18n().te(tKey)) {
        return localization.getI18n().t(tKey);
    }
    return humanize(key);
}
</script>

<style lang="scss" scoped>
.timeline {
  &-toggle {
    color: var(--ot-color-core-brand);
    font-size: 13px;
    line-height: 1rem;
    display: flex;
    align-items: center;
    gap: 0.125rem;
    cursor: pointer;
    font-weight: 600;
    padding: 0;
    height: auto;
    font-family: var(--ot-text-font-family);

    > svg {
      transform: rotate(0deg);
      transition: transform 250ms ease-in-out;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  &-content {
    padding: 0 0 0 2rem;
    position: relative;
    max-height: 0px;
    transition: all 250ms ease-in-out;
    overflow: hidden;

    &.open {
      padding: 1rem 0 1rem 2rem;
      max-height: 30rem;
      overflow-y: auto;
    }

    &__item {
      position: relative;
      padding-bottom: 1rem;
      font-size: 14px;
      line-height: 18px;

      strong {
        font-weight: 500;
      }

      &::before {
        content: '';
        position: absolute;
        left: calc(-2rem + 5px);
        top: 0;
        width: 1px;
        height: 100%;
        background: var(--ot-color-core-brand);
      }

      &:after {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        display: block;
        left: calc(-2rem + 2.5px);
        border-radius: 6px;
        top: 6px;
        background: var(--ot-color-core-brand);
      }

      &:first-child {
        &::before {
          top: 9px;
          width: 1px;
          height: calc(100% - 9px);
          background: var(--ot-color-core-brand);
        }
      }

      &:last-child {
        padding-bottom: 0;
        &::before {
          bottom: 9px;
          width: 1px;
          height: 9px;
          background: var(--ot-color-core-brand);
        }
      }
    }
  }
}

</style>
