<template>
    <div>
        <iframe
            v-show="!loading"
            ref="iframeRef"
            v-resize="{
                heightCalculationMethod: 'lowestElement',
                log: false,
            }"
            :src="appLibraryUrl"
            :title="$t('dashboard.document_title.app_library.title')"
            frameborder="0"
            width="100%"
        />

        <OtSpinner v-if="loading" />

        <ErrorView
            v-if="error"
            :label="error.message"
        />
    </div>
</template>

<script lang="ts" setup>
import type { AuthClient } from '@openticket/lib-auth';
import type { VueLocalization } from '@openticket/vue-localization';
import { onMounted, ref, watch } from 'vue';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import { useGenericErrorHandling } from '../../../composables';
import ErrorView from '../../../components/ErrorView.vue';

const auth = injectOrFail<AuthClient>('auth');
const context = injectOrFail<Context>('context');
const localization = injectOrFail<VueLocalization>('localization');

const { error, handleError } = useGenericErrorHandling();

const token = auth.$token.$activeToken?.access_token || null;

const appLibraryUrl = ref<string | null>(null);
const iframeRef = ref<HTMLIFrameElement | null>(null);
const loading = ref<boolean>(true);

onMounted(() => {
    appLibraryUrl.value = getAppLibraryUrl();

    if (iframeRef.value) {
        iframeRef.value.onload = () => {
            loading.value = false;
        };
    }
});

// Change iframe url when company switch is made.
watch(() => context.company, (value) => {
    if (value !== null) {
        appLibraryUrl.value = getAppLibraryUrl();
    }
});

function getAppLibraryUrl(): string | null {
    const url = new URL('https://eventix.io/app_library/iframe');

    try {
        if (!context.isCompanyContext()) {
            // TODO Proper error handling and logging
            throw Error('Invalid context');
        }

        if (!token) {
            // TODO Proper error handling and logging
            throw Error('App Library should be initialized with access token');
        }

        url.searchParams.set('locale', localization.locale.locale);
        url.searchParams.set('token', token);
        url.searchParams.set('company_id', context.company.id);

        return url.toString();
    } catch (e: unknown) {
        void handleError(e);
        return null;
    }
}
</script>
