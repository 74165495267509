<template>
    <ErrorView v-if="error" :label="error.message" />

    <OtSpinner v-else-if="loading" />

    <div class="shop-styling" v-else>
        <div class="shop-styling__tabs ot-mb[lg]">
            <div class="shop-styling__tabs-items">
                <VerticalTabs>
                    <template v-slot="{ selected }">
                    <VerticalTabsItem
                        :label="$t('dashboard.shops.styling.style.route_title')"
                        :route="{ name: 'shops.styling.style' }"
                        :selected="selected"
                    >
                        <ShopsSettingsStyle />
                    </VerticalTabsItem>
                    <VerticalTabsItem
                        :label="$t('dashboard.shops.styling.shop.route_title')"
                        :route="{ name: 'shops.styling.shop' }"
                        :selected="selected"
                    >
                        <ShopsSettingsShop />
                    </VerticalTabsItem>
                    <VerticalTabsItem
                        :label="$t('dashboard.shops.styling.order.route_title')"
                        :route="{ name: 'shops.styling.order' }"
                        :selected="selected"
                    >
                        <ShopsSettingsOrder />
                    </VerticalTabsItem>
                    </template>
                </VerticalTabs>
            </div>
            <div class="shop-styling__tabs__preview">
                <div class="shop-styling__tabs__preview-container">
                    <OtPreview
                        :headerLabel="$t('dashboard.tickets.ticket_design.preview.header')"
                        :src="shopUrl"
                        type="iframe"
                        ref="previewRef"
                        @load="onLoadPreview"
                    />
                </div>
            </div>
        </div>
        <div class="shop-styling__footer">
            <OtPageFooter>
                <template #left>
                    <button class="ot-button is-dark" @click="$router.back()">
                        <OtIcon type="arrow-left" />
                        {{ $t('dashboard.common.action.back.text') }}
                    </button>
                </template>
                <template #right>
                    <button class="ot-button" @click="save" :disabled="!hasLocalChanges">
                        <OtIcon type="check" />
                        {{ $t('dashboard.common.action.save.text') }}
                    </button>
                </template>
            </OtPageFooter>
        </div>
    </div>
</template>

<script setup lang="ts">
import type VueNotifications from '@openticket/vue-notifications';
import {
    computed, provide, ref, watch,
} from 'vue';
import type { WatchStopHandle } from 'vue';
import { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import axios from 'axios';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { AuthClient } from '@openticket/lib-auth';
import type { PluginsManager } from 'src/plugins';
import urlJoin from 'url-join';
import { OtPreview, type DialogController } from '@openticket/vue-dashboard-components';
import type { VueLocalization } from '@openticket/vue-localization';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router/composables';
import type { NavigationGuard } from 'vue-router';
import { MinimalCrossWindowClient } from '../services/minimalCrossWindowClient';
import type { Context } from '../../../services/context';
import { injectOrFail } from '../../../services/util';
import { useGenericErrorHandling } from '../../../composables';
import ErrorView from '../../../components/ErrorView.vue';
import VerticalTabs from '../../../components/verticaltabs/VerticalTabs.vue';
import VerticalTabsItem from '../../../components/verticaltabs/VerticalTabsItem.vue';
import ShopsSettingsStyle from '../components/settings/ShopsSettingsStyle.vue';
import ShopsSettingsShop from '../components/settings/ShopsSettingsShop.vue';
import ShopsSettingsOrder from '../components/settings/ShopsSettingsOrder.vue';

const context = injectOrFail<Context>('context');
const dialog = injectOrFail<DialogController>('dialog');
const localization = injectOrFail<VueLocalization>('localization');
const notifications = injectOrFail<VueNotifications>('notifications');
const plugins = injectOrFail<PluginsManager>('plugins');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const { error, handleError } = useGenericErrorHandling();

const hasLocalChanges = ref<boolean>(false);
const loading = ref(0);

const settings = ref<CustomShopSettingsClient>(new CustomShopSettingsClient());
let stopWatcher: WatchStopHandle | null = null;
const previewRef = ref<InstanceType<typeof OtPreview> | null>();
const crossWindowClient = ref<MinimalCrossWindowClient | null>();

const shopUrl = computed(() => {
    if (!whitelabel || !context.isShopContext()) {
        return '';
    }

    return urlJoin(whitelabel.shop.url, context.shop.id, '?nocache');
});

watch(() => context?.shop?.id, async () => {
    await initShopSettings();
});

provide('settings', settings.value);
provide('previewRef', previewRef);

async function initShopSettings(showSpinner = true) {
    if (loading.value) {
        return;
    }

    loading.value += showSpinner ? 1 : 0;
    if (stopWatcher) {
        stopWatcher();
    }
    await loadShopSettings();
    loading.value -= showSpinner ? 1 : 0;
}

async function loadShopSettings() {
    if (context.isShopContext()) {
        try {
            const auth: AuthClient = await plugins.auth.loading;
            await settings.value.init({
                baseUrl: whitelabel.shop.custom_shop_settings_url || 'https://custom.shop.openticket.tech/',
                disableCache: true,
                httpRead: axios.create(),
                httpWrite: auth.$http,
                shopId: context.shop.id,
            });

            stopWatcher = watch(() => settings.value.static, () => {
                hasLocalChanges.value = true;
                crossWindowClient.value?.setStaticShopSettings(settings.value.static);
            }, { deep: true });
        } catch (e) {
            void handleError(e);
        }
    }
}

void initShopSettings();

async function save() {
    if (context.isShopContext()) {
        try {
            settings.value.setCompanyScope(context.company.id);
            await settings.value.updateStaticSettings(settings.value.static);
            hasLocalChanges.value = false;
            notifications.success({ message: localization.getI18n().t('dashboard.common.notification.save.success') as string });
            sendLegacyDashboardCallback();
        } catch (err) {
            notifications.danger({ message: localization.getI18n().t('dashboard.common.notification.save.fail') as string });
        }
    }
}

async function onLoadPreview(): Promise<void> {
    try {
        if (previewRef.value && previewRef.value.contentRef && previewRef.value.contentRef.contentWindow) {
            crossWindowClient.value = new MinimalCrossWindowClient(previewRef.value.contentRef.contentWindow);
            await crossWindowClient.value.connecting;
            crossWindowClient.value.setStaticShopSettings(settings.value.static);
        }
    } catch (e) {
        void handleError(e);
    }
}

function isOpenerWindowProxy(opener: unknown): opener is WindowProxy {
    return !!opener && !!(opener as { postMessage?: unknown }).postMessage;
}

function sendLegacyDashboardCallback(): void {
    try {
        if (isOpenerWindowProxy(window.opener)) {
            window.opener.postMessage('shop-settings-updated', 'https://dashboard.eventix.io');
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.debug('error while calling legacy dashboard', e);
    }
}

const handleRouteChange: NavigationGuard = async (to, from, next) => {
    if (hasLocalChanges.value) {
        const confirm = await dialog.confirm({
            // TODO: Replace translation function in Vue 3
            title: localization.getI18n().t('dashboard.common.confirm.unsaved_changes.title') as string || '',
            description: localization.getI18n().t('dashboard.common.confirm.unsaved_changes.description') as string || '',
            type: 'is-danger',
        });

        if (!confirm) {
            return;
        }

        // Reset shop forms when navigating between vertical tabs items and the data is discarded
        if (previewRef.value && to.name?.startsWith('shops.styling')) {
            await initShopSettings(false);
            previewRef.value.reloadContent();
        }

        hasLocalChanges.value = false;
    }

    next();
};

onBeforeRouteLeave(handleRouteChange);
onBeforeRouteUpdate(handleRouteChange);
</script>

<style lang="scss" scoped>
.shop-styling {
    &__header {
        border-bottom: 1px solid var(--ot-color-core-light-accent-tertiary);

        h1 {
            min-height: 2.25rem;
        }
    }

    &__tabs {
        display: flex;
        flex-direction: row;
        gap: var(--ot-spacing-3xl);

        &-items {
            flex: 4;
        }

        &__preview {
            @media (max-width: 75rem) {
                display: none;
            }

            flex: 2;
            margin-top: 2.5rem;

            &-container {
                position: sticky;
                top: calc(var(--ot-layout-header-height, 5.5rem) + 1rem);
                right: 0;
                height: calc(80vh - var(--ot-layout-header-height, 5.5rem) + 1rem);
                min-height: 50vh;

                &::v-deep {
                    .preview {
                        min-height: 50vh;
                    }
                }
            }
        }
    }

    &__footer {
        button {
            gap: var(--ot-spacing-sm)
        }
    }
}
</style>
