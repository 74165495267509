<template>
    <div class="module__profile__change-password">
        <div v-if="auth">
            <div class="module__profile__change-password__title">
                <div>
                    <h2>{{ $t('dashboard.user.change_password.title') }}</h2>

                    <div class="ot-label">{{ $t('dashboard.user.change_password.explanation') }}</div>
                </div>
            </div>

            <div class="form">
                <InputField
                    :label="$t('dashboard.user.change_password.old_password')"
                    :error="form.errors.old_password"
                    :required="form.rules.old_password?.includes('required')"
                    :optional="form.rules.old_password?.includes('optional')"
                >
                    <OtInput
                        class="input"
                        type="password"
                        v-model="form.model.$data.old_password"
                        :invalid="form.errors.old_password?.length > 0"
                        @input="form.errors.old_password = []"
                        :placeholder="$t('dashboard.user.change_password.old_password')"
                    />
                </InputField>

                <OtFormRow>
                    <InputField
                        :label="$t('dashboard.user.change_password.new_password')"
                        :error="form.errors.new_password"
                        :required="form.rules.new_password?.includes('required')"
                        :optional="form.rules.new_password?.includes('optional')"
                    >
                        <OtInput
                            class="input"
                            type="password"
                            v-model="form.model.$data.new_password"
                            :invalid="form.errors.new_password?.length > 0"
                            @input="form.errors.new_password = []"
                            :placeholder="$t('dashboard.user.change_password.new_password')"
                        />
                    </InputField>

                    <InputField
                        :label="$t('dashboard.user.change_password.new_password_confirmation')"
                        :required="form.rules.new_password?.includes('required')"
                        :optional="form.rules.new_password?.includes('optional')"
                        @input="form.errors.new_password_confirmation = []"
                        :error="form.errors.new_password_confirmation"
                    >
                        <OtInput
                            class="input"
                            type="password"
                            v-model="form.model.$data.new_password_confirmation"
                            :invalid="form.errors.new_password_confirmation?.length > 0"
                            @input="form.errors.new_password_confirmation = []"
                            :placeholder="$t('dashboard.user.change_password.new_password_confirmation')"
                        />
                    </InputField>
                </OtFormRow>
            </div>

            <div class="module__profile__change-password__submit">
                <button
                    class="ot-button is-small"
                    type="button"
                    :disabled="changePasswordFormNotCompleted"
                    @click="submit()"
                    :title="$t('dashboard.user.change_password.button.title')"
                >
                    <OtIcon class="ot-button-icon--left" type="check" />
                    {{ $t('dashboard.user.change_password.button.text') }}
                </button>
            </div>

            <OtInlineModal ref="modalTfaConfirmation">
                <OTTFAConfirmation
                    v-if="tfaTypes"
                    :error="error"
                    :tfaTypes="tfaTypes"
                    :requirePassword="false"
                    @submit="submitAfterTfaConfirmation"
                    @back="tfaSetupClose"
                    hide-logo
                    hide-logout
                />
            </OtInlineModal>
        </div>

        <OtSpinner v-else />
    </div>
</template>

<script lang="ts" setup>
import type { AuthClient, Password, ResetPassword } from '@openticket/lib-auth';
import { OtInlineModal } from '@openticket/vue-dashboard-components';
import { computed, ref, type UnwrapNestedRefs } from 'vue';
import type { TFAError, TFATypes } from '@openticket/vue-tfa-confirmation';
import InputField from '../../../../components/form/InputField.vue';
import { injectOrFail } from '../../../../services/util';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { useGenericErrorHandling } from '../../../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<ResetPassword<Password<AuthClient>>, ResetPassword<Password<AuthClient>>>>,
}

const props = defineProps<Props>();

const auth = injectOrFail<AuthClient>('auth');

const modalTfaConfirmation = ref<InstanceType<typeof OtInlineModal>>();
const tfaTypes = ref<TFATypes | null>(null);
const { handleError } = useGenericErrorHandling();

// TODO: replace OTTFAConfirmation error with lib-auth
const error = ref<TFAError>({
    incorrectPassword: null,
    incorrectTFAInput: null,
});

const changePasswordFormNotCompleted = computed(
    () => !props.form.model.$data.old_password || !props.form.model.$data.new_password
        || !props.form.model.$data.new_password_confirmation,
);

async function submit(): Promise<void> {
    try {
        const response = await props.form.submit();

        if (response.success) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            void modalTfaConfirmation.value?.close();
            return;
        }

        if (props.form.errors.tfa_type) {
            // TODO: change OTTFAConfirmation to work with TwoFactor from lib-auth
            const twoFactorTypes = await auth.twofactor.listAll();
            tfaTypes.value = {};
            for (const [ key, value ] of Object.entries(twoFactorTypes)) {
                tfaTypes.value[key] = value.$raw;
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            modalTfaConfirmation.value?.open();
        } else if (props.form.errors.tfa_input && props.form.errors.tfa_input[0].message) {
            error.value = {
                incorrectPassword: null,
                incorrectTFAInput: props.form.errors.tfa_input[0].message,
            };
        }
    } catch (e) {
        handleError(e);
    }
}

async function tfaSetupClose(): Promise<void> {
    if (!auth) {
        return;
    }
    props.form.model.$data.tfa_type = null;
    props.form.model.$data.tfa_input = null;
    error.value.incorrectTFAInput = null;

    // TODO: change OTTFAConfirmation to work with TwoFactor from lib-auth
    const twoFactorTypes = await auth.twofactor.listAll();
    tfaTypes.value = {};
    for (const [ key, value ] of Object.entries(twoFactorTypes)) {
        tfaTypes.value[key] = value.$raw;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    void modalTfaConfirmation.value?.close();
}

async function submitAfterTfaConfirmation(tfaConfirmed: { input: string, type: string }): Promise<void> {
    if (!auth) {
        return;
    }

    props.form.model.$data.tfa_type = tfaConfirmed.type;
    props.form.model.$data.tfa_input = tfaConfirmed.input;

    await submit();
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/mixins.scss";

.module__profile__change-password {
    &__title {
        display: flex;
        margin-top: var(--ot-spacing-xl);

        & > * {
            flex-grow: 1;
        }

        .ot-button {
            flex-grow: 0;
            width: 20%;
        }

        &__subtitle {
            color: grey;
        }
    }

    &__submit {
        display: flex;
        margin-top: var(--ot-spacing-lg);

        .ot-button {
            margin-left: auto;
            margin-right: 0;
            width: 100%;

            @include breakpoint(tab) {
                width: auto;
            }
        }
    }
    .form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

</style>
