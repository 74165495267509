<template>
    <div class="debug-plugins__plugin">
        <div
            class="debug-plugins__plugin__header"
            role="switch"
            tabindex="0"
            :aria-expanded="opened"
            :aria-checked="opened"
            @click="toggle"
            @keydown.space="toggle"
            @keydown.enter="toggle"
        >
            <code class="full-width">{{ name }}:</code>

            <code
                v-if="plugin.errors.length"
                class="is-failure"
            >#{{ plugin.errors.length }}</code>
        </div>

        <section v-if="plugin.errors.length">
            <code
                class="full-width is-failure"
                v-for="(error, i) in plugin.errors"
                :key="`debug-plugins__plugin__error__${i}`"
            >{{ error }}</code>
        </section>

        <section class="ot-text-small" v-if="opened"><slot>Add a slot to show something here!</slot></section>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

type Props = {
    name: string;
    plugin: Plugin;
};
defineProps<Props>();

const opened = ref<boolean>(false);

function toggle() {
    opened.value = !opened.value;
}
</script>

<style lang="scss" scoped>
.debug-plugins__plugin {
    & > &__header {
        display: flex;
        cursor: pointer;

        & > code.full-width {
            flex-grow: 1;
        }
    }

    & > section {
        display: block;
        padding-left: 1rem;

        & > * {
            flex-grow: 1;
        }
    }
}
</style>
