import { OtError } from '@openticket/lib-log';

export class ContextRouteValueKeyReservedError extends OtError {

    readonly key: string;

    constructor(key: string) {
        super('ContextRouteValueKeyReservedError', 'dashboard.errors.plugins.context.route.value.key.reserved', 'Failed to set context route value. Reserved key used.');

        this.key = key;
        this.addContext('key', key);

        this.captureStackTrace(ContextRouteValueKeyReservedError);
    }

}
