<template>
    <FormWrapper
        class="date-update"
        :form="dateForm"
        back-route="dates.list"
    >
        <VerticalTabs>
            <template #default="{ selected }">
                <VerticalTabsItem
                    :label="$t('dashboard.dates.details.route_title')"
                    :route="{ name: 'dates.edit.details' }"
                    :selected="selected"
                >
                    <DateDetailsForm :form="dateForm" />
                </VerticalTabsItem>
            </template>
        </VerticalTabs>
    </FormWrapper>
</template>

<script setup lang="ts">
import {
    reactive,
    ref,
} from 'vue';
import type {
    Event,
    EventDate,
    ManagementClient,
} from '@openticket/lib-management';
import { useUpdateForm } from '../../../composables/forms';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import DateDetailsForm from '../components/forms/DateDetailsForm.vue';
import VerticalTabs from '../../../components/verticaltabs/VerticalTabs.vue';
import VerticalTabsItem from '../../../components/verticaltabs/VerticalTabsItem.vue';
import FormWrapper from '../../../components/form/FormWrapperNew.vue';
import type RevisionModal from '../../../components/revisions/RevisionModal.vue';

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');

const revisionsModalRef = ref<InstanceType<typeof RevisionModal> | null>(null);

if (!context.isDateContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const dateForm = reactive(
    useUpdateForm<EventDate<ManagementClient>, Event<ManagementClient>>(context.date.model, context.event.model.eventDates, {
        async onSubmit() {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await revisionsModalRef.value?.updateRevisions();
        },
    }),
);
</script>
