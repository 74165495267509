<template>
    <ErrorView
        v-if="error"
        :label="error.message"
    />

    <OtSpinner v-else-if="!tickets" />

    <div
        v-else
        class="module__tickets__list"
    >
        <ComingSoon :number-of-graphs="3" />

        <OtDataGrid
            data-testid="ticketslistview-datagrid"
            class="module__tickets__list__grid"
            type="tiles"
            :pagination="tickets"
            :columns="columns"
            show-switcher
            show-controls="always"
            :empty-title="$t('dashboard.tickets.list.empty.title')"
            :empty-message="$t('dashboard.tickets.list.empty.message')"
            :empty-message-active-filter="$t('dashboard.common.filters.not_found')"
            :loading-message="$t('dashboard.tickets.list.header.loading')"
        >
            <template #list-header="{ typeSwitcher }">
                <OtListHeader
                    :pagination="tickets"
                    searchable
                    :search-key="'name'"
                    :search-placeholder="$t('dashboard.tickets.list.header.search.placeholder')"
                    :title="$t('dashboard.document_title.tickets.list')"
                >
                    <template #filters>
                        <OtFilters
                            :categories-to-hide="filterCategoriesToHide"
                            :labels="$t('models.ticket')"
                            :pagination="tickets"
                            :translations="$t('dashboard.common.filters')"
                            :view-filter="{ label: context.event?.name }"
                        />
                    </template>

                    <template #actions-top>
                        <button
                            :title="$t('dashboard.tickets.list.new.title')"
                            class="ot-button"
                            data-testid="ticket-list-new-button"
                            @click="formModal.open()"
                        >
                            <OtIcon
                                type="plus"
                                class="ot-button-icon--left"
                            />
                            {{ $t('dashboard.tickets.list.new.text') }}
                        </button>
                    </template>

                    <template #actions-bottom>
                        <component :is="typeSwitcher" />
                    </template>
                </OtListHeader>
            </template>

            <template #empty-action-no-filter>
                <button
                    class="ot-button is-outline"
                    :title="$t('dashboard.tickets.list.new.title')"
                    @click="formModal.open()"
                >
                    <OtIcon
                        type="plus"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.tickets.list.new.text') }}
                </button>
            </template>

            <template #tile="{ record }">
                <TicketsListRecord
                    :record="record"
                    :currency="currency"
                />
            </template>

            <template #column__-data__available_stock="{ record, value }">
                <FractionColumn
                    :numerator="record.$data.sold_count"
                    :denominator="value"
                />
            </template>

            <template #column__-data__min_price="{ value }">
                <!-- TODO Pricing here is a lot harder than just min_price. Needs to be discussed!! -->
                {{ $l.currency(value, currency) }}
            </template>

            <template #actions="{ record }">
                <OtTileActions
                    :buttons="[
                        {
                            title: $t('dashboard.tickets.list.edit.title', { ticket: record.$data.name }),
                            text: $t('dashboard.tickets.list.edit.text', { ticket: record.$data.name }),
                            icon: 'carrot-right',
                            classes: 'is-outline is-small',
                            clickHandler: () => goToTicketEditPage(record.$data.event_id, record.$data.guid)
                        }
                    ]"
                />
            </template>
        </OtDataGrid>

        <TicketCreateModal
            v-if="formModal.isOpen"
            :modal="formModal"
            @submit="(model) => tickets?.loadPage()"
        />
    </div>
</template>

<script lang="ts" setup>
import type { Column } from '@openticket/vue-dashboard-components';
import { reactive, watch } from 'vue';
import { useRouter } from 'vue-router/composables';
import type VueRouter from 'vue-router';
import TicketsListRecord from '../components/TicketsListRecord.vue';
import ErrorView from '../../../components/ErrorView.vue';
import FractionColumn from '../../../components/columns/FractionColumn.vue';
import type { Context } from '../../../services/context';
import { injectOrFail } from '../../../services/util';
import ComingSoon from '../../../components/ComingSoon.vue';
import TicketCreateModal from '../components/TicketCreateModal.vue';
import { useGenericErrorHandling, useRouterPagination } from '../../../composables';
import { useFormModal } from '../../../composables/forms';

const { error } = useGenericErrorHandling();
const formModal = reactive(useFormModal());

const context = injectOrFail<Context>('context');
const router: VueRouter = useRouter();

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const { list: tickets, setPagination } = useRouterPagination(context.event.model.tickets, { perPage: 10 });

const { currency } = context.event.model.$data;

const columns: Column[] = [
    {
        key: [ '$data', 'name' ],
        type: 'string',
        label: 'Name',
        hideInSimpleTile: true,
    },
    {
        key: [ '$data', 'min_price' ],
        type: 'custom',
        label: 'Price',
        simpleTileColumnSize: 'small',
    },
    {
        key: [ '$data', 'available_stock' ],
        type: 'custom',
        label: 'Capacity',
    },
];

const filterCategoriesToHide = [
    'availability_margin',
    'barcode_type',
    'class',
    'combines_products',
    'created_at',
    'hide_without_coupon',
    'increment',
    'late_personalization',
    'max_orderable_amount_per_order',
    'min_orderable_amount_per_order',
    'status_overrule',
    'swappable',
    'updated_at',
];

// Reload pagination when event context changes.
watch(() => context.event?.id, (value) => {
    if (value !== null && value !== undefined) {
        void setPagination(context.event.model.tickets);
    }
});

function goToTicketEditPage(event: string, ticket: string): void {
    void router.push({
        name: 'tickets.edit',
        params: { event, ticket },
    });
}
</script>

<style lang="scss" scoped>
.module__tickets__list {
    padding-top: var(--ot-spacing-default);

    &__filters {
        display: flex;
        flex-direction: row;
        gap: var(--ot-spacing-default);
    }
}
</style>
