<template>
    <div
        v-if="dates && !error"
        class="module__events__dates__list"
    >
        <OtDataGrid
            class="module__events__dates__list__grid"
            type="tiles"
            :pagination="dates"
            :columns="columns"
            show-switcher
            :empty-title="$t('dashboard.events.dates.list.empty.title')"
            :empty-message="$t('dashboard.events.dates.list.empty.message')"
            :empty-message-active-filter="$t('dashboard.common.filters.not_found')"
            :loading-message="$t('dashboard.events.dates.list.header.loading')"
        >
            <template #list-header="{ typeSwitcher }">
                <OtListHeader
                    :pagination="dates"
                    :search-key="'name'"
                    :search-placeholder="$t('dashboard.events.dates.list.header.search.placeholder')"
                    :title="$t('dashboard.document_title.events.dates.list')"
                >
                    <template #filters>
                        <OtFilters
                            :categories-to-hide="filterCategoriesToHide"
                            :labels="$t('models.eventdate')"
                            :pagination="dates"
                            :translations="$t('dashboard.common.filters')"
                            :view-filter="{ label: context.event?.name }"
                        />
                    </template>

                    <!-- <template #actions-top>
                        <button
                            class="ot-button"
                            :title="$t('dashboard.events.dates.list.new.title')"
                            @click="formModal.open()"
                        >
                            <OtIcon type="plus" class="ot-button-icon--left" />
                            {{ $t('dashboard.events.dates.list.new.text') }}
                        </button>
                    </template> -->

                    <template #actions-bottom>
                        <component :is="typeSwitcher" />
                    </template>
                </OtListHeader>
            </template>

            <!-- <template #empty-action-no-filter>
                <button
                    class="ot-button is-outline"
                    :title="$t('dashboard.events.dates.list.new.title')"
                    @click="formModal.open()"
                >
                    <OtIcon type="plus" class="ot-button-icon--left" />
                    {{ $t('dashboard.events.dates.list.new.text') }}
                </button>
            </template> -->

            <template #tile="{ record }">
                <DatesListRecord :record="record" />
            </template>

            <template #column__-data__start="{ value }">
                {{ $l.dateTimeLong(value) }}
            </template>

            <template #column__-data__end="{ value }">
                {{ $l.dateTimeLong(value) }}
            </template>

            <template #column__-data__capacity="{ record, value }">
                <FractionColumn
                    :numerator="record.$data.sold_count || 0"
                    :denominator="value"
                />
            </template>
        </OtDataGrid>

        <DateCreateModal
            v-if="formModal.isOpen"
            :modal="formModal"
            @submit="(model) => dates?.loadPage()"
        />
    </div>

    <ErrorView
        v-else-if="error"
        :label="error.message"
    />

    <OtSpinner v-else />
</template>

<script lang="ts" setup>
import { ColumnTypes } from '@openticket/vue-dashboard-components';
import type { Column } from '@openticket/vue-dashboard-components';
import { reactive, watch } from 'vue';
import DatesListRecord from '../components/DatesListRecord.vue';
import type { Context } from '../../../services/context';
import ErrorView from '../../../components/ErrorView.vue';
import { injectOrFail } from '../../../services/util';
import FractionColumn from '../../../components/columns/FractionColumn.vue';
import { useGenericErrorHandling, useRouterPagination } from '../../../composables';
import { useFormModal } from '../../../composables/forms';
import DateCreateModal from '../components/DateCreateModal.vue';

const { error } = useGenericErrorHandling();
const formModal = reactive(useFormModal());

const context = injectOrFail<Context>('context');

if (!context.isEventContext()) {
    throw new Error('Invalid context!');
}

const { list: dates, setPagination } = useRouterPagination(context.event.model.eventDates, { defaultSort: [ [ 'start', 'asc' ] ] });

// TODO: Translations
const columns: Column[] = [
    {
        key: [ '$data', 'start' ],
        type: ColumnTypes.date,
        label: 'Start time',
        simpleTileColumnSize: 'default',
    },
    {
        key: [ '$data', 'end' ],
        type: ColumnTypes.date,
        label: 'End time',
        simpleTileColumnSize: 'default',
    },
    {
        key: [ '$data', 'capacity' ],
        type: ColumnTypes.custom,
        label: 'Capacity',
        simpleTileColumnSize: 'default',
    },
];

const filterCategoriesToHide = [
    'created_at',
    'facebook_event_id',
    'name',
    'updated_at',
];

// Reload pagination when event context changes.
watch(() => context.event?.id, (value) => {
    if (value !== null && value !== undefined) {
        void setPagination(context.event.model.eventDates);
    }
});
</script>

<style lang="scss" scoped>
.module__events__dates__list {
    padding-top: var(--ot-spacing-default);

    &__filters {
        display: flex;
        flex-direction: row;
        gap: var(--ot-spacing-default);
    }
}
</style>
