<template>
    <div class="input-field">
        <label :for="forLabel" class="input-field__label ot-input-label" v-if="label">
            {{ label }}
            <span class="ot-input-label--optional" v-if="optional">Optional</span>
            <span class="ot-input-label--required" v-else-if="required">*</span>
        </label>

        <label
            :for="forLabel"
            v-if="description"
            class="input-field__sublabel ot-input-sublabel"
        >
            {{ description }}
        </label>

        <div class="input-field__content">
            <slot></slot>
        </div>

        <div class="input-field__error-list">
            <div class="input-field__error-list__error ot-input-error" v-for="(err, index) in errorList" :key="index">
                <OtIcon type="alert" />
                {{ $t(err.message, err.data) }}
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { TranslateResult } from '@openticket/vue-localization';
import { computed } from 'vue';
import type { FormErrors } from '../../composables/forms/types';

type Props = {
    for?: string,
    label?: string | TranslateResult,
    description?: string | TranslateResult,
    optional?: boolean,
    required?: boolean,
    error?: string | Array<string | FormErrors[number]>,
    customErrorAttributes?: {
        [key: string]: string | TranslateResult,
    }
}

const props = defineProps<Props>();

const forLabel = computed<string | undefined>(() => props.for);

const errorList = computed<FormErrors>(() => {
    if (!props.error) {
        return [];
    }

    if (typeof props.error === 'string') {
        return [ {
            message: props.error,
            data: {},
        } ];
    }

    const list: FormErrors = [];

    props.error.forEach((err) => {
        if (typeof err === 'string') {
            list.push({
                message: err,
                data: {},
            });
        } else {
            list.push({
                message: err.message,
                data: {
                    ...err.data,
                    attribute: err.customAttribute as string || props.label as string || err.data.attribute,
                    ...props.customErrorAttributes,
                },
            });
        }
    });

    return list;
});

defineExpose({
    forLabel,
});
</script>

<style lang="scss" scoped>
.input-field {
    &__label {
        height: 1.5rem;
    }

    &__sublabel {
        min-height: 1rem;
    }

    &__content {
        display: flex;
        align-items: center;

        & > * {
            flex: 1;
            margin-right: var(--ot-spacing-unit);

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__error-list {
        margin-top: var(--ot-spacing-2xs);
        display: flex;
        flex-direction: column;
        gap: var(--ot-spacing-3xs);

        &__error {
            display: flex;
            align-items: center;
            gap: var(--ot-spacing-xs);

            svg {
                width: 1.5em;
                height: 1.5em;
            }
        }
    }
}
</style>
