<template>
    <FormModal
        :form="dateForm"
        :modal="modal"
        :title="$t('dashboard.dates.new.title')"
        :subtitle="$t('dashboard.dates.new.intro')"
        :submit-button-labels="{
            text: $t('dashboard.events.dates.list.new.create'),
            title: $t('dashboard.events.dates.list.new.title')
        }"
    >
        <DateCreateForm :form="dateForm" />
    </FormModal>
</template>

<script setup lang="ts">
import { reactive, type UnwrapNestedRefs } from 'vue';
import type {
    Event, EventDate, ManagementClient,
} from '@openticket/lib-management';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import FormModal from '../../../components/form/FormModal.vue';
import { useCreateForm, type FormModalComposableResult } from '../../../composables/forms';
import DateCreateForm from './forms/DateCreateForm.vue';

type Props = {
    modal: UnwrapNestedRefs<FormModalComposableResult>,
}

type Emits = {
    (e: 'submit', model: EventDate<ManagementClient>): void
}

defineProps<Props>();
const emit = defineEmits<Emits>();

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const dateForm = reactive(
    useCreateForm<EventDate<ManagementClient>, Event<ManagementClient>>(
        context.event.model.eventDates.new(),
        context.event.model.eventDates,
        {
            onSubmit(model) {
                emit('submit', model);
            },
        },
    ),
);
</script>

<style lang="scss" scoped>
// TODO: Temporary fix to ensure the datetime-picker is on screen
::v-deep .daterangepicker.dropdown-menu {
    top: -12rem;
}
</style>
