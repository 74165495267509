<template>
    <div>
        <CompanySelector
            ref="eventCompanySelector"
            :create-model-label="$t('dashboard.events.model_name').toString().toLocaleLowerCase()"
            @close="handleCompanySelectorClose"
        />

        <OtWizard
            ref="wizard"
            :title="$t('dashboard.events.wizard.title')"
            :prev-label="$t(wizardPrevLabel)"
            :prev-icon="$t(wizardPrevIcon)"
            :next-label="$t(wizardNextLabel)"
            :next-icon="$t(wizardNextIcon)"
            :finish-label="$t('dashboard.events.wizard.steps.finish_label')"
            :close-confirm-label="$t('dashboard.common.confirm.permanent.title')"
            :close-confirm-description="$t('dashboard.common.confirm.permanent.description')"
            :footer-logo="whitelabelLogo"
            :next-button-disabled="!!nextButtonDisabled"
            :next-button-loading="!!loading"
            :prev-button-disabled="!!loading"
            :footer-copyright="$t('common.copyright', { year: (new Date()).getFullYear() })"
            @close:wizard="redirectAfterClose"
            @finish:wizard="onWizardFinish"
        >
            <template #default>
                <OtWizardStep
                    :title="$t('dashboard.events.wizard.steps.details.title')"
                    @finish:step="onEventStepFinish"
                >
                    <EventWizardDetails ref="eventWizardDetails" />
                </OtWizardStep>

                <OtWizardStep
                    :title="$t('dashboard.events.wizard.steps.tickets.title')"
                    @finish:step="onTicketStepFinish"
                    @previous="onTicketStepPrevious"
                >
                    <EventWizardTickets
                        ref="eventWizardTickets"
                    />
                    <template #footer-right>
                        <button
                            v-if="!ticketToEditGuid"
                            class="ot-button is-small"
                            :title="$t(ticketStepFooterRightButtonText.title)"
                            data-testid="event-wizard-add-ticket"
                            :disabled="!!loading"
                            @click="handleTicketStepExtraButton"
                        >
                            <OtIcon
                                type="plus"
                                class="ot-button-icon--left"
                            />
                            {{ $t(ticketStepFooterRightButtonText.text) }}
                        </button>
                    </template>
                </OtWizardStep>

                <OtWizardStep
                    :title="$t('dashboard.events.wizard.steps.shop.title')"
                    @finish:step="onShopStepFinish"
                    @previous="onShopStepPrevious"
                >
                    <EventWizardShop ref="eventWizardShop" />
                </OtWizardStep>
            </template>
        </OtWizard>
    </div>
</template>

<script lang="ts" setup>
import {
    computed,
    inject,
    onMounted,
    provide,
    reactive,
    ref,
} from 'vue';
import type { UnwrapNestedRefs } from 'vue';
import { OtWizard } from '@openticket/vue-dashboard-components';
import type VueNotifications from '@openticket/vue-notifications';
import type { VueLocalization } from '@openticket/vue-localization';
import { useRouter } from 'vue-router/composables';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { ThemeModes } from 'src/plugins/types';
import type {
    Event,
    EventDate, ManagementClient, Location,
    Ticket,
} from '@openticket/lib-management';
import type { Pagination } from '@openticket/lib-crud';
import EventWizardDetails from '../components/steps/EventWizardDetails.vue';
import EventWizardTickets from '../components/steps/EventWizardTickets.vue';
import EventWizardShop from '../components/steps/EventWizardShop.vue';
import { injectOrFail } from '../../../services/util';
import type { WizardProvide } from '../types';
import CompanySelector from '../../../components/CompanySelector.vue';
import type { Context } from '../../../services/context';
import { useCreateForm } from '../../../composables/forms';
import { useGenericErrorHandling } from '../../../composables';
import type { RudderStack } from '../../../services/rudderstack';

const router = useRouter();
const { handleError } = useGenericErrorHandling();

const context = injectOrFail<Context>('context');
const localization = injectOrFail<VueLocalization>('localization');
const notifications = injectOrFail<VueNotifications>('notifications');
const theme = inject<UnwrapNestedRefs<{ mode: ThemeModes }>>('theme');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const management = injectOrFail<ManagementClient>('management');
const rudderstack = injectOrFail<RudderStack>('rudderstack');

const eventCompanySelector = ref<InstanceType<typeof CompanySelector> | null>(null);
const wizard = ref<InstanceType<typeof OtWizard> | null>(null);
const eventWizardDetails = ref<InstanceType<typeof EventWizardDetails> | null>(null);
const eventWizardTickets = ref<InstanceType<typeof EventWizardTickets> | null>(null);
const eventWizardShop = ref<InstanceType<typeof EventWizardShop> | null>(null);

const eventTickets = ref<Pagination<Ticket<Event<ManagementClient>>> | undefined>();
const showUpsertTicketView = ref<boolean>(false);
const ticketToEditGuid = ref<string | null>(null);
const redirectPath = ref<{ to: 'events' | 'shops', guid: string } | undefined>();
const loading = ref(0);

const location = ref<Location<ManagementClient>>(management.locations.new());

// Creating new instances of the parent, otherwise typing is an issue. Upon submission the data of the parent is set.
const eventForm = reactive(
    useCreateForm<Event<Location<ManagementClient>>, Location<ManagementClient>>(
        management.locations.new().events.new(),
        management.locations.new().events,
        {
            toUpdateMixinAfterCreation: true,
            notifications: {
                show: false,
            },
            disableLocalChangesDialog: true,
        },
    ),
);

// Using a second form for the validation rules and errors of the event date.
const eventDateForm = reactive(
    useCreateForm<EventDate<Event<ManagementClient>>, Event<ManagementClient>>(
        management.events.new().eventDates.new(),
        management.events.new().eventDates,
        {
            notifications: {
                show: false,
            },
            toUpdateMixinAfterCreation: true,
            disableLocalChangesDialog: true,
        },
    ),
);

provide<WizardProvide>('wizardProvide', {
    showUpsertTicketView,
    ticketToEditGuid,
    location,
    eventForm,
    eventDateForm,
    eventTickets: {
        pagination: eventTickets,
        update: updateEventTickets,
    },
});

const whitelabelLogo = computed(() => (theme?.mode === 'dark' ? whitelabel.resources.logoDark : whitelabel.resources.logo));
const nextButtonDisabled = computed(() => {
    if (eventWizardShop.value) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return eventWizardShop.value.isNextButtonDisabled;
    }

    return false;
});

const ticketStepFooterRightButtonText = computed(() => {
    if (showUpsertTicketView.value) {
        return {
            text: 'dashboard.events.wizard.steps.tickets.save_add_another.text',
            title: 'dashboard.events.wizard.steps.tickets.save_add_another.title',
        };
    }

    if (eventTickets.value && eventTickets.value.total === 0) {
        return {
            text: 'dashboard.events.wizard.steps.tickets.new.text',
            title: 'dashboard.events.wizard.steps.tickets.new.title',
        };
    }

    return {
        text: 'dashboard.events.wizard.steps.tickets.addanother',
        title: 'dashboard.events.wizard.steps.tickets.addanother',
    };
});

const wizardPrevLabel = computed(() => {
    if (showUpsertTicketView.value) {
        return 'dashboard.common.action.cancel.text';
    }

    return 'dashboard.common.action.back.text';
});

const wizardPrevIcon = computed(() => {
    if (showUpsertTicketView.value) {
        return 'close';
    }

    return 'arrow-left';
});

const wizardNextLabel = computed(() => {
    if (wizard.value && wizard.value.currentStepIndex === 0) {
        return 'dashboard.events.wizard.steps.details.next_label';
    }

    if (wizard.value && wizard.value.currentStepIndex === 1) {
        if (showUpsertTicketView.value) {
            return 'dashboard.events.wizard.steps.tickets.save.text';
        }

        return 'dashboard.events.wizard.steps.tickets.next_label';
    }

    return 'dashboard.common.action.continue.text';
});

const wizardNextIcon = computed(() => {
    if (showUpsertTicketView.value) {
        return 'check';
    }

    return 'arrow-right';
});

onMounted(() => {
    if (!context.isCompanyContext()) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        eventCompanySelector.value?.open();
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    wizard.value?.open();
});

function handleCompanySelectorClose() {
    if (!context.isCompanyContext()) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        wizard.value?.close();
    }
}

async function handleTicketStepExtraButton() {
    if (showUpsertTicketView.value) {
        try {
            loading.value++;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
            const result = await eventWizardTickets.value?.submitTicket();

            if (!result) {
                return;
            }

            ticketToEditGuid.value = null;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            eventWizardTickets.value?.resetForm();
        } catch (error) {
            handleError(error);
        } finally {
            loading.value--;
        }
    } else {
        ticketToEditGuid.value = null;
        showUpsertTicketView.value = true;
    }
}

async function onEventStepFinish() {
    try {
        loading.value++;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        const result = await eventWizardDetails.value?.submit();

        if (!result) {
            return;
        }

        showUpsertTicketView.value = false;

        // Fetch ticket list
        if (eventForm.model.id) {
            const event = management.events.new();
            event.$raw = eventForm.model.$raw;

            const list = event.tickets.list();
            await list.initialization;

            updateEventTickets(list);
        }

        rudderstack.track('vue-dashboard wizard step details continue');

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        wizard.value?.next();
    } catch (error) {
        handleError(error);
    } finally {
        loading.value--;
    }
}

function onTicketStepPrevious() {
    if (showUpsertTicketView.value) {
        ticketToEditGuid.value = null;
        showUpsertTicketView.value = false;
        return;
    }

    rudderstack.track('vue-dashboard wizard step tickets back');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    wizard.value?.previous();
}

async function onTicketStepFinish() {
    if (!showUpsertTicketView.value) {
        rudderstack.track('vue-dashboard wizard step tickets continue');

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        wizard.value?.next();
        return;
    }

    try {
        loading.value++;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        const result = await eventWizardTickets.value?.submitTicket();

        if (!result) {
            return;
        }

        showUpsertTicketView.value = false;
        ticketToEditGuid.value = null;
    } catch (error) {
        handleError(error);
    } finally {
        loading.value--;
    }
}

function onShopStepPrevious() {
    rudderstack.track('vue-dashboard wizard step shop back');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    wizard.value?.previous();
}

async function onShopStepFinish() {
    try {
        loading.value++;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        const result = await eventWizardShop.value?.linkTicketsToShop();

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!result || (!result.result || !result.guid || !result.redirectTo)) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        redirectPath.value = { to: result.redirectTo, guid: result.guid };

        rudderstack.track('vue-dashboard wizard step shop continue');

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        wizard.value?.next();
    } catch (error) {
        handleError(error);
    } finally {
        loading.value--;
    }
}

async function onWizardFinish() {
    notifications.success(localization.getI18n().t('dashboard.events.wizard.steps.tickets.notifications.event_created'));

    await redirectAfterClose();
}

// Using router.replace to ensure that the user does not navigate back and has to start the wizard process over again.
// Keep the query, to skip triggering the filter update.
function redirectAfterClose() {
    if (!redirectPath.value) {
        return router.replace({
            name: 'events.list',
            query: router.currentRoute.query,
        });
    }

    if (redirectPath.value.to === 'shops') {
        return router.replace({
            name: 'shops.home',
            params: { shop: redirectPath.value.guid },
            query: router.currentRoute.query,
        });
    }

    if (redirectPath.value.to === 'events') {
        return router.replace({
            name: 'events.home',
            params: { event: redirectPath.value.guid },
            query: router.currentRoute.query,
        });
    }

    return router.replace({
        name: 'events.list',
        query: router.currentRoute.query,
    });
}

function updateEventTickets(data: Pagination<Ticket<Event<ManagementClient>>>) {
    eventTickets.value = data;
}
</script>
