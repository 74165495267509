<template>
    <OtSpinner v-if="isLoading" />

    <OTTFAConfirmation
        v-else-if="tfaTypes"
        :error="errors"
        :tfa-types="tfaTypes"
        :require-password="false"
        hide-logo-and-logout
        @submit="tryToSubmit"
        @back="tfaConfirmationBack"
    />

    <div
        v-else-if="notLoggedOutError"
        class="ot-card forgot-password"
    >
        <ModalHeader hide-padding>
            <template #logout>
                <button
                    class="is-text is-small"
                    type="button"
                    :title="$t('dashboard.invitation.invalid.logout.title')"
                    @click="logoutAndReload"
                >
                    <i class="ot-button-icon--left oti oti-logout" />
                    {{ $t('dashboard.invitation.invalid.logout.text') }}
                </button>
            </template>
        </ModalHeader>

        <div class="forgot-password__header">
            <h1>{{ $t('dashboard.reset_password.title') }}</h1>
        </div>

        <div class="forgot-password__content">
            <OtAside
                :title="$t('dashboard.reset_password.invalid')"
                :description="$t(notLoggedOutError)"
                type="is-warning"
                icon="warning"
            />
        </div>

        <div class="forgot-password__footer">
            <button
                class="ot-button"
                type="button"
                :title="$t('dashboard.invitation.invalid.dashboard.title')"
                @click="$router.push({name: 'home'})"
            >
                {{ $t('dashboard.invitation.invalid.dashboard.text') }}
                <i class="ot-button-icon--right oti oti-arrow-right" />
            </button>
        </div>
    </div>

    <div
        v-else-if="tokenError"
        class="ot-card forgot-password"
    >
        <ModalHeader hide-padding />

        <div class="forgot-password__header">
            <h1>{{ $t('dashboard.reset_password.title') }}</h1>
        </div>

        <OtAside
            :title="$t(tokenError)"
            type="is-warning"
            icon="warning"
        />
    </div>

    <FixResetPassword
        v-else
        :error="errors"
        class="ot-card forgot-password"
        @submit="resetPasswordSubmit"
    />
</template>

<script setup lang="ts">
import type { AuthClient } from '@openticket/lib-auth';
import type { TFAError, TFATypes } from '@openticket/vue-tfa-confirmation';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { isAxiosError } from 'axios';
import urlJoin from 'url-join';
import { reactive, ref } from 'vue';
import type { VueLocalization } from '@openticket/vue-localization';
import { useRoute, useRouter } from 'vue-router/composables';
import ModalHeader from '../../../components/modal/ModalHeader.vue';
import FixResetPassword from '../components/FixResetPassword.vue';
import { isErrorDescriptionAxiosError } from '../../../services/http/axios';
import { injectOrFail } from '../../../services/util';
import type { RudderStack } from '../../../services/rudderstack';

interface ForgotPasswordData {
    new_password: string;
    new_password_confirmation: string;
    tfa_input: string | null;
    tfa_type: string | null;
}

const auth = injectOrFail<AuthClient>('auth');
const localization = injectOrFail<VueLocalization>('localization');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const rudderstack = injectOrFail<RudderStack>('rudderstack');

const tokenError = ref<string | null>(null);
const notLoggedOutError = ref<string | null>(null);
const tfaTypes = ref<TFATypes | null>(null);
const isLoading = ref<boolean>(true);

const errors = reactive<TFAError>({
    incorrectPassword: null,
    incorrectTFAInput: null,
});

const formData = reactive<ForgotPasswordData>({
    new_password: '',
    new_password_confirmation: '',
    tfa_input: null,
    tfa_type: null,
});

const route = useRoute();
const router = useRouter();

void (async () => {
    rudderstack.track('vue-dashboard fix forgot password init');
    await checkTokenAndUser();
})();

function tfaConfirmationBack(): void {
    tfaTypes.value = null;
}

async function tryToSubmit(data: {type: string, input: string}): Promise<void> {
    if (formData) {
        formData.tfa_type = data.type;
        formData.tfa_input = data.input;

        await submit();
    }
}

async function resetPasswordSubmit(new_password: string, new_password_confirmation: string): Promise<void> {
    if (formData) {
        formData.new_password = new_password;
        formData.new_password_confirmation = new_password_confirmation;

        await submit();
    }
}

async function submit(): Promise<void> {
    const { token } = route.params;

    if (!token) {
        void router.push({
            name: 'error',
            query: {
                reason: 'Password reset token is missing',
            },
        });

        return;
    }

    // TODO - @openticket/lib-auth
    try {
        await auth.$http.post(
            urlJoin(auth.$path, 'passwords', 'reset', 'token'),
            { ...formData, token },
            { headers: { Accept: 'application/json' } },
        );

        rudderstack.track('vue-dashboard fix forgot password submit success');

        if (whitelabel.dashboard.login_url) {
            window.location.href = whitelabel.dashboard.login_url;
        } else {
            void router.push({ name: 'auth.login' });
        }
    } catch (e: unknown) {
        if (!isAxiosError(e)) {
            return;
        }

        if (e.response) {
            if (!isErrorDescriptionAxiosError(e)) {
                return;
            }

            const errorDescription: string | { [p: string]: string | string[] } = e.response.data.error_description;

            if (typeof errorDescription === 'string') {
                if (e.response.status === 404) {
                    rudderstack.track('vue-dashboard fix forgot password token error');
                    tokenError.value = errorDescription;
                } else if (e.response.status === 400) {
                    rudderstack.track('vue-dashboard fix forgot password not logged out error');
                    notLoggedOutError.value = errorDescription;
                }
            } else if (e.response.status === 406) {
                if (errorDescription.new_password && errorDescription.new_password.length > 0) {
                    [ errors.incorrectPassword ] = errorDescription.new_password;
                } else if (errorDescription.tfa_type) {
                    errors.incorrectPassword = null;
                    errors.incorrectTFAInput = null;

                    for (const err of errorDescription.tfa_type) {
                        let split = err.split(':');

                        if (split[0] === 'validation.in') {
                            split = split[1].split(',');

                            tfaTypes.value = {};
                            for (const tfa_method of split) {
                                tfaTypes.value[tfa_method] = { name: tfa_method, settings: 1 };
                            }
                        }
                    }
                } else if (errorDescription.tfa_input) {
                    [ errors.incorrectTFAInput ] = errorDescription.tfa_input;
                }
            }
        } else {
            // TODO @PETER: REVIEW -> Without a response, the password is wrong?
            errors.incorrectPassword = $t('dashboard.user.change_password.errors.password') as string;
            errors.incorrectTFAInput = null;
        }

        // TODO @PETER: REVIEW -> Ignore fallthrough all error conditions above????
        //  lib-auth -> should at least re-trow
    }
}

async function logoutAndReload(): Promise<void> {
    try {
        // TODO - @openticket/lib-auth
        await Promise.all([
            auth.$token.logout(), // TODO @Peter - lib-auth add a 'clear tokens' (e.g. logout without hooks) method.
            auth.$http.get(urlJoin(auth.$path, 'sessions', 'logout')),
        ]);
    } catch (e: unknown) {
        if (isAxiosError(e)
            && e.response
            && (
                e.response.status === Number(import.meta.env.VITE_LOGOUT_REDIRECT_STATUS)
                || e.response.status === 355
            )) {
            void checkTokenAndUser();
        } else {
            await router.push({ name: 'error' });
        }
    }
}

async function checkTokenAndUser(): Promise<void> {
    notLoggedOutError.value = null;
    tokenError.value = null;

    const { token } = route.params;

    if (!token) {
        void router.push({
            name: 'error',
            query: {
                reason: 'Password reset token is missing',
            },
        });

        return;
    }

    try {
        // TODO - @openticket/lib-auth
        await auth.$http.get(
            urlJoin(auth.$path, 'passwords', 'reset', 'token', token),
            { headers: { Accept: 'application/json' } },
        );
    } catch (e: unknown) {
        if (!isAxiosError(e) || !isErrorDescriptionAxiosError(e)) {
            void router.push({ name: 'error' });

            return;
        }

        if (e.response.status === 400 && typeof e.response.data.error_description === 'string') {
            rudderstack.track('vue-dashboard fix forgot password not logged out error');
            notLoggedOutError.value = e.response.data.error_description;
            return;
        }

        if (e.response.status === 404 && typeof e.response.data.error_description === 'string') {
            rudderstack.track('vue-dashboard fix forgot password token error');
            tokenError.value = e.response.data.error_description;
            return;
        }

        // Catch all scenario for any other error case
        void router.push({ name: 'error' });
    } finally {
        formData.new_password = '';
        formData.new_password_confirmation = '';
        formData.tfa_input = null;
        formData.tfa_type = null;

        isLoading.value = false;
    }
}

// TODO: Remove when moving to Vue 3
const $t = (value: string) => localization.getI18n().t(value);
</script>

<style lang="scss" scoped>
.forgot-password {
    &__header{
        margin-bottom: var(--ot-spacing-lg);

        .warning {
            color: var(--ot-color-accent-orange-dark);
        }

        &__top {
            display: flex;
            margin-bottom: var(--ot-spacing-lg);

            &__logo {
                max-width: 40%;
                text-align: left;
            }

            &__logout {
                color: var(--ot-color-core-brand);
                margin: auto 0 auto auto;
            }
        }

        &__title {
            text-align: center;
        }

    }

    &__content {
        margin-bottom: var(--ot-spacing-3xl);
    }

    &__footer .ot-button {
        width: 100%;
    }
}
</style>
