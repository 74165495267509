<template>
    <div class="module__profile__tfa-management">
        <div v-if="tfaTypes">
            <OtAside
                v-if="tfaTypes['code'] && tfaTypes['code'].settings <= 3"
                :title="$t('dashboard.user.tfa.few_recovery_codes.title', {number: tfaTypes['code'].settings})"
                :description="$t('dashboard.user.tfa.few_recovery_codes.description')"
                type="is-warning"
                icon="warning">
            </OtAside>
            <OtForm>
                <OtFormCollapse
                    :title="$t('dashboard.user.tfa.title')"
                    :subtitle="$t('dashboard.user.tfa.subtitle')"
                    headerIcon="call"
                    class="module__profile__tfa-management__tfa_types"
                    :buttonOpenLabel="$t('dashboard.common.action.open.text')"
                    :buttonCloseLabel="$t('dashboard.common.action.close.text')"
                >
                    <!-- DD-DASHBOARD-2719 - Only a user can disable a single tfa methods-->
                    <div v-if="tfaTypes['totp']" class="module__profile__tfa-management__tfa_type">
                        <div class="module__profile__tfa-management__tfa_type__info">
                            <div>
                                <h5>{{ $t('dashboard.user.tfa.types.totp.title') }}</h5>
                                <OtFlair
                                    v-if="tfaTypes['totp'].settings >= 1"
                                    icon="check"
                                    :inversed="false"
                                    :centered="false"
                                    type="is-success"
                                >
                                    {{ $t('dashboard.user.tfa.active') }}
                                </OtFlair>
                            </div>
                            <span class="ot-text-small">
                                {{ $t('dashboard.user.tfa.types.totp.description') }}
                            </span>
                        </div>
                        <div class="module__profile__tfa-management__tfa_type__action">
                            <div v-if="tfaTypes['totp'].settings === 0"
                                 class="ot-button"
                                 @click="openTfaSetup(
                                     'totp',
                                     'dashboard.user.tfa.setup.totp.success',
                                 )"
                                 :title="$t('dashboard.user.tfa.action.set_up.title')"
                                 role="button"
                                 tabindex="0"
                                 @keydown.space="openTfaSetup(
                                     'totp',
                                     'dashboard.user.tfa.setup.totp.success',
                                     )"
                                 @keydown.enter="openTfaSetup(
                                     'totp',
                                     'dashboard.user.tfa.setup.totp.success',
                                )"
                            >
                                {{ $t('dashboard.user.tfa.action.set_up.text') }}
                            </div>
                            <button
                                v-else
                                class="ot-button is-danger"
                                type="button"
                                @click="disableTfaType('totp')"
                                :title="$t('dashboard.user.tfa.action.disable.title')"
                                tabindex="0"
                                @keydown.space="disableTfaType('totp')"
                                @keydown.enter="disableTfaType('totp')"
                            >
                                {{ $t('dashboard.user.tfa.action.disable.text') }}
                            </button>
                        </div>
                    </div>
                    <div v-if="tfaTypes['code']" class="ot-separator" />
                    <div v-if="tfaTypes['code']" class="module__profile__tfa-management__tfa_type">
                        <div class="module__profile__tfa-management__tfa_type__info">
                            <div>
                                <h5>{{ $t('dashboard.user.tfa.types.code.title') }}</h5>
                                <OtFlair
                                    icon="check"
                                    :inversed="false"
                                    :centered="false"
                                    type="is-success"
                                >
                                    {{ $t('dashboard.user.tfa.active') }}
                                </OtFlair>
                            </div>
                            <span class="ot-text-small">
                                {{ $t('dashboard.user.tfa.types.code.description') }}
                            </span>
                        </div>
                        <div class="module__profile__tfa-management__tfa_type__action">
                            <!-- recovery codes can be renewed even if no codes are currently available -->
                            <div
                                 class="ot-button is-dark"
                                 @click="openTfaSetup('code', 'dashboard.user.tfa.setup.code.success')"
                                 :title="$t('dashboard.user.tfa.action.renew_recovery_codes.title')"
                                 role="button"
                                 tabindex="0"
                                 @keydown.space="openTfaSetup('code', 'dashboard.user.tfa.setup.code.success')"
                                 @keydown.enter="openTfaSetup('code', 'dashboard.user.tfa.setup.code.success')"
                            >
                                {{ $t('dashboard.user.tfa.action.renew_recovery_codes.text') }}
                            </div>
                        </div>
                    </div>

                </OtFormCollapse>
            </OtForm>
        </div>

        <OtInlineModal ref="modalTfaSetup" fit-width class="tfa-inline-modal">
            <div class="module__profile__tfa-management__modal__tfa-setup">
                <TfaSetup
                    v-if="tfaTypes"
                    :type="tfaTypeToSetup"
                    :tfaTypes="tfaTypes"
                    :successMessage="successMessageTfaSetup"
                    @close="tfaSetupClosed"
                    hide-logo
                    hide-logout
                />
            </div>
        </OtInlineModal>

        <OtInlineModal ref="modalTfaConfirmation" class="tfa-inline-modal">
            <OTTFAConfirmation
                class="module__profile__tfa-management__modal__tfa-confirmation"
                v-if="tfaTypes && tfaTypeToDelete"
                :tfaTypes="tfaTypes"
                :error="error"
                @submit="tryToSubmitDisable"
                @back="tfaDisableClose"
                :submitting="submitDisable"
                :auto-submit="false"
                hide-logo
                hide-logout
            />
        </OtInlineModal>
    </div>
</template>

<script lang="ts" setup>
import type { AuthClient } from '@openticket/lib-auth';
import { OtInlineModal } from '@openticket/vue-dashboard-components';
import type { DialogController } from '@openticket/vue-dashboard-components';
import urlJoin from 'url-join';
import { reactive, ref } from 'vue';
import type { AxiosError } from 'axios';
import type { TFAError, TFATypes } from '@openticket/vue-tfa-confirmation';
import type { VueLocalization } from '@openticket/vue-localization';
import type VueNotifications from '@openticket/vue-notifications';
import TfaSetup from '../../../components/tfa/TfaSetup.vue';
import { injectOrFail } from '../../../services/util';

const auth = injectOrFail<AuthClient>('auth');
const dialog = injectOrFail<DialogController>('dialog');
const localization = injectOrFail<VueLocalization>('localization');
const notifications = injectOrFail<VueNotifications>('notifications');

const modalTfaConfirmation = ref<InstanceType<typeof OtInlineModal>>();
const modalTfaSetup = ref<InstanceType<typeof OtInlineModal>>();
const submitDisable = ref<boolean>(false);
const successMessageTfaSetup = ref<string>('dashboard.common.state.created');
const tfaEnforced = ref<boolean>(true);
const tfaTypes = ref<TFATypes | null>(null);
const tfaTypeToDelete = ref<string | null>(null);
const tfaTypeToSetup = ref<string | null>(null);

const error = reactive<TFAError>({
    incorrectPassword: null,
    incorrectTFAInput: null,
});

void (async () => {
    // TODO @Peter fix form component usage, should not be necessary here just for UI (but right now it sort-of is...)

    // TODO @Peter Add try catch/errors here

    // TODO - @openticket/lib-auth
    const tfaTypesPromise = auth.$http.get<TFATypes>(urlJoin(auth.$path, 'twofactor'));
    const forcedListPromise = auth.$http.get<unknown[]>(urlJoin(auth.$path, 'twofactor', 'forced'));

    tfaTypes.value = (await tfaTypesPromise).data;
    tfaEnforced.value = (await forcedListPromise).status !== 204;
})();

async function tfaSetupClosed(): Promise<void> {
    if (!auth) {
        return;
    }

    // TODO - @openticket/lib-auth
    tfaTypes.value = (await auth.$http.get<TFATypes>(urlJoin(auth.$path, 'twofactor'))).data;
    submitDisable.value = false;
    successMessageTfaSetup.value = 'dashboard.common.state.created';
    modalTfaSetup.value?.close();
}

async function disableTfaType(type: string): Promise<void> {
    const confirm = await dialog.confirm({
        title: $t(tfaEnforced.value
            ? 'dashboard.user.tfa.action.forced_disable.title'
            : 'dashboard.common.confirm.permanent.title') as string,
        description: $t(tfaEnforced.value
            ? 'dashboard.user.tfa.action.forced_disable.description'
            : 'dashboard.common.confirm.permanent.description') as string,
        type: 'is-danger',
    });

    if (confirm) {
        tfaTypeToDelete.value = type;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        modalTfaConfirmation.value?.open();
    }
}

async function tryToSubmitDisable(tfaConfirmed: { input: string, type: string, password: string}): Promise<void> {
    if (auth && tfaConfirmed && tfaTypeToDelete) {
        submitDisable.value = true;

        // TODO - @openticket/lib-auth
        await auth.$http.delete(
            urlJoin(auth.$path, 'twofactor', tfaTypeToDelete.value as string),
            {
                data: {
                    tfa_type: tfaConfirmed.type,
                    tfa_input: tfaConfirmed.input,
                    password: tfaConfirmed.password,
                },
            },
        ).then(() => {
            notifications.success($t('dashboard.user.tfa.deletion.success'));
            submitDisable.value = false;

            void tfaDisableClose();
        }).catch((catchError: AxiosError<{error_description: {password: Array<string>, tfa_input: Array<string>} }>) => {
            if (catchError.response && catchError.response.status === 406) {
                if (catchError.response.data.error_description.password
                        && catchError.response.data.error_description.password.length > 0) {
                    [ error.incorrectPassword ] = catchError.response.data.error_description.password;
                    error.incorrectTFAInput = null;
                } else if (catchError.response?.data.error_description.tfa_input
                        && catchError.response.data.error_description.tfa_input.length > 0) {
                    error.incorrectPassword = null;
                    [ error.incorrectTFAInput ] = catchError.response.data.error_description.tfa_input;
                }
            } else {
                notifications.warning($t('dashboard.user.tfa.deletion.warning'));
            }
            submitDisable.value = false;
        });

        // TODO @Peter Proper errors and logging
    }
}

async function tfaDisableClose(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await modalTfaConfirmation.value?.close();
    tfaTypeToDelete.value = null;
    submitDisable.value = false;

    if (!auth) {
        return;
    }

    // TODO - @openticket/lib-auth
    tfaTypes.value = (await auth.$http.get<TFATypes>(urlJoin(auth.$path, 'twofactor'))).data;
}

function openTfaSetup(type: string, successMessage: string): void {
    tfaTypeToSetup.value = type;
    successMessageTfaSetup.value = successMessage;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    modalTfaSetup.value?.open();
}

// TODO: Remove when switching to Vue 3
const $t = (value: string) => localization.getI18n().t(value);

</script>

<style lang="scss" scoped>
@import "../../../assets/scss/mixins.scss";

.module__profile__tfa-management {
     &__tfa_types {
        .ot-separator {
            margin: var(--ot-spacing-2xl) 0;
        }
    }

    &__tfa_type {
        display: flex;
        align-items: center;

        &__info {
            flex: 1;
            margin-right: var(--ot-spacing-lg);

            h5 {
                display: inline-flex;
                margin-right: var(--ot-spacing-xs);
            }

            span {
                display: inline-block;
            }

            &__enforced {
                color: var(--ot-color-accent-orange-dark);
            }

            &__label {
                background-color: var(--ot-color-accent-green-dark);
                color: var(--ot-color-core-white);
                cursor: default;
                padding: 0 var(--ot-spacing-xs);
                border-radius: .3rem;
                height: auto;
                vertical-align: bottom;
            }
        }

        &__action {
            .ot-button {
                padding: 0 var(--ot-spacing-3xl);
            }
        }
    }

    &__modal__tfa-confirmation,
    &__modal__tfa-setup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 201;
        width: 584px;
        text-align: center;

        @include breakpoint(mob) {
            width: calc(100dvw - (var(--ot-spacing-unit) * 2));
        }

        @media screen and (max-width: 25rem) {
            top: 0;
            transform: translate(-50%, var(--ot-spacing-unit));
            padding-bottom: var(--ot-spacing-unit);
        }
    }
}

::v-deep {
    .inline-modal__card {
        @include breakpoint(mob) {
            overflow: auto;
        }
    }
}
</style>
