<template>
    <ErrorView
        v-if="error"
        :label="error.message"
    />

    <div
        v-else-if="orders"
        class="module__orders__list"
    >
        <OtDataGrid
            class="module__orders__list__grid"
            type="table"
            :pagination="orders"
            :columns="columns"
            :empty-title="$t('dashboard.orders.list.empty.title')"
            :empty-message="$t('dashboard.orders.list.empty.message')"
            :empty-message-active-filter="$t('dashboard.common.filters.not_found')"
            :loading-message="$t('dashboard.orders.list.header.loading')"
        >
            <template #list-header="{ typeSwitcher }">
                <OtListHeader
                    :pagination="orders"
                    searchable
                    :search-key="'email'"
                    :search-placeholder="$t('dashboard.orders.list.header.search.placeholder')"
                    :title="$t('dashboard.document_title.orders.list')"
                >
                    <!-- TODO: Restore when orders db has been indexed -->
                    <!-- <template #filters>
                        <OtFilters
                            :pagination="orders"
                            :view-filter="{ label: companyViewFilterSlug }"
                            :categories="[
                                {
                                    label: $t('dashboard.orders.list.filters.by_status.label'),
                                    icon: 'alert',
                                    targets: [ 'status' ]
                                },
                            ]"
                            :labels="{
                                status: {
                                    cancelled: $t('dashboard.orders.status.cancelled'),
                                    expired: $t('dashboard.orders.status.expired'),
                                    failed: $t('dashboard.orders.status.failed'),
                                    paid: $t('dashboard.orders.status.paid'),
                                    'paid decide': $t('dashboard.orders.status.paid_decide'),
                                    pending: $t('dashboard.orders.status.pending'),
                                    refunded: $t('dashboard.orders.status.refunded'),
                                    refunding: $t('dashboard.orders.status.refunding'),
                                    unknown: $t('dashboard.orders.status.unknown'),
                                    verify: $t('dashboard.orders.status.verify'),
                                }
                            }"
                            :tags-to-hide="[ 'email' ]"
                            :new-filter-label="$t('dashboard.common.filters.new')"
                            :apply-filter-label="$t('dashboard.common.filters.apply')"
                        />
                    </template> -->

                    <template #actions-top>
                        <!-- <button class="ot-button" @click="handleNewEventButton" data-testid="event-wizard-btn">
                            <OtIcon type="plus" class="ot-button-icon--left" />
                            {{ $t('dashboard.events.new.title') }}
                        </button> -->
                    </template>

                    <template #actions-bottom>
                        <component :is="typeSwitcher" />
                    </template>
                </OtListHeader>
            </template>
            <template #column__-data__created_at="{ value }">
                {{ $l.dateTime(value) }}
            </template>

            <template #column__event_name>
                Open+++Ticket Event
            </template>

            <template #column__shop_name>
                Open+++Ticket Shop
            </template>

            <template #column__-data__status="{ value }">
                <OtFlair
                    :type="orderStatusColors[value] || orderStatusColors['unknown']"
                    inversed
                    centered
                >
                    {{ orderStatusSlugs[value] ? $t(orderStatusSlugs[value]) : value }}
                </OtFlair>
            </template>

            <template #column__tickets_count>
                Open+++Ticket
            </template>

            <template #column__-data__finn_price="{ value }">
                {{ $l.currency(value, currency) }}
            </template>

            <template #actions="{ record }">
                <button
                    :title="$t('dashboard.orders.list.table.edit.title')"
                    class="ot-button is-outline is-small"
                    data-testid="order-list-view-edit-order"
                    @click="goToOrderDetails(record.id)"
                    @keydown.space="goToOrderDetails(record.id)"
                    @keydown.enter="goToOrderDetails(record.id)"
                >
                    {{ $t('dashboard.orders.list.table.edit.text') }}
                    <OtIcon
                        type="carrot-right"
                        size="small"
                        class="ot-button-icon--right"
                    />
                </button>
            </template>
        </OtDataGrid>
    </div>

    <OtSpinner v-else />
</template>

<script setup lang="ts">
import type { ManagementClient, Order } from '@openticket/lib-management';
import type { Column } from '@openticket/vue-dashboard-components';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router/composables';
import type { VueLocalization } from '@openticket/vue-localization';
import type { Pagination } from '@openticket/lib-crud';
import ErrorView from '../../../components/ErrorView.vue';
import type { Context } from '../../../services/context';
import { injectOrFail } from '../../../services/util';
import { useGenericErrorHandling, useRouterPagination } from '../../../composables';

const columns: Column[] = [
    {
        key: [ '$data', 'created_at' ],
        type: 'date',
        label: 'Date',
        // TODO: Remove when orders db has been indexed
        sortingKey: 'UNKNOWN_PROPERTY',
    },
    {
        key: [ '$data', 'fullname' ],
        type: 'string',
        label: 'Name',
        // sortingKey: 'firstName',
        // TODO: Remove when orders db has been indexed
        sortingKey: 'UNKNOWN_PROPERTY',
    },
    // {
    //     key: [ 'event_name' ], // TODO
    //     type: 'string',
    //     label: 'Event',
    // },
    // {
    //     key: [ 'shop_name' ], // TODO
    //     type: 'string',
    //     label: 'Shop',
    // },
    {
        key: [ '$data', 'status' ],
        type: 'custom',
        label: 'Status',
        // TODO: Remove when orders db has been indexed
        sortingKey: 'UNKNOWN_PROPERTY',
    },
    // {
    //     key: [ 'tickets_count' ], // TODO
    //     type: 'number',
    //     label: 'Tickets',
    // },
    // {
    //     key: [ '$data', 'finn_price' ],
    //     type: 'number',
    //     label: 'Amount',
    // },
];

const orderStatusColors = {
    cancelled: 'is-danger',
    expired: 'is-danger',
    failed: 'is-danger',
    paid: 'is-success',
    pending: 'is-neutral',
    refunded: 'is-danger',
    refunding: 'is-warning',
    swapped: 'is-info',
    unknown: 'is-warning',
    verify: 'is-warning',
};

const orderStatusSlugs = {
    cancelled: 'dashboard.orders.status.cancelled',
    expired: 'dashboard.orders.status.expired',
    failed: 'dashboard.orders.status.failed',
    paid: 'dashboard.orders.status.paid',
    pending: 'dashboard.orders.status.pending',
    refunded: 'dashboard.orders.status.refunded',
    refunding: 'dashboard.orders.status.refunding',
    swapped: 'dashboard.orders.status.swapped',
    unknown: 'dashboard.orders.status.unknown',
    verify: 'dashboard.orders.status.verify',
};

const router = useRouter();
const { error, handleError } = useGenericErrorHandling();

const context = injectOrFail<Context>('context');
const localization = injectOrFail<VueLocalization>('localization');
const management = injectOrFail<ManagementClient>('management');

// TODO: Restore when orders db has been indexed
// const { list: orders, setPagination } = useRouterPagination(management.orders, { defaultSort: [ [ 'created_at', 'desc' ] ] });
const orders = ref<Pagination<Order<ManagementClient>> | null>(null);

// TODO Should come from payment??? On global context, we now don't have a currency...
const currency = computed(() => (context.isCompanyContext() ? context.company.model.$data.currency : 'XPD')); // TODO ALTERNATIVE HARDCODED!!!!
const companyViewFilterSlug = computed<string>(() => (context.isCompanyContext()
    ? context.company.name
    : localization.getI18n().t('dashboard.sidebar.context_filter.company.all_companies') as string
));

watch(() => context.company, (newValue, oldValue) => {
    if (newValue?.id !== oldValue?.id) {
        // TODO: Restore when orders db has been indexed
        // void setPagination(management.orders);
        void setPagination();
    }
});

// TODO: Remove when orders db has been indexed
async function setPagination() {
    try {
        orders.value = management.orders.list();
        await orders.value.initialization;
    } catch (e: unknown) {
        void handleError(e);
    }
}
void setPagination();

function goToOrderDetails(order: string): void {
    try {
        const params: {order: string, company?: string} = { order };

        if (context.isCompanyContext()) {
            params.company = context.company.id;
        }

        void router.push({
            name: 'orders.details',
            params,
        });
    } catch (e) {
        void handleError(e);
    }
}
</script>

<style scoped lang="scss">
.module__orders__list {
    padding-top: var(--ot-spacing-default);
}
</style>
