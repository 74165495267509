<template>
    <div class="vertical-tabs">
        <div class="vertical-tabs__items">
            <router-link
                v-for="tab in tabProps"
                :key="tab.label"
                :to="tab.disabled !== false ? '' : tab.route"
                class="vertical-tabs__items__label ot-text-body-strong"
                :class="{
                    selected: tab.selected === true || selected === tab.route.name, disabled: tab.disabled !== false,
                    'is-admin': tab.isAdmin,
                }"
            >
                <OtIcon
                    v-if="tab.isAdmin"
                    size="small"
                    class="vertical-tabs__item__icon"
                    type="lock"
                />
                <div>{{ tab.label }}</div>
            </router-link>
        </div>

        <div class="vertical-tabs__content">
            <slot :selected="selected" />
        </div>
    </div>
</template>

<script setup lang="ts">
// Note:
// This component is intended to replace the existing OtVerticalTabs in @openticket/dashboard-components
// The main difference between the two is that in this implementation, slots are used to pass the content of the vertical tabs,
// but the routes still work.

import type { TranslateResult } from '@openticket/vue-localization';
import { computed, type UnwrapNestedRefs, useSlots } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { ROLE_ADMIN, ROLE_WHITELABEL_ADMIN } from '@openticket/lib-auth';
import { injectOrFail } from '../../services/util';
import type { Roles } from '../../plugins/types';

type VerticalTabItemProps = {
    route: { name: string, params?: Record<string, string> },
    label: string | TranslateResult,
    disabled?: boolean,
    // A boolean selected property can be used to 'overrule' the route matching.
    selected?: string | boolean,
}

const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');

const route = useRoute();
const router = useRouter();
const routes = router.getRoutes();
const slots = useSlots();

const selected = computed<string>(() => route.name || '');

const tabProps = computed<(VerticalTabItemProps & {isAdmin: boolean})[]>(() => (
    slots.default ? slots.default()
        .map((tab) => ({
            ...tab.componentOptions?.propsData as VerticalTabItemProps,
            isAdmin: isAdminRoute((tab.componentOptions?.propsData as VerticalTabItemProps | undefined)?.route.name),
        }))
        .filter((p) => !('isAdmin' in p) || p.isAdmin === false || roles.isWhitelabelAdmin || roles.isSuperAdmin)
        .map((p) => ({
            ...p,
            disabled: ('disabled' in p ? p.disabled : false),
            selected: ('selected' in p ? p.selected : false),
            isAdmin: ('isAdmin' in p && p.isAdmin !== false),
        })) : []));

function isAdminRoute(routeName?: string) {
    const currentRoute = routes.find((r) => r.name === routeName);
    return currentRoute?.meta?.restrictedBy === ROLE_ADMIN
        || currentRoute?.meta?.restrictedBy === ROLE_WHITELABEL_ADMIN;
}

</script>

<style scoped lang="scss">
.vertical-tabs {
    display: flex;

    &__items {
        display: flex;
        flex-direction: column;
        min-width: 12rem;

        &__label {
            color: var(--ot-color-core-light-accent-primary);
            padding: var(--ot-spacing-xs) var(--ot-spacing-default);
            border-left: 2px solid var(--ot-color-core-light-accent-tertiary);
            display: flex;
            align-items: center;

            &.selected {
                border-left: 2px solid var(--ot-color-core-brand);
                color: var(--ot-color-core-brand);
            }

            &.disabled {
                color: var(--ot-color-core-light-accent-secondary);
                cursor: not-allowed;
            }

            svg {
                margin-bottom: 0.188rem;
                margin-right: 0.5rem;
            }

            &.is-admin {
                color: var(--ot-color-accent-green-dark);

                &.selected {
                    border-left-color: var(--ot-color-accent-green-dark);
                }
            }
        }
    }

    &__content {
        padding: var(--ot-spacing-default) 0;
        flex-grow: 1;
    }
}

@media (max-width: 40rem) {
    .vertical-tabs {
        flex-direction: column;
        width: calc(100dvw - 2 * var(--ot-spacing-default));

        &__items {
            overflow-x: auto;
            flex-direction: row;
            justify-content: space-between;

            &__label {
                flex-grow: 1;
                white-space: nowrap;
                border-left: none;
                border-bottom: 2px solid var(--ot-color-core-light-accent-tertiary);
                margin-bottom: var(--ot-spacing-xs);
                text-align: center;

                &.selected {
                    border-bottom: 2px solid var(--ot-color-core-brand);
                    border-left: none;

                    &.is-admin {
                        border-color: var(--ot-color-accent-green-dark);
                    }
                }
            }
        }
    }
}
</style>
