<template>
    <div
        class="ticket-create-form"
        data-testid="ticket-create-form"
    >
        <OtFormRow>
            <InputField
                :description="$t('dashboard.events.wizard.steps.tickets.upsert.form.ticket_name.description')"
                :error="form.errors.name"
                :label="$t('dashboard.events.wizard.steps.tickets.upsert.form.ticket_name.label')"
                :required="form.rules.name && form.rules.name.includes('required')"
                data-testid="ticket-create-form-name"
            >
                <OtInput
                    v-model="form.model.$data.name"
                    class="input"
                    type="text"
                    @input="form.errors.name = []"
                />
            </InputField>
        </OtFormRow>

        <OtFormRow>
            <InputField
                :description="$t('dashboard.events.wizard.steps.tickets.upsert.form.capacity.description')"
                :error="form.errors.available_stock"
                :label="$t('dashboard.events.wizard.steps.tickets.upsert.form.capacity.label')"
                :required="form.rules.available_stock && form.rules.available_stock.includes('required')"
                data-testid="ticket-create-form-capacity"
            >
                <InputFloatUnlimited
                    v-model="form.model.$data.available_stock"
                    @input="form.errors.available_stock = []"
                />
            </InputField>
        </OtFormRow>

        <OtFormRow>
            <InputTicketPrice
                :currency="context.event.model.$data.currency"
                :form="form"
                data-testid="ticket-create-form-price"
            />
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import type {
    Event, ManagementClient, Ticket,
} from '@openticket/lib-management';
import type { UnwrapNestedRefs } from 'vue';
import InputField from '../../../../components/form/InputField.vue';
import InputFloatUnlimited from '../../../../components/InputFloatUnlimited.vue';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { injectOrFail } from '../../../../services/util';
import type { Context } from '../../../../services/context';
import InputTicketPrice from '../../../../components/form/InputTicketPrice.vue';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Ticket<ManagementClient>, Event<ManagementClient>>>
}

defineProps<Props>();

const context = injectOrFail<Context>('context');

if (!context.isEventContext()) {
    // TODO Proper error handling and logging
    throw Error('Invalid context');
}
</script>
