<template>
    <div class="module__access-moments__list">
        <OtDataGrid
            class="module__access-moments__list__grid"
            type="tiles"
            :pagination="accessMoments"
            :columns="columns"
            :empty-message-active-filter="$t('dashboard.common.filters.not_found')"
            :loading-message="$t('common.loading')"
            :empty-title="$t(
                'dashboard.access_moments.list.empty.title',
                {
                    name: context.event.name,
                    start: context.event.model.$data.start && $l.dateTimeLong(context.event.model.$data.start),
                    end: context.event.model.$data.end && $l.dateTimeLong(context.event.model.$data.end)
                })
            "
        >
            <template #list-header>
                <OtListHeader
                    :pagination="accessMoments"
                    searchable
                    search-key="name"
                    :search-placeholder="$t('common.placeholder.search')"
                    :title="$t('dashboard.document_title.access_moments.list')"
                >
                    <template #filters>
                        <OtFilters
                            :categories-to-hide="filterCategoriesToHide"
                            :labels="$t('models.eventdate')"
                            :pagination="accessMoments"
                            :translations="$t('dashboard.common.filters')"
                            :view-filter="{ label: context.event?.name }"
                        />
                    </template>

                    <template #actions-top>
                        <button
                            class="ot-button"
                            :title="$t('dashboard.access_moments.list.header.new.title')"
                            data-testid="accessmoment-create-btn"
                            @click="formModal.open()"
                        >
                            <OtIcon
                                type="plus"
                                class="ot-button-icon--left"
                            />
                            {{ $t('dashboard.access_moments.list.header.new.text') }}
                        </button>
                    </template>
                </OtListHeader>
            </template>

            <template #empty-action-no-filter>
                <div class="module__access-moments__list__empty-content">
                    <i18n
                        class="company-selector__title"
                        tag="span"
                        path="dashboard.access_moments.list.empty.message"
                    >
                        <template #link>
                            <!-- TODO: Replace with correct support url link -->
                            <a
                                class="ot-link ot-clickable"
                                target="_blank"
                                :href="whitelabel.dashboard.support_url"
                            >
                                {{ $t('dashboard.access_moments.list.empty.link') }}
                            </a>
                        </template>
                    </i18n>

                    <button
                        class="ot-button is-outline"
                        data-testid="access-moment-grid-empty-new"
                        @click="formModal.open()"
                    >
                        <OtIcon
                            type="plus"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.access_moments.list.header.new.text') }}
                    </button>
                </div>
            </template>

            <template #tile="{ record }">
                <AccessMomentsListTile
                    :record="record"
                    @link-action="showModal(record)"
                />
            </template>
        </OtDataGrid>

        <UnlinkedTicketsList
            v-if="accessMoments.records.length > 0"
            ref="unlinkedTicketsListRef"
            data-testid="access-moment-unlinked-tickets-list"
        />

        <AccessMomentCreateModal
            v-if="formModal.isOpen"
            :modal="formModal"
            show-add-another-button
            :show-location-field="false"
            :show-name-field="true"
            @submit="() => accessMoments?.loadPage()"
        />

        <AccessMomentTicketsLinkModal
            ref="accessMomentTicketsLinkModalRef"
            @submit="() => unlinkedTicketsListRef?.reload()"
        />
    </div>
</template>

<script setup lang="ts">
import { ColumnTypes, OtDataGrid, type Column } from '@openticket/vue-dashboard-components';
import { watch, reactive, ref } from 'vue';
import type { VueLocalization } from '@openticket/vue-localization';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { Event, EventDate, ManagementClient } from '@openticket/lib-management';
import { useRouterPagination } from '../../../composables';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import AccessMomentsListTile from '../components/AccessMomentListTile.vue';
import { useFormModal } from '../../../composables/forms';
import AccessMomentCreateModal from '../components/AccessMomentCreateModal.vue';
import AccessMomentTicketsLinkModal from '../components/AccessMomentTicketsLinkModal.vue';
import UnlinkedTicketsList from '../components/UnlinkedTicketsList.vue';

const context = injectOrFail<Context>('context');
const localization = injectOrFail<VueLocalization>('localization');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

if (!context.isEventContext()) {
    throw new Error('Invalid context!');
}

const { list: accessMoments, setPagination } = useRouterPagination(context.event.model.eventDates, { perPage: 5 });
const formModal = reactive(useFormModal());

const accessMomentTicketsLinkModalRef = ref<InstanceType<typeof AccessMomentTicketsLinkModal> | null>(null);
const unlinkedTicketsListRef = ref<InstanceType<typeof UnlinkedTicketsList> | null>(null);

const columns: Column[] = [
    {
        key: [ '$data', 'start' ],
        type: ColumnTypes.date,
        label: $t('dashboard.access_moments.list.tile.columns.start.label'),
        simpleTileColumnSize: 'default',
    },
    {
        key: [ '$data', 'end' ],
        type: ColumnTypes.date,
        label: $t('dashboard.access_moments.list.tile.columns.end.label'),
        simpleTileColumnSize: 'default',
    },
    {
        key: [ '$data', 'location_id' ],
        type: ColumnTypes.custom,
        label: $t('dashboard.access_moments.list.tile.columns.location.label'),
        simpleTileColumnSize: 'default',
    },
    {
        key: [ '$data', 'capacity' ],
        type: ColumnTypes.number,
        label: $t('dashboard.access_moments.list.tile.columns.capacity.label'),
        simpleTileColumnSize: 'default',
    },
];

const filterCategoriesToHide = [
    'created_at',
    'facebook_event_id',
    'name',
    'updated_at',
];

watch(() => context.event?.id, (value) => {
    if (value !== null && value !== undefined) {
        void setPagination(context.event.model.eventDates);
    }
});

function showModal(record: EventDate<Event<ManagementClient>>): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    accessMomentTicketsLinkModalRef.value?.show(record);
}

// TODO: Remove when upgraded to Vue 3
function $t(slug: string, values?: Record<string, unknown>) {
    return localization.getI18n().t(slug, values);
}
</script>

<style scoped lang="scss">
.module__access-moments__list {
    display: flex;
    flex-direction: column;
    gap: var(--ot-spacing-2xl);

    &__grid {
        min-height: 25rem;
    }

    &__empty-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--ot-spacing-unit);
    }
}
</style>
