<template>
    <ActionTile
        :description="$t('dashboard.company.edit.actions.anti_fraud_watermark.description')"
        :title="$t('dashboard.company.edit.actions.anti_fraud_watermark.title')"
        class="actions-anti-fraud-watermark"
        data-testid="actions-anti-fraud-watermark"
    >
        <InputField
            :optional="companySettingsForm.rules.hide_free_watermark
            && companySettingsForm.rules.hide_free_watermark.includes('optional')"
            :required="companySettingsForm.rules.hide_free_watermark
            && companySettingsForm.rules.hide_free_watermark.includes('required')"
            class="actions-anti-fraud-watermark__toggle"
        >
            <OtInputToggle
                v-model="companySettingsForm.model.$data.hide_free_watermark"
                :label="$t('dashboard.company.edit.actions.anti_fraud_watermark.toggle_label')"
                data-testid="actions-anti-fraud-watermark-toggle"
            />
        </InputField>
        <button
            :disabled="!hasChanges"
            class="is-small ot-button"
            data-testid="actions-anti-fraud-watermark-submit"
            @click="save"
        >
            {{ $t('dashboard.company.edit.actions.anti_fraud_watermark.action_label') }}
        </button>
    </ActionTile>
</template>

<script setup lang="ts">
import type { UnwrapNestedRefs } from 'vue';
import { ref, computed } from 'vue';
import type { CompanySettings, ManagementClient } from '@openticket/lib-management/lib';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import ActionTile from './ActionTile.vue';
import InputField from '../../../../components/form/InputField.vue';

type Props = {
    companySettingsForm: UnwrapNestedRefs<ModelFormComposableResult<CompanySettings<ManagementClient>, CompanySettings<ManagementClient>>>;
}

const props = defineProps<Props>();

// TODO: replace with partial update
const originalWatermark = ref<boolean>(!!props.companySettingsForm.model.$data.hide_free_watermark);
const hasChanges = computed<boolean>(() => (originalWatermark.value !== props.companySettingsForm.model.$data.hide_free_watermark));

async function save() {
    await props.companySettingsForm.submit();
    originalWatermark.value = !!props.companySettingsForm.model.$data.hide_free_watermark;
}

</script>

<style lang="scss" scoped>
</style>
