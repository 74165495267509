<template>
    <div class="debug-plugins ot-card">
        <div
            class="debug-plugins__header debug__header"
            role="switch"
            tabindex="0"
            :aria-expanded="opened"
            :aria-checked="opened"
            @click="keepOpen"
            @keydown.space="keepOpen"
            @keydown.enter="keepOpen"
        >
            <i
                class="oti is-small"
                :class="plugins && !plugins.errorCount ? 'oti-checkmark' : 'oti-warning'"
            />
            Plugins

            <span class="hspace"></span>

            <span
                v-if="plugins.errorCount"
            >Errors: <code class="is-failure">#{{ plugins && plugins.errorCount }}</code></span>

            <i class="oti" :class="opened ? 'oti-drop-up' : 'oti-drop-down'" />
        </div>

        <div class="debug-plugins__content" v-if="opened">
            <DebugPluginsPlugin
                class="debug-plugins__plugin"
                v-for="(plugin, name) in filteredPlugins"
                :key="`debug-plugins__plugin__${name}`"
                :name="name"
                :plugin="plugin"
            ><component :is="debugComponents[name]" /></DebugPluginsPlugin>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { DefineComponent } from 'vue';
import { computed } from 'vue';
import type { PluginsManager } from '../../../../plugins';
import DebugPluginsAuth from './DebugPluginsAuth.vue';
import DebugPluginsComponents from './DebugPluginsComponents.vue';
import DebugPluginsContext from './DebugPluginsContext.vue';
import DebugPluginsDocumentTitle from './DebugPluginsDocumentTitle.vue';
import DebugPluginsHotkeys from './DebugPluginsHotkeys.vue';
import DebugPluginsLocalization from './DebugPluginsLocalization.vue';
import DebugPluginsLog from './DebugPluginsLog.vue';
import DebugPluginsManagement from './DebugPluginsManagement.vue';
import DebugPluginsRouter from './DebugPluginsRouter.vue';
import DebugPluginsWhitelabel from './DebugPluginsWhitelabel.vue';
import { injectOrFail } from '../../../../services/util';
import DebugPluginsPlugin from './DebugPluginsPlugin.vue';

type PluginsOnly = Omit<PluginsManager, 'options' | 'errorCount' | 'all' | 'install'>;

type DebugPluginComponent<Props = {}, Emits = {}, Slots = {}> = DefineComponent<Props, Emits, Slots, {}, {}>;

const debugComponents: Partial<Record<keyof PluginsOnly, DebugPluginComponent>> = {
    auth: DebugPluginsAuth,
    components: DebugPluginsComponents,
    context: DebugPluginsContext,
    documentTitle: DebugPluginsDocumentTitle,
    hotkeys: DebugPluginsHotkeys,
    localization: DebugPluginsLocalization,
    log: DebugPluginsLog,
    management: DebugPluginsManagement,
    router: DebugPluginsRouter,
    whitelabel: DebugPluginsWhitelabel,
};

const plugins = injectOrFail<PluginsManager>('plugins');

const filteredPlugins = computed<PluginsOnly>(() => {
    const filtered: PluginsOnly = { ...plugins };
    delete (filtered as { options?: unknown }).options;

    return filtered;
});

type Props = {
    opened?: boolean;
};
withDefaults(defineProps<Props>(), {
    opened: false,
});

type Emits = {
    (e: 'keep-open'): void
}
const emit = defineEmits<Emits>();

function keepOpen() {
    emit('keep-open');
}
</script>

<style lang="scss" scoped>
.debug-plugins {
    padding: 0;

    &__plugin {
        &:not(:last-child) {
            margin-bottom: 1px;
        }
    }

    & > &__header {
        cursor: pointer;
    }

    & > &__header, & > &__content {
        padding: var(--ot-card-padding);
    }
}
</style>
