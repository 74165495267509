// Register component hooks from packages (e.g. vue-router)
import './importThisFileFirst';
import Vue, { type CreateElement } from 'vue';
import App from './App.vue';
import { moduleRoutes } from './modules/routes';
import { PluginsManager } from './plugins';
import './util.scss';
import './style.scss';
import type { WhitelabelOptions } from './plugins/types';
import { iframeResize } from './services/util/directives/iframeResize';

const whitelabelOptions: WhitelabelOptions = {
    override: new URLSearchParams(window.location.search).get('whitelabel') || (window.location.hostname.startsWith('testapp') ? window.location.hostname : null),
};

if (import.meta.env.VITE_WHITELABEL_CONFIG_URL) {
    whitelabelOptions.configUrlOverride = import.meta.env.VITE_WHITELABEL_CONFIG_URL;
}

const pluginManager = new PluginsManager({
    // Auth:
    // - client id and secret
    // Log:
    // - url
    //
    // log: {
    //     url: 'https://notify.openticket.local:8000/', // TODO Remove before merge / deploy to master
    // },
    router: {
        routes: moduleRoutes(),
    },
    whitelabel: whitelabelOptions,
});

Vue.config.productionTip = false;
Vue.use(pluginManager);

Vue.directive('resize', iframeResize);

const app = new Vue({
    render: (h: CreateElement) => h(App),
});

app.$mount('#app');
