<template>
    <OtCard class="tfa-choice" no-padding>
        <ModalHeader
            :hide-logo="hideLogo"
            :hide-logout="hideLogout"
            text-centered
        >
            <template #title>
                <h1>{{ $t('dashboard.user.tfa.setup.title') }}</h1>
            </template>

            <template #subtitle>
                {{ $t('dashboard.user.tfa.setup.explanation') }}
            </template>
        </ModalHeader>

        <OtCardContent>
            <div class="tfa-choice__options">
                <div class="tfa-choice__options__main__option">
                    <div>
                        <h5>{{ $t('dashboard.user.tfa.confirmation.totp.title') }}</h5>

                        <p>{{ $t('dashboard.user.tfa.confirmation.totp.explanation') }}</p>
                    </div>

                    <button
                        class="ot-button is-round is-large"
                        type="button"
                        @click="onChoice('totp')"
                    >
                        <i class="oti oti-arrow-right" />
                    </button>
                </div>
            </div>
        </OtCardContent>

        <OtCardFooter>
            <template #left>
                <button
                    class="ot-button is-dark"
                    type="button"
                    @click="back()"
                    :title="$t('dashboard.common.action.back.title')"
                >
                    <i class="oti oti-arrow-left ot-button-icon--left" />
                    {{ $t('dashboard.common.action.back.text') }}
                </button>
            </template>
        </OtCardFooter>
    </OtCard>
</template>

<script setup lang="ts">
import ModalHeader from '../modal/ModalHeader.vue';

type Props = {
    hideLogo: boolean,
    hideLogout: boolean
}

type Emits = {
    (e: 'back'): void,
    (e: 'choice', type: string): void
}

withDefaults(
    defineProps<Props>(),
    {
        hideLogo: false,
        hideLogout: false,
    },
);

const emit = defineEmits<Emits>();

const back = (): void => {
    emit('back');
};

const onChoice = (type: string): void => {
    emit('choice', type);
};
</script>

<style lang="scss" scoped>
.tfa-choice {
    &__options {
        text-align: left;

        &__main__option {
            display: flex;
            justify-content: space-between;
        }
    }
}
</style>
