import { Whitelabel, type WhitelabelInitConfig } from '@openticket/lib-whitelabels';
import type { VueConstructor } from 'vue';
import { getGlobal, type OpenTicketGlobal } from '../openticket';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import type { WhitelabelOptions } from './types';

export class WhitelabelPlugin extends Plugin<Whitelabel> {

    async install(plugins: PluginsManager, Vue: VueConstructor): Promise<void> {
        try {
            const options: WhitelabelOptions = plugins.options.whitelabel || {};

            const config: Partial<WhitelabelInitConfig> = {};

            if (options.configUrlOverride) {
                config.url = options.configUrlOverride;
            } else if (options.override) {
                config.whitelabel = options.override;
            }

            const whitelabel = new Whitelabel(config);

            const OT: OpenTicketGlobal = getGlobal();

            OT.Whitelabel = whitelabel;

            Object.defineProperty(Vue.prototype, '$whitelabel', {
                get: () => whitelabel,
            });

            Vue.mixin({
                provide: {
                    whitelabel,
                },
            });

            void whitelabel.loadStylesheet();
            void whitelabel.loadIconPack();
            void whitelabel.setFavicon();

            await whitelabel.init();

            this.resolve(whitelabel);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

}
