import type { VueConstructor } from 'vue';
import { reactive } from 'vue';
import { Context } from '../services/context';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';

export class ContextPlugin extends Plugin<Context> {

    install(plugins: PluginsManager, Vue: VueConstructor): void {
        try {
            const contextPlugin = new Context(plugins);
            const context = reactive<Context>(contextPlugin);

            Vue.mixin({ provide: { context } });

            this.resolve(contextPlugin);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

}
