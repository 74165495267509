import type { ShopDataCollapse, ShopDataTicket } from '../../../services/shop';
import type { LinkEvent, SortEvent } from '../../../services/shop/types';

type EventQueueItem = {
    name: string;
    load: LinkEvent | SortEvent
}

export default class DraggableInterpreter {

    private readonly parentGuid: string | undefined;
    private eventQueue: Array<EventQueueItem> = [];
    private readonly collapses: Array<ShopDataTicket | ShopDataCollapse> = [];
    private collapsesShadow: Array<ShopDataTicket | ShopDataCollapse> = [];

    constructor(collapses: Array<ShopDataTicket | ShopDataCollapse>, parentGuid?: string) {
        this.parentGuid = parentGuid;
        this.collapses = collapses;
        this.reset();
    }

    public getEventQueue(): Array<EventQueueItem> {
        return this.eventQueue;
    }

    public persistSort(oldIndex: number, newIndex: number): this {
        if (this.$isMovingOut) {
            return this; // Leave sorting to the list the item is moved to
        }

        const self = this.getSelf(newIndex, oldIndex);
        if (self._type_ === 'collapse' && this.parentGuid) {
            return this;
        }

        if (this.$isMovingIn) {
            const prevSibling = this.collapses[newIndex - 1];
            const nextSibling = this.collapses[newIndex + 1];

            if (typeof prevSibling !== 'undefined') {
                this.persist('move:down', {
                    itemGuid: self.guid,
                    anchorGuid: prevSibling._type_ === 'collapse' ? prevSibling.items[prevSibling.items.length - 1].guid : prevSibling.guid,
                });
            }

            if (typeof nextSibling !== 'undefined') {
                this.persist('move:up', {
                    itemGuid: self.guid,
                    anchorGuid: nextSibling._type_ === 'collapse' ? nextSibling.items[0].guid : nextSibling.guid,
                });
            }

            return this;
        }

        const sortMutation = {
            itemGuid: self.guid,
            anchorGuid: this.getAnchor(newIndex).guid,
        };

        if (newIndex > oldIndex) {
            this.persist('move:down', sortMutation);
        } else {
            this.persist('move:up', sortMutation);
        }

        return this;
    }

    public persistLink(oldIndex: number, newIndex: number): this {
        const self = this.getSelf(newIndex, oldIndex);

        if (self._type_ === 'collapse' && this.parentGuid) {
            return this;
        }

        if (!this.parentGuid) {
            console.error('Trying to persistLink without a parentGuid');
            return this;
        }

        this.persist('link', {
            itemGuid: self.guid,
            parentGuid: this.parentGuid,
        });

        return this;
    }

    public persistUnlink(oldIndex: number, newIndex: number): this {
        if (!this.parentGuid) {
            console.error('Trying to persistUnlink without a parentGuid');
            return this;
        }

        this.persist('unlink', {
            itemGuid: this.getSelf(newIndex, oldIndex).guid,
            parentGuid: this.parentGuid,
        });

        return this;
    }

    public reset(): this {
        this.collapsesShadow = [ ...this.collapses ]; // clone to kill reactivity
        this.eventQueue = [];

        return this;
    }

    private persist(name: string, load: LinkEvent | SortEvent): this {
        this.eventQueue.push({
            name,
            load,
        });

        return this;
    }

    private getAnchor(newIndex: number): ShopDataTicket | ShopDataCollapse {
        const anchor: ShopDataTicket | ShopDataCollapse = this.collapsesShadow[newIndex];

        if (anchor._type_ === 'collapse') {
            return anchor.items[0];
        }

        return anchor;
    }

    private getSelf(newIndex: number, oldIndex: number): ShopDataTicket | ShopDataCollapse {
        if (this.$isMovingIn) {
            return this.collapses[newIndex];
        }

        const self: ShopDataTicket | ShopDataCollapse = this.collapsesShadow[oldIndex];

        if (self._type_ === 'collapse') {
            return self.items[0];
        }

        return self;
    }

    // Indicates whether the current item is being moved into a new list
    private get $isMovingIn(): boolean {
        return (this.collapses.length > this.collapsesShadow.length);
    }

    // Indicates whether the current item is being moved out of its current list
    private get $isMovingOut(): boolean {
        return (this.collapsesShadow.length > this.collapses.length);
    }

    // Indicates whether the current item is being moved within its current list
    private get $isMovingFlat(): boolean {
        return (this.collapsesShadow.length === this.collapses.length);
    }

}
