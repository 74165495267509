import type { Whitelabel, WhitelabelDashboardConfig } from '@openticket/lib-whitelabels';
import { injectOrFail } from '../services/util';
import type { InternalNavigatorItem } from '../services/navigator';

export const useNavigationGuard = () => {
    const whitelabel = injectOrFail<Whitelabel>('whitelabel');

    /** Record of <Route name, whitelabel dashboard url> */
    const routesNeedWlConfig: Record<string, string> = {
        connections: 'albato_url',
    };

    const isNavItemAvailable = (item: InternalNavigatorItem) => !item.disabled && !!item.route?.name;

    // Disable the route when `url` is not present in whitelabel config
    function navItemNeedsWhitelabelRoute(item: InternalNavigatorItem) {
        const routeName = item.route?.name;

        if (!routeName) {
            return false;
        }

        return Object.keys(routesNeedWlConfig).includes(routeName)
            && whitelabel.dashboard[routesNeedWlConfig[routeName] as keyof WhitelabelDashboardConfig] === undefined;
    }

    return { isNavItemAvailable, navItemNeedsWhitelabelRoute };
};
