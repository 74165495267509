import { OtError } from '@openticket/lib-log';

export class ContextPluginsUndefinedError extends OtError {

    constructor() {
        super('ContextPluginsUndefinedError', 'dashboard.errors.plugins.context.plugins.undefined', 'Plugins are undefined.');

        this.captureStackTrace(ContextPluginsUndefinedError);
    }

}
