<template>
    <div class="ticket-visitor-data">
        <div class="ticket-visitor-data__header">
            <h1>{{ $t('dashboard.tickets.visitor_data.header.title') }}</h1>
            <div class="ot-text-body">{{ $t('dashboard.tickets.visitor_data.header.description') }}</div>
        </div>
        <div class="ticket-visitor-data__sorter">
            <OtForm>
                <InputField class="ticket-visitor-data__sorter-search">
                    <OtInput class="input" type="search"
                        :placeholder="$t('dashboard.tickets.visitor_data.search.placeholder')" />
                </InputField>
                <div>
                    <!-- TODO Use CrudSorter/SlickSorter here, can't really use CrudSorter here because of design reasons. -->
                    <div class="ticket-visitor-data__sorter__item" v-for="(model, index) in list.data" :key="index">
                        {{ index + 1 }}
                        {{ model.name }}
                        <div class="ticket-visitor-data__sorter__item-right">
                            <div class="drop-both">
                                <OtIcon type="drop-both" />
                            </div>
                            <div class="close">
                                <OtIcon type="close" class="close"/>
                            </div>
                        </div>
                    </div>
                </div>
            </OtForm>
        </div>
        <div class="ticket-visitor-data__footer">
            {{ $t('dashboard.tickets.visitor_data.footer.text') }}
            <button class="ot-button is-outline">
                {{ $t('dashboard.tickets.visitor_data.footer.button.text') }}
                <OtIcon type="carrot-right" />
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ArrayPaginatable, LazyList } from '@openticket/lib-sdk-helpers';
import type { TableRecord } from '@openticket/vue-dashboard-components';
import { ref } from 'vue';
import InputField from '../../../components/form/InputField.vue';

// TODO Open+++Ticket hardcoded
const uselessData: TableRecord = {
    guid: 'not-a-ticket-visitor-data-guid',
    created_at: '2023-01-01T01:01:01+01:00',
    updated_at: '2023-01-01T01:01:01+01:00',
};

// Replace with correct ticket visitor data
const list = ref(new LazyList(new ArrayPaginatable<TableRecord & { name: string }>([
    {
        ...uselessData,
        name: 'Open+++Ticket - First Name',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - Last Name',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - Email',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - Street',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - House number',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - House number appendix',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - ZIP code',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - Date of birth',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - City',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - Gender',
    },
])));

void list.value.init({
    defaults: {
        per_page: 15,
    },
});
</script>

<style scoped lang="scss">
.ticket-visitor-data {
    max-width: 40rem;

    &__sorter {
        margin: var(--ot-spacing-xl) 0;

        &-search {
            margin: var(--ot-spacing-xl) 0;
        }

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            padding: var(--ot-spacing-sm) 0;

            border-bottom: 1px solid var(--ot-color-core-accent-secondary);

            &-right {
                display: flex;
                flex-direction: row;
                gap: var(--ot-spacing-default);

                color: var(--ot-color-core-accent-primary);

                .close:hover {
                    color: var(--ot-color-accent-orange-light);
                }

                .drop-both:hover {
                    color: var(--ot-color-core-brand);
                }
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: var(--ot-spacing-default);
    }
}
</style>
