import type { VueConstructor } from 'vue';
import AvatarHeader from './AvatarHeader.vue';
import ConfettiWrapper from './ConfettiWrapper.vue';
import ModalHeader from './modal/ModalHeader.vue';

export function registerComponents(app: VueConstructor): void {
    app.component('AvatarHeader', AvatarHeader);
    app.component('ConfettiWrapper', ConfettiWrapper);
    app.component('ModalHeader', ModalHeader);
}
