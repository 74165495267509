import type { Route, RouteConfig } from 'vue-router';
import CompaniesListView from './views/CompaniesListView.vue';
import CompaniesMembersList from './components/members/CompaniesMembersList.vue';
import CompaniesMembersEdit from './components/members/CompaniesMembersEdit.vue';
import CompaniesMembersInvites from './components/members/CompaniesMembersInvites.vue';
import { RouterView } from '../../services/util';
import CompaniesSettingsWrapper from './layouts/CompanyUpdateWrapper.vue';
import {ROLE_ADMIN, ROLE_WHITELABEL_ADMIN} from "@openticket/lib-auth";

export function companiesRoutes(): RouteConfig[] {
    return [
        {
            path: '/companies',
            component: RouterView,
            children: [
                {
                    path: '',
                    name: 'companies.list',
                    component: CompaniesListView,
                    meta: {
                        context: 'global',
                        title: 'dashboard.document_title.companies.list',
                        titleFallback: 'Companies',
                        onContextSelect(id) {
                            if (!id || typeof id === 'symbol') {
                                return null;
                            }
                            return {
                                name: 'companies.edit.general',
                                params: { company: id },
                            };
                        },
                    },
                },
                {
                    path: ':company',
                    name: 'companies.home',
                    component: RouterView,
                    meta: {
                        context: 'company',
                        title: 'dashboard.document_title.companies.company',
                        titleFallback: 'Company',
                        onContextSelect(id) {
                            if (!id || typeof id === 'symbol') {
                                return {
                                    name: 'companies.list',
                                };
                            }
                            return {
                                name: 'companies.edit.general',
                                params: { company: id },
                            };
                        },
                    },
                    redirect: (to: Route) => ({
                        name: 'companies.edit',
                        params: { ...to.params },
                    }),
                    children: [
                        {
                            path: 'edit',
                            name: 'companies.edit',
                            component: CompaniesSettingsWrapper,
                            meta: {
                                title: 'dashboard.document_title.companies.edit.main',
                                titleFallback: 'Company Settings',
                            },
                            redirect: (to: Route) => ({
                                name: 'companies.edit.general',
                                params: { ...to.params },
                            }),
                            children: [
                                {
                                    path: 'general',
                                    name: 'companies.edit.general',
                                },
                                {
                                    path: 'registration',
                                    name: 'companies.edit.registration',
                                },
                                {
                                    path: 'bank-info',
                                    name: 'companies.edit.bank-info',
                                },
                                {
                                    path: 'contact',
                                    name: 'companies.edit.contact',
                                },
                                {
                                    path: 'security',
                                    name: 'companies.edit.security',
                                },
                                {
                                    path: 'members',
                                    name: 'companies.edit.members',
                                    component: RouterView,
                                    redirect: (to: Route) => ({
                                        name: 'companies.edit.members.home',
                                        params: { ...to.params },
                                    }),
                                    children: [
                                        {
                                            path: '',
                                            name: 'companies.edit.members.home',
                                            component: CompaniesMembersList,
                                        },
                                        {
                                            path: 'invites',
                                            name: 'companies.edit.members.invites',
                                            component: CompaniesMembersInvites,
                                        },
                                        {
                                            path: ':user',
                                            name: 'companies.edit.members.edit',
                                            component: CompaniesMembersEdit,
                                        },
                                    ],
                                },
                                {
                                    path: 'admin-actions',
                                    name: 'companies.edit.actions',
                                    meta: {
                                        restrictedBy: ROLE_WHITELABEL_ADMIN,
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
}
