<template>
    <InputField
        :label="$t('dashboard.tickets.details.inputs.ticket_price.label')"
        :description="$t('dashboard.tickets.details.inputs.ticket_price.description')"
        :required="form.rules.min_price && form.rules.min_price.includes('required')"
        :optional="form.rules.min_price && form.rules.min_price.includes('optional')"
        :error="priceErrors"
        class="input-ticket-price"
    >
        <OtInputText
            v-if="isFree"
            class="input"
            :disabled="true"
            :value="$t('dashboard.tickets.details.inputs.ticket_price.free')"
        />

        <OtInputFloat
            v-else
            v-model="form.model.$data.min_price"
            class="input"
            :prefix="currency"
            :decimals="2"
            :factor="100"
            data-testid="input-ticket-price"
            @input="form.errors.min_price = []"
        />

        <OtInputToggle
            data-testid="input-ticket-price-isfree"
            :label="$t('dashboard.tickets.details.inputs.ticket_price.is_free')"
            :value="isFree"
            @input="handleIsFreeToggle"
        />

        <OtFormRow
            class="input-ticket-price__vat"
            data-testid="input-ticket-price-vat"
        >
            <OtInputSelectBar
                :value="vatPercentage"
                :options="vatOptions"
                class="input-ticket-price__vat-select"
                :disabled="isFree"
                @input="updateVatPercentage"
            >
                <template #none>
                    {{ vatOptions['none'] }}%
                </template>
                <template #reduced>
                    {{ vatOptions['reduced'] }}%
                </template>
                <template #normal>
                    {{ vatOptions['normal'] }}%
                </template>
                <template #other>
                    {{ vatOptions['other'] }}
                </template>
            </OtInputSelectBar>
            <OtInputFloat
                v-if="vatPercentage === 'other'"
                v-model="form.model.$data.vat_percentage"
                class="input"
                :prefix="$t('dashboard.events.wizard.steps.tickets.upsert.form.vat.input.prefix')"
                suffix="%"
                :disabled="isFree"
                @input="form.errors.vat_percentage = []"
            />
        </OtFormRow>
    </InputField>
</template>

<script lang="ts" setup>
import type { Ticket, ManagementClient } from '@openticket/lib-management';
import {
    computed, onMounted, ref, type UnwrapNestedRefs,
} from 'vue';
import type { TranslateResult, VueLocalization } from '@openticket/vue-localization';
import type { ModelFormComposableResult } from '../../composables/forms';
import InputField from './InputField.vue';
import type { FormErrors } from '../../composables/forms/types';
import { injectOrFail } from '../../services/util';
import type { Context } from '../../services/context';

// TODO Move these hardcoded values to the backend where the values can be updated when necessary
type VatCountries = 'BE' | 'CH' | 'DE' | 'ES' | 'FR' | 'GT' | 'IE' | 'NL' | 'TR' | 'UK' | 'US';
const VAT_RATES: { [key in VatCountries ]: CountryVatRates } = {
    BE: { reduced: 6, normal: 21 },
    CH: { reduced: 2.5, normal: 7.7 },
    DE: { reduced: 7, normal: 19 },
    ES: { reduced: 10, normal: 21 },
    FR: { reduced: 5.5, normal: 20 },
    GT: { reduced: 5, normal: 12 },
    IE: { reduced: 13.5, normal: 23 },
    NL: { reduced: 9, normal: 21 },
    TR: { reduced: 10, normal: 20 },
    UK: { reduced: 5, normal: 20 },
    US: {},
};

type CountryVatRates = { reduced?: number, normal?: number };
type StandardVatRates = { none: number, other: TranslateResult };
type VatRates = CountryVatRates & StandardVatRates;

type Props = {
    currency: string,
    form: UnwrapNestedRefs<ModelFormComposableResult<Ticket<ManagementClient>, ManagementClient>>
}

const props = defineProps<Props>();

const localization = injectOrFail<VueLocalization>('localization');
const context = injectOrFail<Context>('context');

const isFree = ref<boolean>(props.form.model.$data.min_price === 0);
const vatPercentage = ref('0');

const vatOptions = computed<VatRates>(() => {
    const standarVatOptions = {
        none: 0,
        other: localization.getI18n().t('dashboard.events.wizard.steps.tickets.upsert.form.vat.other'),
    };

    if (!context.isCompanyContext()) {
        return standarVatOptions;
    }

    const companyLocation = context.company.model.$data.country ?? localization.locale.territory;
    const vatRates = VAT_RATES[companyLocation as VatCountries];

    if (!vatRates) {
        return standarVatOptions;
    }

    return {
        none: 0,
        ...vatRates,
        other: localization.getI18n().t('dashboard.events.wizard.steps.tickets.upsert.form.vat.other'),
    };
});

const priceErrors = computed<FormErrors>(() => {
    const errors: FormErrors = [];

    if (props.form.errors.min_price) {
        errors.push(...props.form.errors.min_price);
    }

    if (props.form.errors.vat_percentage) {
        const map = props.form.errors.vat_percentage.map((item) => ({
            ...item,
            customAttribute: localization.getI18n().t('dashboard.events.wizard.steps.tickets.upsert.form.price.vat'),
        }));

        errors.push(...map);
    }

    return errors;
});

onMounted(() => {
    const vat = props.form.model.$data.vat_percentage;
    const vatRate: keyof VatRates | undefined = (Object.keys(vatOptions.value) as Array<keyof VatRates>)
        .find((key: keyof VatRates) => vatOptions.value[key] === vat);

    updateVatPercentage(vatRate || 'other');
});

function updateVatPercentage(value: keyof VatRates) {
    props.form.errors.vat_percentage = [];
    const newValue = vatOptions.value[value];
    vatPercentage.value = value;

    if (value !== 'other' && typeof newValue === 'number') {
        props.form.model.$data.vat_percentage = newValue;
    }
}

function handleIsFreeToggle() {
    isFree.value = !isFree.value;

    if (isFree.value) {
        props.form.model.$data.min_price = 0;
        updateVatPercentage('none');
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/mixins.scss";

.input-ticket-price {
    &::v-deep .input-field__content {
        flex-wrap: wrap;
        gap: var(--ot-spacing-sm);
    }

    &__vat {
        flex-basis: 100%;

        &-select {
            max-width: max-content;

            @include breakpoint(tab) {
                width: 100%;
                max-width: 100%
            }
        }
    }
}
</style>
