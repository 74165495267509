<template>
    <div class="form-wrapper">
        <div class="form-wrapper__header ot-pb[lg]">
            <slot name="header-title">
                <div
                    class="form-wrapper__header-title"
                    data-testid="form-wrapper-header-title"
                >
                    <h1>
                        {{ headerTitle }}
                    </h1>
                </div>
            </slot>

            <div class="form-wrapper__header-actions">
                <slot name="header-actions">
                    <slot name="secondary-actions" />
                    <button
                        v-if="!hideControls"
                        :disabled="!form.hasLocalChanges"
                        data-testid="form-wrapper-top-save"
                        @click="save"
                    >
                        <OtIcon
                            type="check"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.common.action.save.text') }}
                    </button>
                </slot>
            </div>
        </div>

        <div class="form-wrapper__content ot-my[xl]">
            <slot />
        </div>

        <div class="form-wrapper__footer">
            <OtPageFooter v-if="!hideControls">
                <template #left>
                    <button
                        class="ot-button is-dark"
                        @click="goBack"
                    >
                        <OtIcon
                            type="arrow-left"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.common.action.back.text') }}
                    </button>
                </template>
                <template #right>
                    <button
                        :disabled="!form.hasLocalChanges"
                        class="ot-button"
                        data-testid="form-wrapper-bottom-save"
                        @click="save"
                    >
                        <OtIcon
                            type="check"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.common.action.save.text') }}
                    </button>
                </template>
            </OtPageFooter>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, type UnwrapNestedRefs, watch } from 'vue';
import type { Model, ModelConfig, Parent } from '@openticket/lib-crud';
import { useRoute, useRouter } from 'vue-router/composables';
import type { ModelFormComposableResult } from '../../composables/forms';
import { useGenericErrorHandling } from '../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Model<Parent, ModelConfig>, Parent>>,
    backRoute?: string,
    hideControls?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
    backRoute: undefined,
    hideControls: false,
});

const route = useRoute();
const router = useRouter();
const { handleErrorSilently } = useGenericErrorHandling();
const headerTitle = ref<string>(props.form.getModelName());

async function save() {
    try {
        const { success } = await props.form.submit();

        if (success) {
            headerTitle.value = props.form.getModelName();
        }
    } catch (e) {
        void handleErrorSilently(e);
    }
}

// Updating the title of the form, on context switch.
watch(() => props.form.model, () => {
    headerTitle.value = props.form.getModelName();
});

async function goBack() {
    if (props.backRoute) {
        await router.push({
            name: props.backRoute,
            params: route.params,
        });
    } else {
        router.back();
    }
}
</script>

<style scoped lang="scss">
.form-wrapper {
    &__header {
        border-bottom: 1px solid var(--ot-color-core-accent-secondary);
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 4.75rem;
        &-actions {
            display: flex;
            gap: 1rem;
        }
    }

    &__content {
        max-width: 62rem; // 12rem VerticalTab items + 50rem content
    }
}
</style>
