<template>
    <div
        class="shop-settings__order"
        v-if="settings"
    >
        <h2 class="has-margin">{{ $t('dashboard.shop.settings.order.title') }}</h2>
        <h5 class="ot-text-tiny has-margin subtitle">{{ $t('dashboard.shop.settings.order.description') }}</h5>

        <OtInputField
          :label="$t('dashboard.shop.settings.order.general.title')"
          >
          <OtInput
              type="toggle"
              :label="$t('dashboard.shop.settings.order.general.apple_wallet.label')"
              v-model="settings.static.order.enablePassbook"
          />
        </OtInputField>

        <OtInputField
            :label="$t('dashboard.shop.settings.order.resell.title')"
            :description="$t('dashboard.shop.settings.order.resell.description')"
        >
            <OtInput
                type="toggle"
                :label="$t('dashboard.shop.settings.order.resell.resell.label')"
                v-model="settings.static.order.enableResell"
            />

            <OtInput
                type="toggle"
                :label="$t('dashboard.shop.settings.order.resell.ticket_swap.label')"
                :disabled="!settings.static.order.enableResell"
                v-model="settings.static.order.enableTicketSwap"
            />

            <OtInput
                type="toggle"
                :label="$t('dashboard.shop.settings.order.resell.tixel.label')"
                :disabled="!settings.static.order.enableResell"
                v-model="settings.static.order.enableTixel"
            />
        </OtInputField>

        <OtInputField
            :label="$t('dashboard.shop.settings.order.event_card.title')"
            :description="$t('dashboard.shop.settings.order.event_card.description')"
        >
            <OtInput
                type="toggle"
                :label="$t('dashboard.shop.settings.order.event_card.show_event_date.label')"
                v-model="settings.static.order.components.eventCard.showEventDate"
            />

            <OtInput
                type="toggle"
                :label="$t('dashboard.shop.settings.order.event_card.show_location.label')"
                v-model="settings.static.order.components.eventCard.showLocation"
            />
        </OtInputField>

        <OtInputField
            v-if="roles.isWhitelabelAdmin"
            :label="$t('dashboard.shop.settings.order.admin.title')"
            :description="$t('dashboard.shop.settings.order.admin.description')"
        >
            <OtInput
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.enable_appic.label')"
                v-model="settings.static.order.enableAppic"
            />

            <OtInput
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.enable_party_pay.label')"
                v-model="settings.static.order.enablePartyPay"
            />

            <OtInput
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.disable_download_button.label')"
                v-model="settings.static.order.disableDownloadButton"
            />

            <OtInput
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.event_card.disable_download_button.label')"
                v-model="settings.static.order.components.eventCard.disableDownloadButton"
            />

            <div class="ot-toggle-label">
                {{ $t('dashboard.shop.settings.order.admin.event_card.close_url.label') }}
            </div>
            <OtInput type="text" v-model="settings.static.order.components.eventCard.closeUrl" />

            <OtInput
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.header.showLogo.label')"
                v-model="settings.static.order.header.showLogo"
            />

            <OtInput
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.footer.showPoweredBy.label')"
                v-model="settings.static.order.footer.showPoweredBy"
            />

            <OtInput
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.footer.showLocaleSwitcher.label')"
                v-model="settings.static.order.footer.showLocaleSwitcher"
            />

            <OtInput
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.footer.showCopyright.label')"
                v-model="settings.static.order.footer.showCopyright"
            />

            <OtInput
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.footer.showSupport.label')"
                v-model="settings.static.order.footer.showSupport"
            />
        </OtInputField>
    </div>
</template>

<script setup lang="ts">
import type { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import type { UnwrapNestedRefs } from 'vue';
import { injectOrFail } from '../../../../services/util';
import type { Roles } from '../../../../plugins/types';

const settings = injectOrFail<CustomShopSettingsClient>('settings');
const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');
</script>

<style lang="scss" scoped>
.shop-settings__order {
    .ot-input-field > .ot-toggle:not(:last-child) {
        margin-bottom: .375rem;
    }

    .subtitle {
        color: var(--ot-color-core-foreground-secondary);
    }
}
</style>
