import type { AxiosInstance, AxiosResponse } from 'axios';
import { Pagination, validatePaginationResponseData, type PaginationResponse } from '@openticket/lib-crud';
import type { MutationTypes, RevisionModelType } from './types';
import { RevisorInvalidResponseError } from './errors/revisor.invalid.response.error';

export class Revisor {

    http: AxiosInstance;
    baseUrl: string = '';

    constructor(config: { http: AxiosInstance, baseUrl: string }) {
        this.baseUrl = config.baseUrl;
        this.http = config.http;
    }

    getRevisions(type: RevisionModelType, guid: string, per_page: number = 6): Pagination<MutationTypes> | undefined {
        try {
            const revisorUrl = `${this.baseUrl}${type}/${guid}?per_page=${per_page}`;

            const pageResult = new Pagination(async (page) => {
                const result: AxiosResponse<PaginationResponse<MutationTypes>> = await this.http.get(`${revisorUrl}&page=${page}`);

                if (!validatePaginationResponseData(this.baseUrl, result.data)) {
                    throw new RevisorInvalidResponseError();
                }

                return result.data;
            });

            return pageResult;
        } catch (e) {
            throw new Error(e as string);
        }
    }

}
