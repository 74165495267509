<template>
    <FormWrapper
        class="access-moments-update"
        :form="accessMomentForm"
        back-route="access-moments.list"
    >
        <template #secondary-actions>
            <RevisionModal
                :id="context.accessMoment?.id || ''"
                ref="revisionsModalRef"
                type="eventdate"
                :model-name="context.accessMoment?.model.$data.name || ''"
            />
        </template>

        <VerticalTabs>
            <template #default="{ selected }">
                <VerticalTabsItem
                    :label="$t('dashboard.access_moments.details.route_title')"
                    :route="{ name: 'access-moments.edit.details' }"
                    :selected="selected"
                >
                    <AccessMomentFormFields
                        :form="accessMomentForm"
                        show-name-field
                        name-field-on-top
                    />
                </VerticalTabsItem>
                <VerticalTabsItem
                    :label="$t('dashboard.access_moments.linked_tickets.route_title')"
                    :route="{ name: 'access-moments.edit.linked-tickets' }"
                    :selected="selected"
                >
                    <AccessMomentLinkedTicketsView />
                </VerticalTabsItem>
            </template>
        </VerticalTabs>
    </FormWrapper>
</template>

<script setup lang="ts">
import {
    reactive,
    ref,
} from 'vue';
import type {
    Event,
    EventDate,
    ManagementClient,
} from '@openticket/lib-management';
import { useUpdateForm } from '../../../composables/forms';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import VerticalTabs from '../../../components/verticaltabs/VerticalTabs.vue';
import VerticalTabsItem from '../../../components/verticaltabs/VerticalTabsItem.vue';
import FormWrapper from '../../../components/form/FormWrapperNew.vue';
import RevisionModal from '../../../components/revisions/RevisionModal.vue';
import AccessMomentFormFields from '../components/forms/AccessMomentFormFields.vue';
import AccessMomentLinkedTicketsView from './AccessMomentLinkedTicketsView.vue';

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');

const revisionsModalRef = ref<InstanceType<typeof RevisionModal> | null>(null);

if (!context.isAccessMomentContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const accessMomentForm = reactive(
    useUpdateForm<EventDate<ManagementClient>, Event<ManagementClient>>(
        context.accessMoment.model,
        context.event.model.eventDates,
        {
            async onSubmit() {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                await revisionsModalRef.value?.updateRevisions();
            },
        },
    ),
);
</script>
