<template>
    <OtDialogModal
        ref="accessMomentTicketsLinkModalRef"
        class="access-moment-tickets-link-modal"
        @close="$emit('close')"
    >
        <OtCard
            v-if="accessMomentModel"
            class="access-moment-tickets-link-modal__card"
        >
            <OtCardHeader :title="$t('dashboard.access_moments.list.link_tickets.title')" />

            <OtCardContent>
                <i18n
                    tag="span"
                    path="dashboard.access_moments.list.link_tickets.description"
                    class="access-moment-tickets-link-modal__card__description"
                >
                    <template #moment>
                        <span class="access-moment-tickets-link-modal__card__description__highlighted">
                            {{ accessMomentModel?.$data.name }}
                        </span>
                    </template>
                </i18n>

                <OtFormRow>
                    <InputField
                        :label="$t('dashboard.access_moments.list.link_tickets.select.label')"
                        :description="$t('dashboard.access_moments.list.link_tickets.select.description')"
                    >
                        <OtPaginationSelect
                            ref="paginationSelect"
                            :pagination="ticketsList"
                            :value="linkedTicketsInitial"
                            data-primary-key="name"
                            multiple
                            data-testid="access-moment-tickets-link-modal-select"
                            @input="handleSelectInput"
                        />
                    </InputField>
                </OtFormRow>
            </OtCardContent>

            <OtCardFooter>
                <button
                    class="ot-button is-dark"
                    :title="$t('dashboard.common.action.cancel.title')"
                    @click="close"
                >
                    <OtIcon
                        type="close"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.common.action.cancel.text') }}
                </button>

                <button
                    class="ot-button"
                    :title="$t('dashboard.access_moments.list.link_tickets.save')"
                    @click="submit()"
                >
                    <OtIcon
                        type="check"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.access_moments.list.link_tickets.title') }}
                </button>
            </OtCardFooter>
        </OtCard>
    </OtDialogModal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type {
    Event, EventDate, ManagementClient, Ticket,
} from '@openticket/lib-management';
import { OtDialogModal } from '@openticket/vue-dashboard-components';
import type { Pagination } from '@openticket/lib-crud';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import InputField from '../../../components/form/InputField.vue';
import { useGenericErrorHandling } from '../../../composables';

type Emits = {
    (e: 'close'): void,
    (e: 'error', error: Error): void
    (e: 'submit', model: EventDate<ManagementClient>): void
}

const emit = defineEmits<Emits>();

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const { handleError } = useGenericErrorHandling();

const accessMomentTicketsLinkModalRef = ref<InstanceType<typeof OtDialogModal> | null>(null);
const linkedTicketsInitial = ref<string[]>([]);
const linkedTicketsNew = ref<string[]>([]);
const accessMomentModel = ref<EventDate<ManagementClient> | null>(null);
const ticketsList = ref<Pagination<Ticket<Event<ManagementClient>>> | null>(null);

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

async function populateList() {
    try {
        if (!context.isEventContext()) {
            // TODO Properly log error & localise reason.
            throw Error('Invalid context');
        }

        ticketsList.value = context.event.model.tickets.list();
        await ticketsList.value.initialization;
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }
}

// Run this on created
void populateList();

function handleSelectInput(listOfGuids: string[]) {
    linkedTicketsNew.value = listOfGuids;
}

function close() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    accessMomentTicketsLinkModalRef.value?.close();

    accessMomentModel.value = null;
}

async function show(model: EventDate<ManagementClient> | EventDate<Event<ManagementClient>>) {
    if (!model) {
        return;
    }

    try {
        accessMomentModel.value = management.eventdates.$factory(model.$raw);

        // Only get the first 100 linked tickets for an eventdate.
        // This will result in issues when more than 100 tickets are linked to a single eventdate.
        // TODO: Update the PaginationSelect component to properly handle multi selection.
        const eventdateTicketList = accessMomentModel.value.tickets.list(100);
        await eventdateTicketList.initialization;

        linkedTicketsInitial.value = eventdateTicketList.records.map((m) => m.id || '');
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    accessMomentTicketsLinkModalRef.value?.show();
}

async function submit() {
    if (!context.isEventContext()) {
        // TODO Properly log error & localise reason.
        throw Error('Invalid context');
    }

    if (!accessMomentModel.value) {
        return;
    }

    const existingLinks = new Set(linkedTicketsInitial.value);
    const newLinks = new Set(linkedTicketsNew.value);

    const ticketGuidsToLink = linkedTicketsNew.value.filter((guid) => !existingLinks.has(guid));
    const ticketGuidsToUnLink = linkedTicketsInitial.value.filter((guid) => !newLinks.has(guid));

    try {
        if (ticketGuidsToLink.length > 0) {
            await accessMomentModel.value.tickets.linkMulti(...ticketGuidsToLink);
        }

        if (ticketGuidsToUnLink.length > 0) {
            await accessMomentModel.value.tickets.unlinkMulti(...ticketGuidsToUnLink);
        }

        emit('submit', accessMomentModel.value);
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }

    close();
}

defineExpose({
    show,
});
</script>

<style scoped lang="scss">
.access-moment-tickets-link-modal {
    overflow: visible;

    &__card {
        overflow: visible !important;

        &__description {
            &__highlighted {
                color: var(--ot-color-core-brand);
                font-weight: 500;
            }
        }

        &::v-deep {
            .card-content {
                display: flex;
                flex-direction: column;
                gap: var(--ot-spacing-unit);
                padding-bottom: var(--ot-spacing-unit);
            }

            .card-footer {
                border-radius: 0 0 var(--ot-input-radius) var(--ot-input-radius);
            }
        }
    }
}
</style>
