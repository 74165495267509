<template>
    <div class="vertical-tabs-item" v-if="tabIsSelected">
        <slot></slot>
    </div>
</template>

<script lang="ts" setup>
import type { TranslateResult } from '@openticket/vue-localization';
import { computed } from 'vue';

type Props = {
    route: { name: string, params?: Record<string, string> },
    label: string | TranslateResult,
    disabled?: boolean,
    selected: string | boolean,
}

const props = defineProps<Props>();

const tabIsSelected = computed<boolean>(() => props.selected === true || props.selected === props.route.name);
</script>
