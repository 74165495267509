import type { Route, RouteConfig } from 'vue-router';
import { ROLE_COMPANY_ADMIN } from '@openticket/lib-auth';
import { RouterView } from '../../services/util';
import AlbatoView from './views/AlbatoView.vue';

export function connectionsRoutes(): RouteConfig[] {
    return [
        {
            path: '/connections',
            component: RouterView,
            redirect: (to: Route) => ({
                name: 'connections',
                params: { ...to.params },
            }),
            children: [
                {
                    path: ':company',
                    name: 'connections',
                    component: AlbatoView,
                    meta: {
                        restrictedBy: ROLE_COMPANY_ADMIN,
                        context: 'company',
                        title: 'dashboard.document_title.connections.title',
                        titleFallback: 'Connections',
                        onContextSelect(id) {
                            if (!id || typeof id === 'symbol') {
                                return {
                                    name: 'companies.list',
                                };
                            }
                            return {
                                params: { company: id },
                            };
                        },
                    },
                },
            ],
        },
    ];
}
