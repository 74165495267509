<template>
    <div class="profile-summary">
        <OtAvatar class="profile-summary__avatar" :name="userName" size="56" />

        <div class="profile-summary__info">
            <h4 class="profile-summary__info__user-name">{{ userName }}</h4>

            <div
                class="profile-summary__info__open profile-summary__action"
                role="button"
                tabindex="0"
                @click="openProfileSettings"
                @keydown.space="openProfileSettings"
                @keydown.enter="openProfileSettings"
            >
                <OtIcon type="login" size="small" />
                {{ $t('dashboard.header.profile.user.settings.text') }}
            </div>

            <a
                v-if="!!classicDashboardRoute"
                class="profile-summary__info__open profile-summary__action"
                role="button"
                tabindex="0"
                :href="classicDashboardRoute"
            >
                <OtIcon type="carrot-left" size="small" />
                {{ $t('dashboard.classic.back_to_classic_dashboard_profile.title') }}
            </a>

            <div
                class="profile-summary__info__logout profile-summary__action"
                role="button"
                tabindex="0"
                @click="logout"
                @keydown.space="logout"
                @keydown.enter="logout"
            >
                <OtIcon type="logout" size="small" />
                {{ $t('dashboard.header.profile.logout.text') }}
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { AuthClient } from '@openticket/lib-auth';
import type { DialogController } from '@openticket/vue-dashboard-components';
import type { VueLocalization } from '@openticket/vue-localization';
import { ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import { useClassicDashboardRouting } from '../../../../composables';
import { injectOrFail } from '../../../../services/util';

type Emits = {
    (e: 'navigate'): void,
}
const emit = defineEmits<Emits>();

const { goBackToClassicRoute } = useClassicDashboardRouting();
const router = useRouter();

const auth = injectOrFail<AuthClient>('auth');
const dialog = injectOrFail<DialogController>('dialog');
const localization = injectOrFail<VueLocalization>('localization');

const userName = ref<string | undefined>();

void (async () => {
    const tokenInfo = await auth.$token.$info;
    userName.value = tokenInfo ? tokenInfo.user.name : '?';
})();

const classicDashboardRoute = goBackToClassicRoute.value;

async function openProfileSettings(): Promise<void> {
    if (router.currentRoute.name !== 'profile.settings') {
        await router.push({ name: 'profile.settings' });
    }

    emit('navigate');
}

async function logout(): Promise<void> {
    if (await dialog.confirm({
        title: localization.getI18n().t('dashboard.header.profile.logout.confirmation.title') as string,
        description: localization.getI18n().t('dashboard.header.profile.logout.confirmation.description') as string,
        type: 'is-danger',
    })) {
        void router.push({ name: 'auth.logout' });
    }
}
</script>

<style lang="scss" scoped>
.profile-summary {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--ot-spacing-xl);

    &__info {
        margin-top: 0.25rem;
        margin-left: 1rem;

        h4 {
            margin-bottom: 0.5rem;
        }
    }

    &__action {
        color: var(--ot-color-core-foreground-secondary);
        display: flex;
        align-items: center;
        margin-top: 0.25rem;
        font-weight: 500;
        cursor: pointer;
        border-radius: 2px;

        .oti {
            margin-right: 0.5rem;
        }

        &:hover, &:focus, &:focus-visible {
            outline: var(--ot-color-core-brand) solid 2px;
            outline-offset: 1px;
            color: var(--ot-color-core-brand);
        }
    }
}
</style>
