<template>
    <div>
        <iframe
            v-show="!loading"
            ref="iframeRef"
            :title="$t('dashboard.document_title.connections.title')"
            :src="albatoEmbedUrl"
            width="100%"
        />

        <OtSpinner v-if="loading" />

        <ErrorView
            v-if="error"
            :label="error.message"
        />
    </div>
</template>

<script setup lang="ts">
import { OtSpinner } from '@openticket/vue-dashboard-components';
import { ref, onMounted, watch } from 'vue';
import type { Albato } from '../../../services/albato/albato.service';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import { useGenericErrorHandling } from '../../../composables/useGenericErrorHandling';
import ErrorView from '../../../components/ErrorView.vue';

const { error, handleError } = useGenericErrorHandling();

const context = injectOrFail<Context>('context');
const albato = injectOrFail<Albato>('albato');

const iframeRef = ref<HTMLIFrameElement | null>(null);
const sessionToken = ref<string>();
const companyId = ref<string>();
const loading = ref<boolean>(true);
const albatoEmbedUrl = ref<string | null>(null);

void (async () => {
    if (!context.company) {
        return;
    }
    companyId.value = context.company.id;

    sessionToken.value = (await albato.getAlbatoToken(companyId.value))?.token;
})();

onMounted(() => {
    if (iframeRef.value) {
        iframeRef.value.onload = () => {
            loading.value = false;
        };
    }
});

watch(() => sessionToken.value, () => {
    if (!sessionToken.value) {
        void handleError('Service not available');
        albatoEmbedUrl.value = null;
        return;
    }
    albatoEmbedUrl.value = albato.getAlbatoUrl(sessionToken.value);
});

</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/mixins.scss";

    iframe {
        width: 1px;
        min-width: calc(100% + calc(var(--ot-spacing-3xl) * 2));
        border: none;
        height: calc(100vh - (var(--ot-layout-header-height) + 10rem));
        overflow: hidden;
        margin: 0 calc(var(--ot-spacing-3xl) * -1);

        @include breakpoint(mob) {
            margin: 0 calc(var(--ot-spacing-default) * -1);
            min-width: calc(100% + calc(var(--ot-spacing-default) * 2));
            width: 100%;
        }
    }
</style>
