<template>
    <OtSpinner v-if="!user || !tfaTypes" />

    <TfaSetup
        v-else-if="!user.two_factor_enabled"
        :type="tfaTypeToSetup"
        :tfa-types="tfaTypes"
        :show-forced-warning="showForcedWarning"
        @close="tfaSetupClose"
        :hide-logo="false"
        :hide-logout="false"
        success-message="dashboard.common.state.created"
        hide-back-button
    />

    <div v-else class="ot-card">
        <ModalHeader />

        <OtAside
            :title="$t('dashboard.user.tfa.setup.already_setup.title')"
            :description="$t('dashboard.user.tfa.setup.already_setup.description')"
            type="is-warning"
            icon="warning">
        </OtAside>

        <div class="complete-profile__footer">
            <button
                class="ot-button is-fullwidth"
                type="button"
                @click="$router.push({name: 'auth.login', query: clientConfig})"
                :title="$t('dashboard.invitation.invalid.dashboard.title')"
            >
                {{ $t('dashboard.invitation.invalid.dashboard.text') }}
                <i class="ot-button-icon--right oti oti-arrow-right" />
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import urlJoin from 'url-join';
import { ref } from 'vue';
import type { TFATypes } from '@openticket/vue-tfa-confirmation';
import { useRoute, useRouter } from 'vue-router/composables';
import type { AuthClient } from '@openticket/lib-auth';
import type { DialogController } from '@openticket/vue-dashboard-components';
import type { VueLocalization } from '@openticket/vue-localization';
import ModalHeader from '../../../components/modal/ModalHeader.vue';
import TfaSetup from '../../../components/tfa/TfaSetup.vue';
import type { OptionalClientConfig, StrippedMinimalUser } from '../types';
import { injectOrFail } from '../../../services/util';

const auth = injectOrFail<AuthClient>('auth');
const dialog = injectOrFail<DialogController>('dialog');
const user = injectOrFail<StrippedMinimalUser>('minimal_user');
const localization = injectOrFail<VueLocalization>('localization');

const clientConfig = ref<OptionalClientConfig>();
const tfaTypes = ref<TFATypes | null>(null);
const showForcedWarning = ref<boolean>(true);

const tfaTypeToSetup = 'totp' as const;

const route = useRoute();
const router = useRouter();

void (async (): Promise<void> => {
    clientConfig.value = route.query;

    try {
        // TODO - @openticket/lib-auth
        tfaTypes.value = (await auth.$http.get<TFATypes>(urlJoin(auth.$path, 'twofactor'))).data;
    } catch {
        await router.push({ name: 'error' });
    }

    if (route.query['forced-warning'] === 'hide') {
        showForcedWarning.value = false;
    }
})();

async function tfaSetupClose(): Promise<void> {
    const confirm = await dialog.confirm({
        title: $t('dashboard.user.tfa.setup.code.confirm_close.title') as string,
        description: $t('dashboard.user.tfa.setup.code.confirm_close.description') as string,
        type: 'is-info',
    });

    if (!confirm) {
        return;
    }
    // TODO @PETER - auth.login route (or equivalent)
    await router.push({ name: 'auth.login', query: clientConfig.value });
}

const $t = (value: string) => localization.getI18n().t(value);
</script>
