<template>
    <div class="events-home-wrapper">
        <EventHeader :event="context.event.model" v-if="context.type === 'event'" />

        <router-view></router-view>
    </div>
</template>

<script setup lang="ts">
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import EventHeader from '../../../components/EventHeader.vue';

const context = injectOrFail<Context>('context');

if (!context.isEventContext()) {
    throw Error('Invalid context');
}
</script>
