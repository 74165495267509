<template>
    <AvatarHeader
        class="user-header"
        :name="name"
    >
        <div class="user-header__flairs">
            <template v-if="!hide_role">
                <OtFlair
                    v-for="(role, key) in roles"
                    :key="key"
                    :inversed="false"
                    :centered="false"
                    :type="role.type"
                >
                    {{ $t(role.slug) }}
                </OtFlair>
            </template>

            <OtFlair
                v-if="two_factor_enabled"
                icon="check"
                :inversed="false"
                :centered="false"
                type="is-success"
            >
                {{ $t('dashboard.members.tag.tfa') }}
            </OtFlair>
        </div>
    </AvatarHeader>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import AvatarHeader from '../AvatarHeader.vue';

type Props = {
    name: string;
    subtitle?: string | null;
    manager?: boolean;
    two_factor_enabled?: boolean;
    hide_role?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    subtitle: null,
    manager: false,
    two_factor_enabled: false,
});

interface RoleFlair {
    type: 'is-black' | 'is-light';
    slug: string;
}

const roles = computed((): { [key: string]: RoleFlair } => {
    // This getter is a bit over-engineered.
    // This is done to reduce the template conditional collisions.
    // And this keeps flexibility towards the future, where it is expected
    // to get changes to the way roles are used/done.
    if (props.manager) {
        return {
            manager: {
                type: 'is-black',
                slug: 'dashboard.members.tag.manager',
            },
        };
    }

    return {
        view_only: {
            type: 'is-light',
            slug: 'dashboard.members.tag.view_only',
        },
    };
});
</script>

<style lang="scss" scoped>
.user-header {
    &__flairs {
      margin-top: var(--ot-spacing-xs);
      & > .ot-flair__label {
        margin-left: 0;
      }
    }
}
</style>
