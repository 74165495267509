<template>
    <div class="shop-tickets__item ot-py[md]" :class="{ 'solo ot-px[lg] ot-py[md] ot-my[xs]': solo }">
        <div class="shop-tickets__item--header">
            <OtIcon type="ticket" />
            {{ ticket.name }}
        </div>
        <div class="shop-tickets__item--actions">
            <button data-drag-handle class="ot-button is-tiny is-text ot-px[sm] drop-both">
                <OtIcon type="drop-both" size="small" />
            </button>
            <button class="ot-button is-tiny is-text ot-px[sm] close" @click="removeTicketFromShop(ticket.guid)">
                <OtIcon type="close" size="small" />
            </button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { DialogController } from '@openticket/vue-dashboard-components';
import type { VueLocalization } from '@openticket/vue-localization';
import { injectOrFail } from '../../../../services/util';
import type { ShopDataTicket } from '../../../../services/shop';

type Props = {
    ticket: ShopDataTicket,
    solo?: boolean,
}

type Emits = {
    (e: 'remove', ticketId: string): void
}

defineProps<Props>();
const emit = defineEmits<Emits>();

const dialog = injectOrFail<DialogController>('dialog');
const localization = injectOrFail<VueLocalization>('localization');

async function removeTicketFromShop(ticketId: string) {
    const confirm = await dialog?.confirm({
        title: localization?.getI18n().t('dashboard.shop_tickets.confirm.remove_ticket.title') as string || '',
        description: localization?.getI18n().t('dashboard.shop_tickets.confirm.remove_ticket.description') as string || '',
        type: 'is-danger',
    });

    if (confirm) {
        emit('remove', ticketId);
    }
}
</script>

<style lang="scss" scoped>
.shop-tickets__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--ot-input-radius);

    // TODO Find other classname for a single ticket without collapse parent instead of .solo
    &.solo {
        border: 1px solid var(--ot-color-core-accent-secondary);
        overflow: hidden;
        background-color: var(--eventix-core-light-background-primary);
    }

    &--header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--ot-spacing-xs);
    }

    &--actions {

        .close:hover {
            color: var(--ot-color-accent-orange-dark);
        }

        .drop-both:hover {
            color: var(--ot-color-core-brand);
        }
    }

    &:not(.sortable-ghost) + & {
        border-top: 1px solid var(--ot-color-core-accent-tertiary);
    }
}
</style>
