import type { Route, RouteConfig } from 'vue-router';
import AppLibraryView from './views/AppLibraryView.vue';
import { RouterView } from '../../services/util';

export function appLibraryRoutes(): RouteConfig[] {
    return [
        {
            path: '/app-library',
            component: RouterView,
            redirect: (to: Route) => ({
                name: 'home',
                params: { ...to.params },
            }),
            children: [
                {
                    path: ':company',
                    name: 'app-library',
                    component: AppLibraryView,
                    meta: {
                        context: 'company',
                        title: 'dashboard.document_title.app_library.title',
                        titleFallback: 'App Library',
                        onContextSelect(id) {
                            if (!id || typeof id === 'symbol') {
                                return {
                                    name: 'companies.list',
                                };
                            }
                            return {
                                params: { company: id },
                            };
                        },
                    },
                },
            ],
        },
    ];
}
