<template>
    <OtSplash
        v-if="loading"
        class="app__loading"
        :spinner="spinner"
    />

    <OtDashboardLayout
        v-else
        ref="layout"
        class="app-wrapper-view"
    >
        <template #header>
            <OtHeader
                class="app-wrapper-view__header"
                @show-menu="layout?.showSidebar()"
            >
                <template #right>
                    <ProfileDropdown />
                </template>
            </OtHeader>
        </template>

        <template #sidebar>
            <div class="app-wrapper-view__sidebar">
                <div class="app-wrapper-view__sidebar-navigator">
                    <OtNavigator
                        :logo="navigatorLogo"
                    >
                        <template #between>
                            <ContextFilter
                                :companies="companies"
                                :events="events"
                                :shops="shops"
                                data-testid="context-filter"
                            />
                        </template>
                        <template v-for="(item, index) of navigatorItems">
                            <OtNavigatorItem
                                v-if="item.type === 'item' && !navItemNeedsWhitelabelRoute(item)"
                                :key="index"
                                :icon="item.icon"
                                :label="$t(item.label)"
                                :route="isNavItemAvailable(item) ? item.route : undefined"
                                :disabled="!isNavItemAvailable(item)"
                                :selected="itemIsSelected(item)"
                            />
                            <OtNavigatorSeparator
                                v-else-if="item.type === 'separator'"
                                :key="index"
                            />
                        </template>
                    </OtNavigator>
                </div>
                <div class="app-wrapper-view__sidebar-footer">
                    <BackToClassicDashboardCard />
                </div>
            </div>
        </template>

        <template #content>
            <template v-if="!context?.loading">
                <div class="app-wrapper-view__content__card">
                    <router-view />
                </div>
            </template>

            <OtSpinner v-else />
        </template>

        <template #footer>
            <OtFooter
                class="app-wrapper-view__footer"
                :copyright-label="$t('common.copyright', { year: (new Date()).getFullYear() })"
                :logo="whitelabelLogo"
            />
        </template>
    </OtDashboardLayout>
</template>

<script setup lang="ts">
import type {
    AuthClient, CompanyRaw, User, UserRaw,
} from '@openticket/lib-auth';
import { ROLE_COMPANY_ADMIN } from '@openticket/lib-auth';
import {
    computed, onBeforeUnmount, provide, reactive, ref,
} from 'vue';
import type { Pagination } from '@openticket/lib-crud';
import type {
    CompanyPath, Event, ManagementClient, Shop,
} from '@openticket/lib-management';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { VueLocalization } from '@openticket/vue-localization';
import { useRouter } from 'vue-router/composables';
import { useNavigationGuard } from '../composables';
import ProfileDropdown from '../modules/profile/components/dropdown/ProfileDropdown.vue';
import type { Context } from '../services/context';
import type { InternalNavigatorItem, InternalNavigatorSeparator } from '../services/navigator';
import { getNavigatorItems, itemIsSelected } from '../services/navigator';
import BackToClassicDashboardCard from '../components/BackToClassicDashboardCard.vue';
import type { Roles, ThemeModes } from '../plugins/types';
import { injectOrFail } from '../services/util';
import ContextFilter from '../components/contextfilter/ContextFilter.vue';
import type { SidebarUpdateTriggers } from './types';
import type { Intercom } from '../services/intercom';
import type { GlobalContext } from '../services/context/types';

const auth = injectOrFail<AuthClient>('auth');
const context = injectOrFail<Context>('context');
const intercom = injectOrFail<Intercom>('intercom');
const localization = injectOrFail<VueLocalization>('localization');
const management = injectOrFail<ManagementClient>('management');
const theme = injectOrFail<{ mode: ThemeModes }>('theme');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const router = useRouter();
const { isNavItemAvailable, navItemNeedsWhitelabelRoute } = useNavigationGuard();
const removeContextChangeListener = context.onChange(onContextFilterChange);

const companies = ref<CompanyRaw[]>([]);
const eventsLoading = ref<number>(0);
const loading = ref<number>(0);
const navigatorItems = ref<(InternalNavigatorItem | InternalNavigatorSeparator)[]>([]);
const shops = ref<Pagination<Shop<CompanyPath<ManagementClient>>> | null>(null);
const shopsLoading = ref<number>(0);
const events = ref<Pagination<Event<ManagementClient> | Event<CompanyPath<ManagementClient>>> | null>(null);

const user = ref<User<AuthClient> | null>(null);

// TODO:
//  This should be replaced by a slot scoped property (method)
//  I am delaying that until after the vue2 > vue3 migration.
//  @openticket/vue-dashboard-components should receive as little
//  changes as possible.
const layout = ref<{ showSidebar:() => void }>();
const roles = reactive<Roles>({ isSuperAdmin: false, isWhitelabelAdmin: false, isManager: false });
provide('roles', roles);

const sidebarUpdate = reactive<SidebarUpdateTriggers>({
    updateShops,
    updateEvents,
    updateUserandCompanies,
});
provide('sidebarUpdate', sidebarUpdate);

const navigatorLogo = computed(() => ({
    src: whitelabelLogo.value,
    route: {
        name: 'home',
    },
}));

const spinner = computed<string>(() => (theme.mode === 'dark' ? whitelabel.resources.spinnerDark : whitelabel.resources.spinner));

const whitelabelLogo = computed<string>(() => (theme.mode === 'dark' ? whitelabel.resources.logoDark : whitelabel.resources.logo));

void (async () => {
    loading.value++;

    try {
        await onLoad();
    } finally {
        loading.value--;
    }
})();

onBeforeUnmount(() => {
    removeContextChangeListener();
});

async function onLoad(): Promise<void> {
    await updateUserandCompanies();
    await updateRoles();
    updateSidebarItems();
    loadIntercom();
}

async function onContextFilterChange(oldContext: GlobalContext | null, newContext: GlobalContext | null) {
    if (oldContext?.company?.id !== newContext?.company?.id) {
        await updateRoles();
    }
    updateSidebarItems();
}

async function updateRoles(): Promise<void> {
    try {
        roles.isSuperAdmin = await auth.$token.isSuperAdmin();
        roles.isWhitelabelAdmin = await auth.$token.isWhitelabelAdmin();
        roles.isManager = context.isCompanyContext()
            ? await auth.$token.hasRoleForCompany(context.company.id, ROLE_COMPANY_ADMIN)
            : false;
    } catch (e) {
        roles.isSuperAdmin = false;
        roles.isWhitelabelAdmin = false;
        roles.isManager = false;
    }
}

async function updateUserandCompanies() {
    try {
        auth.setCompanyScope(null);
        const tokenInfo: {
            user: UserRaw;
            companies: CompanyRaw[];
        } | null = await auth.$token.retrieveTokenInfo();

        // The previous call overrides the company scope set by the context service. 'hacking' it back in.
        // TODO: align the company header according to the discussion in CU-86bxnhczr
        if (context.isCompanyContext()) {
            auth.setCompanyScope(context.company.id);
        }

        if (tokenInfo) {
            user.value = auth.users.$factory(tokenInfo.user);
            companies.value = tokenInfo.companies;
        }
    } catch (e) {
        // TODO Proper error + logging
        console.error('Failed to load user data', { e });
    }
}

async function updateEvents() {
    eventsLoading.value++;

    try {
        const tempEvents = context.isCompanyContext()
            ? context.company.model.events.list()
            : management.events.list();

        await tempEvents.initialization;
        await tempEvents.setSorting('name', 'asc');
        events.value = tempEvents;
    } finally {
        eventsLoading.value--;
    }
}

async function updateShops() {
    shopsLoading.value++;

    try {
        if (context.isCompanyContext()) {
            const tempShops = context.company.model.shops.list();
            await tempShops.initialization;
            await tempShops.setSorting('name', 'asc');
            shops.value = tempShops;
        }
    } finally {
        shopsLoading.value--;
    }
}

function updateSidebarItems() {
    if (context.isEventContext()) {
        if (!events.value && !eventsLoading.value) {
            void updateEvents();
        }
    } else if (context.isShopContext()) {
        if (!shops.value && !shopsLoading.value) {
            void updateShops();
        }
    } else {
        shops.value = null;
        events.value = null;
    }

    navigatorItems.value = getNavigatorItems(context, roles);
}

function loadIntercom(): void {
    const { company, event } = context;

    let companyURL: string | null = null;
    if (company) {
        // TODO figure out a better way to find the company url
        const route = router.resolve({ name: 'companies.home', params: { company: company.id } });
        companyURL = new URL(route.href, window.location.origin).href;
    }

    if (roles.isSuperAdmin || roles.isWhitelabelAdmin) {
        return;
    }

    intercom.updateSettings({
        company: {
            name: company?.name || null,
            company_id: company?.id || null,
            dashboard_link: companyURL,
            whitelabel_id: null, // TODO whitelabel.whitelabel_id does not exist?
            whitelabel_name: whitelabel.name,
            remote_created_at: company?.model.$data.created_at
                ? Math.floor(new Date(company.model.$data.created_at).getTime() / 1000)
                : null,
        },
        current_event_id: event?.id || null,
        user_id: user.value?.$data.guid || null,
        name: user.value?.$data.name || null,
        email: user.value?.$data?.email || null,
        two_factor_enabled: user.value?.$data?.two_factor_enabled || null,
        has_a_company: null,
    });
}

// TODO: Remove when upgraded to Vue 3
function $t(slug: string, values?: Record<string, unknown>) {
    return localization.getI18n().t(slug, values);
}
</script>

<style lang="scss" scoped>
.app-wrapper-view {
    &__sidebar {
        display: flex;
        flex-direction: column;
        height: 100%;

        &-navigator {
            flex: 1;
            padding-bottom: var(--ot-spacing-2xl);

            // Applies when context filter is not visible
            &:not(:has(.context-filter__backdrop)) {
                overflow-y: auto;
            }
        }
    }

    &__content__card {
        padding: 0;
    }
}

::v-deep .context-filter__card__list__item__all_companies + .context-filter__card__list__item {
    .context-filter__card__list__item__content {
        border-top-width: 0.25rem;
    }
}
</style>
