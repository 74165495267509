import { OtError } from '@openticket/lib-log';

export class FailedCopyShopUrlError extends OtError {

    readonly shop: string;
    readonly url: string;

    constructor(shop: string, url: string, cause?: OtError) {
        super('FailedCopyShopUrlError', 'dashboard.error.module.shops.list.copy.url.failed', 'Failed to copy URL.');

        this.shop = shop;
        this.addContext('shop', shop);

        this.url = url;
        this.addContext('url', url);

        if (cause) {
            this.causedBy(cause);
        }

        this.captureStackTrace(FailedCopyShopUrlError);
    }

}
