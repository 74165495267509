<template>
    <ActionTile
        :description="$t('dashboard.company.edit.actions.render_receipts.description')"
        :title="$t('dashboard.company.edit.actions.render_receipts.title')"
        class="actions-render-receipts"
        data-testid="actions-render-receipts"
    >
        <InputField
            :optional="companyForm.rules.name && companyForm.rules.name.includes('optional')"
            :required="companyForm.rules.name && companyForm.rules.name.includes('required')"
            class="actions-render-receipts__toggle"
        >
            <OtInputToggle
                v-model="companyForm.model.$data.invoice_to_visitors"
                :disabled="isDisabled"
                :label="$t('dashboard.company.edit.actions.render_receipts.toggle_label')"
                data-testid="actions-render-receipts-invoice-to-visitors"
            />
        </InputField>
        <div
            v-if="isDisabled"
            class="actions-render-receipts__warning"
            data-testid="actions-render-receipts-warning"
        >
            <!-- TODO: replace by warnings from new design system -->
            <div class="actions-render-receipts__warning__icon">
                <OtIcon type="warning" />
            </div>
            <div class="actions-render-receipts__warning__content">
                <div class="actions-render-receipts__warning__content__title">
                    {{ $t('dashboard.company.edit.actions.render_receipts.warning.title') }}
                </div>
                <div
                    class="actions-render-receipts__warning__content__description"
                >
                    <i18n
                        tag="span"
                        path="dashboard.company.edit.actions.render_receipts.warning.description"
                    >
                        <template #link>
                            <router-link
                                v-if="context.company"
                                :to="{
                                    name: 'companies.edit.registration',
                                    params: { company: context.company.id }
                                }"
                            >
                                <span>{{ $t('dashboard.company.edit.actions.render_receipts.warning.description_link') }}</span>
                            </router-link>
                        </template>
                    </i18n>
                </div>
            </div>
        </div>
        <div class="actions-render-receipts__actions">
            <button
                :disabled="isDisabled || !(companyForm.hasLocalChanges || companyForm.model.$data.invoice_to_visitors)"
                class="is-small ot-button actions-render-receipts__save-preferences is-outline"
                data-testid="actions-render-receipts-save"
                @click="props.companyForm.submit()"
            >
                {{ $t('dashboard.company.edit.actions.render_receipts.save_preferences_label') }}
            </button>
            <button
                v-if="modalRef"
                :disabled="!companyForm.model.$data.invoice_to_visitors || isDisabled"
                class="is-small ot-button"
                data-testid="actions-render-receipts-save-and-render"
                @click="saveAndOpenRerenderModal"
            >
                {{ $t('dashboard.company.edit.actions.render_receipts.save_rerender_label') }}
            </button>
        </div>
        <ConfirmWithYesModal
            ref="modalRef"
            :title="$t('dashboard.company.edit.actions.render_receipts.confirm.title')"
            :confirm-label="$t('dashboard.company.edit.actions.render_receipts.confirm.action_label')"
            @confirm="rerender"
        >
            <template #description>
                <i18n
                    tag="span"
                    path="dashboard.company.edit.actions.render_receipts.confirm.stats_description"
                >
                    <template #count>
                        <span
                            v-if="nrOfReceipts !== undefined"
                            class="confirm-with-yes-modal__highlighted"
                            data-testid="confirm-with-yes-modal-count-render-receipts"
                        >
                            {{ $l.number(nrOfReceipts) }}
                        </span>
                    </template>
                    <template #company>
                        <span class="confirm-with-yes-modal__highlighted">{{ companyName }}</span>
                    </template>
                </i18n>
            </template>
        </ConfirmWithYesModal>
    </ActionTile>
</template>

<script setup lang="ts">
import { computed, ref, type UnwrapNestedRefs } from 'vue';
import type { AuthClient, Company } from '@openticket/lib-auth';
import type VueNotifications from '@openticket/vue-notifications';
import type { VueLocalization } from '@openticket/vue-localization';
import { type ModelFormComposableResult } from '../../../../composables/forms';
import { injectOrFail } from '../../../../services/util';
import type { Context } from '../../../../services/context';
import ActionTile from './ActionTile.vue';
import ConfirmWithYesModal from './modals/ConfirmWithYesModal.vue';
import type { AdminActions } from '../../../../services/admin-actions/adminActions.service';
import { useGenericErrorHandling } from '../../../../composables';
import InputField from '../../../../components/form/InputField.vue';

type Props = {
    companyForm: UnwrapNestedRefs<ModelFormComposableResult<Company<AuthClient>, Company<AuthClient>>>
}
const props = defineProps<Props>();

const { handleError } = useGenericErrorHandling();

const modalRef = ref<InstanceType<typeof ConfirmWithYesModal>>();
const context = injectOrFail<Context>('context');
const adminActions = injectOrFail<AdminActions>('adminActions');
const notifications = injectOrFail<VueNotifications>('notifications');
const localization = injectOrFail<VueLocalization>('localization');

const nrOfReceipts = ref<number | undefined>();

const companyName = computed<string>(() => context.company?.name || '');
// TODO: when translation slugs of fields become more generic, rules can be used to fetch the required fields.
const isDisabled = computed<boolean>(() => !props.companyForm.model.$data.vat_number);

async function rerender() {
    if (!context.isCompanyContext()) {
        return;
    }

    try {
        await adminActions.rerenderReceipts(context.company.id);
        notifications.success(localization.getI18n().t('dashboard.company.edit.actions.render_receipts.success'));
    } catch (e: unknown) {
        handleError(e);
    }
}

async function saveAndOpenRerenderModal() {
    if (!context.isCompanyContext()) {
        return;
    }

    await props.companyForm.submit();
    nrOfReceipts.value = undefined;

    try {
        nrOfReceipts.value = await adminActions.countRerenderReceipts(context.company.id);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        modalRef.value?.open();
    } catch (e: unknown) {
        handleError(e);
    }
}

</script>

<style lang="scss" scoped>
.actions-render-receipts {
    &__save-preferences {
        margin-right: var(--ot-spacing-sm);
    }

    &__warning {
        display: flex;
        padding: var(--ot-spacing-lg) var(--ot-spacing-xl);
        background-color: var(--ot-color-accent-orange-faded-3);
        border-radius: var(--ot-spacing-2xs);

        &__icon {
            color: var(--ot-color-accent-orange-dark);
            padding-right: var(--ot-spacing-unit);
        }

        &__content {
            &__title {
                font-weight: 500;
            }

            &__description ::v-deep(a) {
                font-weight: normal;
                text-decoration: underline;
            }
        }
    }
}
</style>
