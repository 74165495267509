import type { NavigationGuardNext, Route, RouteRecord } from 'vue-router';
import { ROLE_ADMIN, ROLE_COMPANY_ADMIN, ROLE_WHITELABEL_ADMIN } from '@openticket/lib-auth';
import type { PluginsManager } from '../../plugins';
import type { RestrictedRoles } from '../navigator/types';
import type { Roles } from '../../plugins/types';

export async function roleGuard(to: Route, from: Route, next: NavigationGuardNext, plugins: PluginsManager) {
    if (!to.matched.some((route: RouteRecord) => !!route.meta.restrictedBy)) {
        // Exit early: Not a restricted route
        next();
        return;
    }

    const auth = await plugins.auth.loading;
    const context = await plugins.context.loading;

    const isSuperAdmin = await auth.$token.isSuperAdmin();
    const isWhitelabelAdmin = await auth.$token.isWhitelabelAdmin();
    const isManager = context.isCompanyContext()
        ? await auth.$token.hasRoleForCompany(context.company.id, ROLE_COMPANY_ADMIN)
        : false;

    const roles: Roles = { isManager, isWhitelabelAdmin, isSuperAdmin };

    if (to.meta?.restrictedBy && !roleCheck(to.meta.restrictedBy, roles)) {
        // Move on to restricted route
        next();
        return;
    }

    // Redirect to home
    // TODO: Add notification
    // new VueNotifications('This route is not accessible, ask your supervisor or contact CS');
    next({ name: 'home' });
}

export function disableByRole(roles: Roles, role: RestrictedRoles): boolean {
    return roleCheck(role, roles);
}

const roleCheck = (role: RestrictedRoles, roles: Roles) => {
    if (role === ROLE_COMPANY_ADMIN && (roles.isManager || roles.isWhitelabelAdmin || roles.isSuperAdmin)) {
        return false;
    }
    if (role === ROLE_WHITELABEL_ADMIN && (roles.isWhitelabelAdmin || roles.isSuperAdmin)) {
        return false;
    }

    return !(role === ROLE_ADMIN && roles.isSuperAdmin);
};
